import { motion } from "framer-motion";
import { Box, CheckSquare, XSquare } from "lucide-react";
import { Equipment } from "@/types/inventory";

interface InventoryStatsProps {
  inventory: Equipment[];
}

export const InventoryStats = ({ inventory }: InventoryStatsProps) => {
  const stats = [
    {
      label: "Stock Total",
      value: inventory.length,
      icon: Box,
      bgColor: "bg-blue-500/10",
      textColor: "text-blue-400"
    },
    {
      label: "Disponible",
      value: inventory.filter(item => item.estado === 'disponible').length,
      icon: Box,
      bgColor: "bg-green-500/10",
      textColor: "text-green-400"
    },
    {
      label: "En Buen Estado",
      value: inventory.filter(item => item.estado === 'bueno').length,
      icon: CheckSquare,
      bgColor: "bg-emerald-500/10",
      textColor: "text-emerald-400"
    },
    {
      label: "Dañado",
      value: inventory.filter(item => item.estado === 'dañado').length,
      icon: XSquare,
      bgColor: "bg-red-500/10",
      textColor: "text-red-400"
    }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      {stats.map((stat, index) => (
        <motion.div
          key={stat.label}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: index * 0.1 }}
          className="bg-gray-900/40 backdrop-blur-lg rounded-lg p-6 border border-gray-800/50"
        >
          <div className="flex justify-between items-center">
            <div>
              <p className="text-gray-400 text-sm">{stat.label}</p>
              <p className="text-2xl font-bold text-white mt-1">
                {stat.value}
              </p>
            </div>
            <div className={`p-3 rounded-lg ${stat.bgColor}`}>
              <stat.icon className={`w-6 h-6 ${stat.textColor}`} />
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  );
};