import { useEffect } from "react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Loader2, Mail, Users2, Sparkles, Search, Filter, ArrowUpDown } from "lucide-react";
import { AccessStatusTracker } from "./AccessStatusTracker";
import { toast } from "sonner";
import { Input } from "@/components/ui/input";
import { useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

export const AccessTrackingSection = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

  const { data: newHires, isLoading, refetch } = useQuery({
    queryKey: ["newHiresTracking"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("new_hires")
        .select("*")
        .order("created_at", { ascending: false })
        .limit(10);

      if (error) throw error;
      return data;
    },
  });

  // Set up real-time subscription
  useEffect(() => {
    const channel = supabase
      .channel('new-hires-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'new_hires'
        },
        () => {
          console.log('New hires data changed, refreshing...');
          refetch();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [refetch]);

  const sendStatusSummaryEmail = async () => {
    try {
      const { error } = await supabase.functions.invoke('notify-new-hire-summary', {
        body: { newHires }
      });

      if (error) throw error;
      toast.success("Resumen de estado enviado correctamente");
    } catch (error) {
      console.error('Error sending status summary:', error);
      toast.error("Error al enviar el resumen de estado");
    }
  };

  const filteredHires = newHires?.filter(hire => 
    hire.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    hire.cdsid.toLowerCase().includes(searchQuery.toLowerCase()) ||
    hire.market.toLowerCase().includes(searchQuery.toLowerCase())
  ) || [];

  const sortedHires = [...filteredHires].sort((a, b) => {
    const dateA = new Date(a.created_at).getTime();
    const dateB = new Date(b.created_at).getTime();
    return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
  });

  if (isLoading) {
    return (
      <div className="flex justify-center p-4">
        <Loader2 className="h-6 w-6 animate-spin" />
      </div>
    );
  }

  if (!newHires?.length) {
    return (
      <div className="text-center py-8 text-gray-400">
        No hay solicitudes de acceso pendientes
      </div>
    );
  }

  return (
    <div className="p-6 space-y-6 bg-transparent">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-3">
          <div className="p-3 bg-white/10 rounded-lg animate-float">
            <Users2 className="h-6 w-6 text-blue-400" />
          </div>
          <div>
            <h3 className="text-lg font-semibold text-white">Seguimiento de Solicitudes</h3>
            <p className="text-sm text-gray-400">Estado actual de las solicitudes de acceso</p>
          </div>
          <Sparkles className="h-5 w-5 text-yellow-400 animate-pulse" />
        </div>
        <Button 
          onClick={sendStatusSummaryEmail}
          className="bg-blue-500 hover:bg-blue-600 text-white flex items-center gap-2 hover:scale-105 transition-all duration-200"
        >
          <Mail className="h-4 w-4" />
          Enviar Resumen
        </Button>
      </div>

      <div className="flex flex-col sm:flex-row gap-4 items-center justify-between bg-gray-800/50 p-4 rounded-lg backdrop-blur-sm">
        <div className="flex gap-4 items-center w-full sm:w-auto">
          <div className="relative flex-1 sm:flex-initial">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
            <Input
              placeholder="Buscar por nombre, CDSID o mercado..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="pl-10 bg-gray-700/50 border-gray-600 text-white w-full sm:w-80"
            />
          </div>
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="bg-gray-700/50">
              <ArrowUpDown className="h-4 w-4 mr-2" />
              Ordenar
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="bg-gray-800 border-gray-700">
            <DropdownMenuItem onClick={() => setSortOrder('desc')} className="text-white hover:bg-gray-700">
              Más recientes primero
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => setSortOrder('asc')} className="text-white hover:bg-gray-700">
              Más antiguos primero
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <div className="space-y-4">
        {sortedHires.map((hire) => (
          <Card 
            key={hire.id}
            className="group transition-all duration-300 hover:shadow-xl bg-gray-800/50 backdrop-blur-sm border-gray-700/50"
          >
            <div className="p-4">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
                <div className="flex-1">
                  <div className="flex items-center gap-3 mb-2">
                    <h4 className="font-medium text-base text-white group-hover:text-blue-400 transition-colors duration-200">
                      {hire.name}
                    </h4>
                    <span className="text-xs text-gray-400 bg-gray-700/50 px-2 py-1 rounded-full">
                      CDSID: {hire.cdsid}
                    </span>
                    <span className="text-xs text-gray-400 bg-gray-700/30 px-3 py-1 rounded-full">
                      Mercado: {hire.market}
                    </span>
                  </div>
                  <div className="text-sm text-gray-400">
                    Creado: {new Date(hire.created_at).toLocaleDateString()}
                  </div>
                </div>
                <AccessStatusTracker
                  accessStatus={hire.access_status || []}
                  newHireId={hire.id}
                  onStatusUpdate={refetch}
                />
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};