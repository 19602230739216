import { motion } from "framer-motion";
import { Card } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { InventoryStats } from "@/components/inventory/InventoryStats";
import { TicketReports } from "@/components/dashboard/TicketReports";
import { AssetCollectionsSection } from "@/components/dashboard/AssetCollectionsSection";
import { RecentActivity } from "@/components/dashboard/RecentActivity";
import { NewHiresSection } from "@/components/admin/new-hires/NewHiresSection";
import { UserAssignmentSection } from "@/components/inventory/UserAssignmentsView";
import { ServiceAlertsSection } from "@/components/alerts/ServiceAlertsSection";
import { Equipment } from "@/types/inventory";
import { Box, Users, FileText, LayoutDashboard, Sparkles, AlertCircle } from "lucide-react";
import { useEffect, useRef, Suspense } from "react";
import { QuickAccessGrid } from "./QuickAccessGrid";
import { toast } from "sonner";

interface MainContentProps {
  inventory: Equipment[];
  tickets: any[];
  ticketStats: any;
}

const LoadingFallback = () => (
  <div className="flex items-center justify-center p-8">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
  </div>
);

export const MainContent = ({ inventory, tickets, ticketStats }: MainContentProps) => {
  const mountedRef = useRef(true);

  useEffect(() => {
    console.log('MainContent mounted');
    
    toast.success("¡Bienvenido al Dashboard!", {
      description: "Aquí podrás gestionar todas tus tareas y recursos.",
    });

    const activeAlerts = tickets.filter(t => t.status === 'open' && t.priority === 'high');
    if (activeAlerts.length > 0) {
      toast.warning(`Tienes ${activeAlerts.length} tickets prioritarios pendientes`, {
        duration: 5000,
      });
    }

    return () => {
      console.log('MainContent unmounting');
      mountedRef.current = false;
    };
  }, [tickets]);

  if (!mountedRef.current) {
    return null;
  }

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        className="lg:col-span-2"
      >
        <ServiceAlertsSection />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.2 }}
        className="lg:col-span-2"
      >
        <Card className="p-6 glass-panel hover:bg-white/10 transition-all duration-300">
          <div className="flex items-center gap-4 mb-6">
            <div className="p-3 bg-white/10 rounded-lg animate-float">
              <Sparkles className="w-6 h-6 text-blue-400" />
            </div>
            <div>
              <h1 className="text-2xl font-bold text-white">¡Bienvenido al Panel de Control!</h1>
              <p className="text-white/80">Gestiona y monitoriza tu sistema IT desde aquí</p>
            </div>
          </div>
          <Suspense fallback={<LoadingFallback />}>
            <QuickAccessGrid />
          </Suspense>
        </Card>
      </motion.div>

      <motion.div 
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.3, delay: 0.3 }}
        className="space-y-6"
      >
        <Suspense fallback={<LoadingFallback />}>
          <Card className="glass-panel p-6">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-white/10 rounded-lg">
                <Box className="w-5 h-5 text-blue-400" />
              </div>
              <h2 className="text-xl font-bold text-white">Inventario IT</h2>
              <p className="text-white/60 text-sm">Gestión de equipos y recursos tecnológicos</p>
            </div>
            
            <Tabs defaultValue="stats" className="space-y-4">
              <TabsList className="bg-white/5 border border-white/10">
                <TabsTrigger 
                  value="stats" 
                  className="data-[state=active]:bg-white/10 text-white/80 data-[state=active]:text-white"
                >
                  <Box className="h-4 w-4 mr-2" />
                  Estadísticas
                </TabsTrigger>
                <TabsTrigger 
                  value="users" 
                  className="data-[state=active]:bg-white/10 text-white/80 data-[state=active]:text-white"
                >
                  <Users className="h-4 w-4 mr-2" />
                  Asignaciones
                </TabsTrigger>
              </TabsList>
              
              <TabsContent value="stats" className="mt-4">
                <InventoryStats inventory={inventory} />
              </TabsContent>
              
              <TabsContent value="users" className="mt-4">
                <UserAssignmentSection inventory={inventory} />
              </TabsContent>
            </Tabs>
          </Card>

          <TicketReports 
            tickets={tickets}
            stats={ticketStats}
          />
          <AssetCollectionsSection />
        </Suspense>
      </motion.div>

      <motion.div 
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.3, delay: 0.4 }}
        className="space-y-6"
      >
        <Suspense fallback={<LoadingFallback />}>
          <Card className="glass-panel p-6">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-white/10 rounded-lg">
                <FileText className="w-5 h-5 text-blue-400" />
              </div>
              <h2 className="text-xl font-bold text-white">Nuevas Incorporaciones</h2>
            </div>
            <NewHiresSection />
          </Card>
          <RecentActivity />
        </Suspense>
      </motion.div>
    </div>
  );
};