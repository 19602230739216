import { useState } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { toast } from "sonner";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

interface NewHireFormProps {
  onSuccess: () => void;
}

export const NewHireForm = ({ onSuccess }: NewHireFormProps) => {
  const [formData, setFormData] = useState({
    cdsid: "",
    name: "",
    market: "IBERIA",
    start_date: "",
  });

  const queryClient = useQueryClient();

  const createNewHireMutation = useMutation({
    mutationFn: async (data: typeof formData) => {
      console.log('Creating new hire with data:', data);
      const { error } = await supabase
        .from('new_hires')
        .insert([data]);

      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['new-hires'] });
      toast.success('Nueva incorporación registrada correctamente');
      onSuccess();
    },
    onError: (error: any) => {
      console.error('Error creating new hire:', error);
      toast.error("Error al registrar la nueva incorporación: " + error.message);
    }
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!formData.start_date) {
      toast.error("Por favor, selecciona una fecha de incorporación");
      return;
    }

    createNewHireMutation.mutate(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <Label>CDSID</Label>
          <Input
            required
            value={formData.cdsid}
            onChange={(e) => setFormData({ ...formData, cdsid: e.target.value.toUpperCase() })}
            className="bg-gray-700"
            placeholder="Ej: JSMITH12"
          />
        </div>

        <div className="space-y-2">
          <Label>Nombre Completo</Label>
          <Input
            required
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            className="bg-gray-700"
            placeholder="Ej: John Smith"
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <Label>Mercado</Label>
          <Select
            value={formData.market}
            onValueChange={(value) => setFormData({ ...formData, market: value })}
          >
            <SelectTrigger className="bg-gray-700">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="IBERIA">IBERIA</SelectItem>
              <SelectItem value="ITALY">ITALY</SelectItem>
              <SelectItem value="FRANCE">FRANCE</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="space-y-2">
          <Label>Fecha de Incorporación</Label>
          <Input
            type="date"
            required
            value={formData.start_date}
            onChange={(e) => setFormData({ ...formData, start_date: e.target.value })}
            className="bg-gray-700"
          />
        </div>
      </div>

      <Button 
        type="submit" 
        disabled={createNewHireMutation.isPending}
        className="w-full"
      >
        {createNewHireMutation.isPending ? "Procesando..." : "Crear Nueva Incorporación"}
      </Button>
    </form>
  );
};