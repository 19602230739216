import { Badge } from "@/components/ui/badge";

export const ticketCategories = {
  "hardware": {
    label: "Hardware",
    description: "Problemas con equipos físicos",
    color: "bg-blue-500"
  },
  "software": {
    label: "Software",
    description: "Problemas con programas y aplicaciones",
    color: "bg-green-500"
  },
  "access": {
    label: "Accesos",
    description: "Gestión de permisos y accesos",
    color: "bg-purple-500"
  },
  "network": {
    label: "Red",
    description: "Problemas de conectividad",
    color: "bg-orange-500"
  },
  "email": {
    label: "Email",
    description: "Problemas con correo electrónico",
    color: "bg-yellow-500"
  },
  "other": {
    label: "Otros",
    description: "Otras solicitudes",
    color: "bg-gray-500"
  }
} as const;

export type TicketCategory = keyof typeof ticketCategories;

interface TicketCategoryBadgeProps {
  category: TicketCategory;
}

export const TicketCategoryBadge = ({ category }: TicketCategoryBadgeProps) => {
  const categoryInfo = ticketCategories[category];
  
  return (
    <Badge className={`${categoryInfo.color} text-white`}>
      {categoryInfo.label}
    </Badge>
  );
};