import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { FileDown, BarChart2, Clock, Users, Filter } from "lucide-react";
import { toast } from "sonner";
import { TicketStatCards } from "./TicketStatCards";
import { TicketPriorityBreakdown } from "./TicketPriorityBreakdown";
import { generateTicketPDF } from "@/utils/pdfGenerator";
import { motion } from "framer-motion";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import type { Ticket } from "@/utils/pdf/types";

interface TicketStats {
  total: number;
  open: number;
  inProgress: number;
  closed: number;
  byPriority: {
    low: number;
    medium: number;
    high: number;
    urgent: number;
  };
}

interface Props {
  tickets: Ticket[];
  stats: TicketStats;
}

export const TicketReports = ({ tickets, stats }: Props) => {
  const [isGenerating, setIsGenerating] = useState(false);

  const handleExportPDF = async () => {
    setIsGenerating(true);
    try {
      const doc = await generateTicketPDF(tickets, stats);
      doc.save('reporte-tickets.pdf');
      toast.success("PDF exportado correctamente");
    } catch (error) {
      console.error('Error generando PDF:', error);
      toast.error("Error al exportar PDF");
    } finally {
      setIsGenerating(false);
    }
  };

  // Procesar datos para el gráfico de tickets por usuario
  const ticketsByUser = tickets.reduce<Record<string, number>>((acc, ticket) => {
    const user = ticket.submitter_name || ticket.submitter_cdsid || 'Unknown';
    acc[user] = (acc[user] || 0) + 1;
    return acc;
  }, {});

  const userChartData = Object.entries(ticketsByUser)
    .map(([user, count]) => ({
      user: user,
      tickets: count
    }))
    .sort((a, b) => b.tickets - a.tickets)
    .slice(0, 10); // Top 10 usuarios

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Card className="p-6 bg-gradient-to-br from-gray-800/80 via-gray-800 to-gray-800/80 border-gray-700 shadow-xl">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-blue-500/10 rounded-lg">
              <BarChart2 className="w-5 h-5 text-blue-400" />
            </div>
            <div>
              <h3 className="text-lg font-semibold text-gray-100">Reportes de Tickets</h3>
              <p className="text-sm text-gray-400">Análisis y estadísticas del sistema de tickets</p>
            </div>
          </div>
          <Button 
            onClick={handleExportPDF} 
            disabled={isGenerating}
            className="flex items-center gap-2 bg-blue-600 hover:bg-blue-700 text-white transition-colors duration-200"
          >
            <FileDown className="w-4 h-4" />
            {isGenerating ? 'Generando...' : 'Exportar PDF'}
          </Button>
        </div>
        
        <div className="grid gap-6">
          <TicketStatCards stats={stats} />
          
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <Card className="p-4 bg-gray-700/30 border-gray-600">
              <div className="flex items-center gap-2 mb-4">
                <Users className="w-4 h-4 text-blue-400" />
                <h4 className="font-medium text-gray-200">Tickets por Usuario</h4>
              </div>
              <div className="h-[300px]">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={userChartData}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                    <XAxis 
                      dataKey="user" 
                      stroke="#9CA3AF"
                      tick={{ fill: '#9CA3AF' }}
                      angle={-45}
                      textAnchor="end"
                      height={80}
                    />
                    <YAxis stroke="#9CA3AF" tick={{ fill: '#9CA3AF' }} />
                    <Tooltip
                      contentStyle={{
                        backgroundColor: '#1F2937',
                        border: '1px solid #374151',
                        borderRadius: '0.375rem',
                        color: '#F3F4F6',
                      }}
                    />
                    <Bar 
                      dataKey="tickets" 
                      fill="#3B82F6"
                      radius={[4, 4, 0, 0]}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Card>
            
            <Card className="p-4 bg-gray-700/30 border-gray-600">
              <div className="flex items-center gap-2 mb-4">
                <Filter className="w-4 h-4 text-blue-400" />
                <h4 className="font-medium text-gray-200">Distribución por Prioridad</h4>
              </div>
              <TicketPriorityBreakdown stats={stats} />
            </Card>
          </div>
        </div>
      </Card>
    </motion.div>
  );
};
