import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";

interface EmailNotificationFieldsProps {
  sendEmail: boolean;
  setSendEmail?: (value: boolean) => void;
  customEmailSubject: string;
  setCustomEmailSubject?: (value: string) => void;
  additionalEmails: string[];
  setAdditionalEmails?: (value: string[]) => void;
}

export const EmailNotificationFields = ({
  sendEmail,
  setSendEmail,
  customEmailSubject,
  setCustomEmailSubject,
  additionalEmails,
  setAdditionalEmails
}: EmailNotificationFieldsProps) => {
  const [newEmail, setNewEmail] = useState("");

  const handleAddEmail = () => {
    if (newEmail && !additionalEmails.includes(newEmail)) {
      setAdditionalEmails?.([...additionalEmails, newEmail]);
      setNewEmail("");
    }
  };

  const handleRemoveEmail = (emailToRemove: string) => {
    setAdditionalEmails?.(additionalEmails.filter(email => email !== emailToRemove));
  };

  if (!setSendEmail || !setCustomEmailSubject || !setAdditionalEmails) {
    return null;
  }

  return (
    <div className="space-y-4 border border-gray-600 rounded-lg p-4">
      <div className="flex items-center space-x-2">
        <Switch
          id="send-email"
          checked={sendEmail}
          onCheckedChange={setSendEmail}
        />
        <Label htmlFor="send-email">
          Enviar aviso por email
        </Label>
      </div>
      
      {sendEmail && (
        <div className="space-y-4">
          <div>
            <label className="text-sm font-medium">Asunto del Email</label>
            <Input
              value={customEmailSubject}
              onChange={(e) => setCustomEmailSubject(e.target.value)}
              className="bg-gray-700/50 border-gray-600"
              placeholder="Asunto personalizado del email"
            />
          </div>

          <div className="space-y-2">
            <div className="flex gap-2">
              <Input
                type="email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                placeholder="Añadir email adicional"
                className="flex-1 bg-gray-700/50 border-gray-600"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleAddEmail();
                  }
                }}
              />
              <Button 
                type="button" 
                onClick={handleAddEmail}
                variant="outline"
              >
                Añadir
              </Button>
            </div>

            {additionalEmails.length > 0 && (
              <div className="space-y-2">
                {additionalEmails.map((email) => (
                  <div key={email} className="flex items-center gap-2 bg-gray-700/50 p-2 rounded">
                    <span className="flex-1 text-sm">{email}</span>
                    <Button
                      type="button"
                      variant="ghost"
                      size="sm"
                      onClick={() => handleRemoveEmail(email)}
                    >
                      ×
                    </Button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};