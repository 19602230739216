
import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import { SessionContextProvider } from '@supabase/auth-helpers-react';
import { PortalConfigProvider } from "./contexts/PortalConfigContext";
import { AuthProvider } from "./hooks/useAuth";
import { useEffect, useState } from "react";
import { StrictMode } from "react";
import { supabase } from "./integrations/supabase/client";
import AppRoutes from "./components/routing/AppRoutes";
import { toast } from "sonner";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
      meta: {
        onError: (error: unknown) => {
          console.error('Query error:', error);
          toast.error("Error de conexión con el servidor");
        }
      }
    },
  },
});

const App = () => {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    let mounted = true;

    const initializeApp = async () => {
      try {
        console.log('App - Testing Supabase connection...');
        
        // Check if the Supabase client can connect
        const { error: pingError } = await supabase.from('portal_config').select('*').limit(1);
        
        if (pingError && pingError.code !== 'PGRST116') {
          console.error('App - Connection error:', pingError);
        } else {
          console.log('App - Connection successful');
        }

        if (mounted) {
          setIsInitialized(true);
        }
      } catch (error: unknown) {
        console.error('App - Initialization error:', error);
        if (mounted) {
          setIsInitialized(true);
        }
      }
    };

    initializeApp();

    return () => {
      mounted = false;
    };
  }, []);

  if (!isInitialized) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <StrictMode>
        <QueryClientProvider client={queryClient}>
          <SessionContextProvider 
            supabaseClient={supabase}
            initialSession={null}
          >
            <AuthProvider>
              <PortalConfigProvider>
                <TooltipProvider>
                  <AppRoutes />
                  <Toaster />
                  <Sonner />
                </TooltipProvider>
              </PortalConfigProvider>
            </AuthProvider>
          </SessionContextProvider>
        </QueryClientProvider>
      </StrictMode>
    </BrowserRouter>
  );
};

export default App;
