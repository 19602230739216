import { Button } from "@/components/ui/button";
import { Pencil, Trash2, Users } from "lucide-react";

interface AlertActionsProps {
  isLoginPage: boolean;
  affectedUsersCount: number;
  onEdit: () => void;
  onDelete: () => void;
  onShowUsers: () => void;
}

export const AlertActions = ({ 
  isLoginPage, 
  affectedUsersCount, 
  onEdit, 
  onDelete, 
  onShowUsers 
}: AlertActionsProps) => {
  return (
    <div className="flex flex-col items-end gap-2">
      {!isLoginPage && (
        <div className="flex gap-2">
          <Button
            variant="outline"
            size="sm"
            onClick={onEdit}
            className="bg-gray-800 hover:bg-gray-700 text-gray-300 border-gray-700"
          >
            <Pencil className="w-4 h-4" />
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={onDelete}
            className="bg-gray-800 hover:bg-gray-700 text-red-500 hover:text-red-400 border-gray-700"
          >
            <Trash2 className="w-4 h-4" />
          </Button>
        </div>
      )}

      <div 
        className="flex items-center gap-1 text-sm text-gray-400 cursor-pointer hover:text-gray-300 transition-colors"
        onClick={onShowUsers}
      >
        <Users className="w-4 h-4" />
        {affectedUsersCount} afectados
      </div>
    </div>
  );
};