import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Badge } from "@/components/ui/badge";
import { Search, User } from "lucide-react";
import { Equipment } from "@/types/inventory";
import { motion } from "framer-motion";

interface UserAssignmentsViewProps {
  inventory: Equipment[];
}

export const UserAssignmentSection = ({ inventory }: UserAssignmentsViewProps) => {
  const [searchTerm, setSearchTerm] = useState("");

  // Group equipment by user
  const userAssignments = inventory.reduce((acc, item) => {
    if (item.asignado_a) {
      if (!acc[item.asignado_a]) {
        acc[item.asignado_a] = [];
      }
      acc[item.asignado_a].push(item);
    }
    return acc;
  }, {} as Record<string, Equipment[]>);

  // Filter users based on search
  const filteredUsers = Object.entries(userAssignments).filter(([cdsid]) =>
    cdsid.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="space-y-6">
      <div className="relative">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground h-4 w-4" />
        <Input
          placeholder="Buscar por CDSID..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="pl-10 bg-background/50 border-border/50"
        />
      </div>

      <div className="grid gap-6">
        {filteredUsers.map(([cdsid, items], index) => (
          <motion.div
            key={cdsid}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: index * 0.1 }}
          >
            <Card className="p-6 bg-background/30 backdrop-blur-sm border-border/50 hover:bg-muted/50 transition-all duration-300">
              <div className="flex items-center gap-3 mb-4">
                <div className="p-2 rounded-full bg-primary/10">
                  <User className="h-5 w-5 text-primary" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-foreground">{cdsid}</h3>
                  <p className="text-sm text-muted-foreground">
                    {items.length} {items.length === 1 ? "equipo" : "equipos"} asignados
                  </p>
                </div>
              </div>

              <div className="grid gap-3">
                {items.map((item) => (
                  <div
                    key={item.id}
                    className="flex items-center justify-between p-3 rounded-lg bg-background/50 border border-border/50 hover:bg-muted/50 transition-all duration-300"
                  >
                    <div>
                      <p className="font-medium text-foreground">{item.nombre}</p>
                      <p className="text-sm text-muted-foreground">
                        {item.tipo_equipo} - SN: {item.serial_number || "N/A"}
                      </p>
                    </div>
                    <Badge
                      className="bg-primary/10 text-primary border-primary/20"
                    >
                      {item.estado}
                    </Badge>
                  </div>
                ))}
              </div>
            </Card>
          </motion.div>
        ))}

        {filteredUsers.length === 0 && (
          <Card className="p-6 text-center bg-background/30 backdrop-blur-sm border-border/50">
            <p className="text-muted-foreground">No se encontraron asignaciones</p>
          </Card>
        )}
      </div>
    </div>
  );
};