import { useState } from "react";
import { Calendar as CalendarComponent } from "@/components/ui/calendar";
import { Card } from "@/components/ui/card";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { motion } from "framer-motion";
import { es } from "date-fns/locale";
import { Button } from "@/components/ui/button";
import { Building2, Home } from "lucide-react";
import { toast } from "sonner";
import { format } from "date-fns";

interface WorkDay {
  date: string;
  work_type: 'office' | 'remote';
}

export const WorkScheduleCalendar = () => {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(new Date());
  const queryClient = useQueryClient();

  const { data: workDays = [] } = useQuery({
    queryKey: ["work-schedule"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("work_schedule")
        .select("*")
        .order("date", { ascending: true });

      if (error) {
        console.error("Error fetching work schedule:", error);
        throw error;
      }

      return data as WorkDay[];
    },
  });

  const mutation = useMutation({
    mutationFn: async ({ date, workType }: { date: Date; workType: 'office' | 'remote' }) => {
      const { error } = await supabase
        .from("work_schedule")
        .upsert({
          date: format(date, 'yyyy-MM-dd'),
          work_type: workType,
          user_id: (await supabase.auth.getUser()).data.user?.id
        });

      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["work-schedule"] });
      toast.success("Horario actualizado correctamente");
    },
    onError: (error) => {
      console.error("Error updating work schedule:", error);
      toast.error("Error al actualizar el horario");
    },
  });

  const handleWorkTypeSelect = (type: 'office' | 'remote') => {
    if (!selectedDate) return;
    mutation.mutate({ date: selectedDate, workType: type });
  };

  const getDayWorkType = (date: Date): 'office' | 'remote' | undefined => {
    const workDay = workDays.find(
      wd => wd.date === format(date, 'yyyy-MM-dd')
    );
    return workDay?.work_type;
  };

  return (
    <Card className="p-6 bg-gray-800/50 backdrop-blur-xl border-gray-700/50">
      <h3 className="text-xl font-semibold text-white mb-4">
        Calendario Oficina - Remoto Iván
      </h3>
      
      <div className="grid gap-4">
        <CalendarComponent
          mode="single"
          selected={selectedDate}
          onSelect={setSelectedDate}
          className="bg-gray-800/50 text-white rounded-lg p-4"
          locale={es}
          modifiers={{
            office: (date) => getDayWorkType(date) === 'office',
            remote: (date) => getDayWorkType(date) === 'remote',
          }}
          modifiersStyles={{
            office: { backgroundColor: '#3b82f6' },
            remote: { backgroundColor: '#10b981' }
          }}
        />

        <div className="flex gap-4 justify-center">
          <Button
            onClick={() => handleWorkTypeSelect('office')}
            className="bg-blue-500 hover:bg-blue-600"
          >
            <Building2 className="w-4 h-4 mr-2" />
            Oficina
          </Button>
          <Button
            onClick={() => handleWorkTypeSelect('remote')}
            className="bg-green-500 hover:bg-green-600"
          >
            <Home className="w-4 h-4 mr-2" />
            Teletrabajo
          </Button>
        </div>
      </div>
    </Card>
  );
};