import { Button } from "@/components/ui/button";
import { FileDown, FileSpreadsheet } from "lucide-react";
import { toast } from "sonner";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { Empleado } from "@/integrations/supabase/types/tables";

interface EmpleadosExportProps {
  empleados: Empleado[];
}

export const EmpleadosExport = ({ empleados }: EmpleadosExportProps) => {
  const exportToPDF = () => {
    try {
      const doc = new jsPDF();
      
      doc.setFontSize(16);
      doc.text("Lista de Empleados Ford", 14, 20);
      
      doc.setFontSize(10);
      doc.text(`Generado el ${new Date().toLocaleString()}`, 14, 30);
      
      const tableData = empleados.map(emp => [
        emp.cdsid,
        emp.nombre,
        emp.apellidos,
        emp.email || emp.msxi_email || '-',
        emp.mercado,
        emp.posicion || '-',
        emp.estado
      ]);
      
      autoTable(doc, {
        startY: 40,
        head: [['CDSID', 'Nombre', 'Apellidos', 'Email', 'Mercado', 'Posición', 'Estado']],
        body: tableData,
      });
      
      doc.save('empleados_ford.pdf');
      toast.success("PDF exportado correctamente");
    } catch (error) {
      console.error('Error exporting PDF:', error);
      toast.error("Error al exportar PDF");
    }
  };

  const exportToExcel = () => {
    try {
      const ws = XLSX.utils.json_to_sheet(empleados.map(emp => ({
        CDSID: emp.cdsid,
        Nombre: emp.nombre,
        Apellidos: emp.apellidos,
        Email: emp.email || emp.msxi_email || '-',
        Mercado: emp.mercado,
        Posición: emp.posicion || '-',
        Estado: emp.estado
      })));
      
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Empleados");
      XLSX.writeFile(wb, 'empleados_ford.xlsx');
      
      toast.success("Excel exportado correctamente");
    } catch (error) {
      console.error('Error exporting Excel:', error);
      toast.error("Error al exportar Excel");
    }
  };

  return (
    <div className="flex gap-2">
      <Button
        variant="outline"
        size="sm"
        onClick={exportToPDF}
        className="gap-2"
      >
        <FileDown className="w-4 h-4" />
        PDF
      </Button>
      <Button
        variant="outline"
        size="sm"
        onClick={exportToExcel}
        className="gap-2"
      >
        <FileSpreadsheet className="w-4 h-4" />
        Excel
      </Button>
    </div>
  );
};