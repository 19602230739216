import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import { MessageSquare, Send } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

interface Message {
  role: "assistant" | "user";
  content: string;
}

export const ChatBot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isServiceAvailable, setIsServiceAvailable] = useState(true);

  const handleSend = async () => {
    if (!input.trim() || isLoading) return;

    const userMessage = input.trim();
    setInput("");
    setMessages(prev => [...prev, { role: "user", content: userMessage }]);
    setIsLoading(true);

    try {
      console.log("Sending message to chat assistant:", userMessage);
      const { data, error } = await supabase.functions.invoke('chat-assistant', {
        body: { message: userMessage }
      });

      console.log("Response from chat assistant:", { data, error });

      if (error) {
        console.error('Error from chat assistant:', error);
        
        // Check if it's a service unavailable error
        if (error.message?.includes('503') || error.message?.includes('no está disponible')) {
          setIsServiceAvailable(false);
          toast.error("El servicio de chat no está disponible temporalmente", {
            description: "Por favor, inténtalo más tarde"
          });
          return;
        }

        throw error;
      }

      // If we get here, the service is available
      setIsServiceAvailable(true);
      setMessages(prev => [...prev, { 
        role: "assistant", 
        content: data.response || "Lo siento, no pude procesar tu mensaje."
      }]);

    } catch (error) {
      console.error('Error in chat interaction:', error);
      toast.error("Ha ocurrido un error", {
        description: "Por favor, inténtalo de nuevo más tarde"
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {isOpen ? (
        <div className="bg-white rounded-lg shadow-xl w-80 h-96 flex flex-col animate-in fade-in slide-in-from-bottom-5">
          <div className="p-4 border-b flex justify-between items-center bg-blue-500 text-white rounded-t-lg">
            <h3 className="font-semibold">Asistente IT</h3>
            <Button 
              variant="ghost" 
              size="sm"
              className="text-white hover:text-blue-100"
              onClick={() => setIsOpen(false)}
            >
              ×
            </Button>
          </div>
          
          <ScrollArea className="flex-1 p-4">
            <div className="space-y-4">
              {messages.length === 0 && isServiceAvailable && (
                <p className="text-gray-500 text-sm text-center">
                  ¡Hola! Soy tu asistente virtual. ¿En qué puedo ayudarte?
                </p>
              )}
              {messages.map((msg, i) => (
                <div
                  key={i}
                  className={`flex ${
                    msg.role === "assistant" ? "justify-start" : "justify-end"
                  }`}
                >
                  <div
                    className={`rounded-lg px-4 py-2 max-w-[80%] ${
                      msg.role === "assistant"
                        ? "bg-gray-100"
                        : "bg-blue-500 text-white"
                    }`}
                  >
                    {msg.content}
                  </div>
                </div>
              ))}
              {isLoading && (
                <div className="flex justify-start">
                  <div className="bg-gray-100 rounded-lg px-4 py-2">
                    <div className="flex space-x-2">
                      <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" />
                      <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce [animation-delay:0.2s]" />
                      <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce [animation-delay:0.4s]" />
                    </div>
                  </div>
                </div>
              )}
              {!isServiceAvailable && (
                <div className="text-center p-4">
                  <p className="text-gray-500 text-sm">
                    El servicio de chat no está disponible en este momento.
                    <br />
                    Por favor, inténtalo más tarde.
                  </p>
                </div>
              )}
            </div>
          </ScrollArea>
          
          <div className="p-4 border-t">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSend();
              }}
              className="flex gap-2"
            >
              <Input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Escribe tu mensaje..."
                disabled={isLoading || !isServiceAvailable}
              />
              <Button 
                type="submit" 
                size="icon"
                disabled={isLoading || !isServiceAvailable || !input.trim()}
              >
                <Send className="h-4 w-4" />
              </Button>
            </form>
          </div>
        </div>
      ) : (
        <Button
          onClick={() => setIsOpen(true)}
          className="rounded-full w-12 h-12 bg-blue-500 hover:bg-blue-600 shadow-lg"
        >
          <MessageSquare className="h-6 w-6" />
        </Button>
      )}
    </div>
  );
};