import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";

const BajaEmpleado = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [formData, setFormData] = useState({
    cdsid: "",
    direccion: "",
    telefono: "",
    material: "",
    fecha_inicio: "",
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // Primero buscamos el empleado por CDSID
      const { data: empleado, error: empleadoError } = await supabase
        .from("empleados")
        .select("id")
        .eq("cdsid", formData.cdsid)
        .single();

      if (empleadoError || !empleado) {
        toast({
          title: "Error",
          description: "No se encontró el empleado con ese CDSID",
          variant: "destructive",
        });
        return;
      }

      // Creamos el registro de baja
      const { error } = await supabase.from("bajas_empleados").insert({
        empleado_id: empleado.id,
        fecha_inicio: formData.fecha_inicio,
        direccion_recogida: formData.direccion,
        telefono_contacto: formData.telefono,
        material_devolver: JSON.stringify([formData.material]),
      });

      if (error) throw error;

      toast({
        title: "Éxito",
        description: "Solicitud de baja registrada correctamente",
      });
      navigate("/dashboard");
    } catch (error) {
      console.error("Error:", error);
      toast({
        title: "Error",
        description: "Hubo un error al registrar la baja",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-gray-100 p-8">
      <div className="max-w-2xl mx-auto">
        <Card className="bg-gray-800 border-gray-700">
          <CardHeader>
            <CardTitle className="text-xl font-bold text-gray-200">
              Registro de Baja de Empleado
            </CardTitle>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-300">
                  CDSID
                </label>
                <Input
                  type="text"
                  value={formData.cdsid}
                  onChange={(e) =>
                    setFormData({ ...formData, cdsid: e.target.value })
                  }
                  className="mt-1 bg-gray-700 border-gray-600 text-white"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Fecha de Inicio
                </label>
                <Input
                  type="date"
                  value={formData.fecha_inicio}
                  onChange={(e) =>
                    setFormData({ ...formData, fecha_inicio: e.target.value })
                  }
                  className="mt-1 bg-gray-700 border-gray-600 text-white"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Dirección de Recogida
                </label>
                <Input
                  type="text"
                  value={formData.direccion}
                  onChange={(e) =>
                    setFormData({ ...formData, direccion: e.target.value })
                  }
                  className="mt-1 bg-gray-700 border-gray-600 text-white"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Teléfono de Contacto
                </label>
                <Input
                  type="tel"
                  value={formData.telefono}
                  onChange={(e) =>
                    setFormData({ ...formData, telefono: e.target.value })
                  }
                  className="mt-1 bg-gray-700 border-gray-600 text-white"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Material a Devolver
                </label>
                <Textarea
                  value={formData.material}
                  onChange={(e) =>
                    setFormData({ ...formData, material: e.target.value })
                  }
                  className="mt-1 bg-gray-700 border-gray-600 text-white"
                  placeholder="Lista de material a devolver..."
                  required
                />
              </div>

              <div className="flex justify-end space-x-4">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => navigate("/dashboard")}
                  className="bg-gray-700 border-gray-600 text-white hover:bg-gray-600"
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  className="bg-blue-600 hover:bg-blue-700 text-white"
                >
                  Registrar Baja
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default BajaEmpleado;