import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Platform, RequestType } from "@/integrations/supabase/types/tables";
import { motion } from "framer-motion";

interface FormFieldsProps {
  cdsid: string;
  setCdsid: (value: string) => void;
  nombre: string;
  setNombre: (value: string) => void;
  apellidos: string;
  setApellidos: (value: string) => void;
  mercado: string;
  setMercado: (value: string) => void;
  plataforma: string;
  setPlataforma: (value: string) => void;
  tipoSolicitud: string;
  setTipoSolicitud: (value: string) => void;
  platforms: Platform[];
  requestTypes: RequestType[];
  missingAccesos?: { plataforma_id: string; plataforma_nombre: string; }[] | null;
  isPrefilledEmployee?: boolean;
}

const formFields = [
  { id: "cdsid", label: "CDSID", value: "cdsid", setter: "setCdsid" },
  { id: "nombre", label: "Nombre", value: "nombre", setter: "setNombre" },
  { id: "apellidos", label: "Apellidos", value: "apellidos", setter: "setApellidos" },
  { id: "mercado", label: "Mercado", value: "mercado", setter: "setMercado" }
];

export const FormFields = ({
  cdsid,
  setCdsid,
  nombre,
  setNombre,
  apellidos,
  setApellidos,
  mercado,
  setMercado,
  plataforma,
  setPlataforma,
  tipoSolicitud,
  setTipoSolicitud,
  platforms,
  requestTypes,
  missingAccesos,
  isPrefilledEmployee
}: FormFieldsProps) => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  // Filter platforms to only show missing accesos if available
  const availablePlatforms = missingAccesos 
    ? platforms.filter(p => missingAccesos.some(ma => ma.plataforma_id === p.id))
    : platforms;

  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="show"
      className="space-y-4"
    >
      {formFields.map(field => (
        <motion.div key={field.id} variants={item} className="space-y-2">
          <Label htmlFor={field.id} className="text-sm font-medium text-gray-200">
            {field.label}
          </Label>
          <Input
            id={field.id}
            placeholder={field.label}
            value={eval(field.value)}
            onChange={(e) => eval(field.setter)(e.target.value)}
            className="bg-gray-700/50 border-gray-600 text-white placeholder:text-gray-400 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
            required
            disabled={isPrefilledEmployee}
          />
        </motion.div>
      ))}

      <motion.div variants={item} className="space-y-2">
        <Label htmlFor="platform" className="text-sm font-medium text-gray-200">
          Plataforma
        </Label>
        <Select value={plataforma} onValueChange={setPlataforma}>
          <SelectTrigger className="bg-gray-700/50 border-gray-600 text-white focus:ring-2 focus:ring-blue-500 transition-all">
            <SelectValue placeholder="Selecciona una plataforma" />
          </SelectTrigger>
          <SelectContent className="bg-gray-800 border-gray-700">
            {availablePlatforms.map((platform) => (
              <SelectItem 
                key={platform.id} 
                value={platform.id}
                className="text-white hover:bg-gray-700 focus:bg-gray-700"
              >
                {platform.nombre}
                {missingAccesos?.some(ma => ma.plataforma_id === platform.id) && 
                  " (Acceso Pendiente)"}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </motion.div>

      <motion.div variants={item} className="space-y-2">
        <Label htmlFor="requestType" className="text-sm font-medium text-gray-200">
          Tipo de Solicitud
        </Label>
        <Select value={tipoSolicitud} onValueChange={setTipoSolicitud}>
          <SelectTrigger className="bg-gray-700/50 border-gray-600 text-white focus:ring-2 focus:ring-blue-500 transition-all">
            <SelectValue placeholder="Selecciona el tipo de solicitud" />
          </SelectTrigger>
          <SelectContent className="bg-gray-800 border-gray-700">
            {requestTypes.map((type) => (
              <SelectItem 
                key={type.id} 
                value={type.id}
                className="text-white hover:bg-gray-700 focus:bg-gray-700"
              >
                {type.nombre}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </motion.div>
    </motion.div>
  );
};