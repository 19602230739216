import { Card } from "@/components/ui/card";
import { ServiceAlertsList } from "./ServiceAlertsList";
import { CreateServiceAlertDialog } from "./CreateServiceAlertDialog";
import { motion } from "framer-motion";
import { Bell } from "lucide-react";

export const ServiceAlertsSection = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Card className="p-6 bg-gray-800/50 backdrop-blur-lg border-gray-700/50">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-3">
            <div className="p-3 bg-blue-500/10 rounded-lg">
              <Bell className="w-6 h-6 text-blue-400" />
            </div>
            <div>
              <h2 className="text-xl font-bold text-white">Estado del Sistema</h2>
              <p className="text-sm text-gray-400">Gestiona y monitorea las alertas activas</p>
            </div>
          </div>
          <CreateServiceAlertDialog />
        </div>
        
        <ServiceAlertsList />

        <div className="mt-4 p-4 bg-gray-900/50 rounded-lg border border-gray-700/50">
          <p className="text-sm text-gray-400">
            Las alertas se actualizan en tiempo real. Los usuarios afectados pueden reportar problemas directamente desde cada alerta.
          </p>
        </div>
      </Card>
    </motion.div>
  );
};