import { ServiceAlertBadge } from "../ServiceAlertBadge";
import { Badge } from "@/components/ui/badge";

interface AlertHeaderProps {
  title: string;
  description: string;
  severity: "low" | "medium" | "high" | "critical";
  affectedServices: string[];
}

export const AlertHeader = ({ title, description, severity, affectedServices }: AlertHeaderProps) => {
  return (
    <div className="space-y-1">
      <ServiceAlertBadge severity={severity} />
      <h3 className="font-medium text-lg mt-2 text-gray-100">{title}</h3>
      <p className="text-gray-300 text-sm">{description}</p>
      
      {affectedServices && affectedServices.length > 0 && (
        <div className="flex flex-wrap gap-2 mt-2">
          {affectedServices.map((service) => (
            <Badge
              key={service}
              variant="outline"
              className="bg-gray-800/50 text-gray-300 border-gray-700"
            >
              {service}
            </Badge>
          ))}
        </div>
      )}
    </div>
  );
};