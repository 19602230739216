
import { Button } from "@/components/ui/button";
import { UserCog } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Empleado } from "@/integrations/supabase/types/tables";
import { useState } from "react";
import { EmpleadoStatusIndicator } from "./EmpleadoStatusIndicator";
import { TicketActions } from "./TicketActions";
import { AccessActions } from "./AccessActions";
import { BajaActions } from "./BajaActions";
import { TicketsDialog } from "./TicketsDialog";

interface ActionsListProps {
  empleado: Empleado;
  onBajaTemporalClick: () => void;
  onBajaTotalClick: () => void;
}

export const ActionsList = ({ 
  empleado,
  onBajaTemporalClick,
  onBajaTotalClick
}: ActionsListProps) => {
  const [showTicketsDialog, setShowTicketsDialog] = useState(false);

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" size="sm">
            <UserCog className="h-4 w-4 mr-2" />
            Acciones
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-56">
          <DropdownMenuLabel>Gestión de Empleado</DropdownMenuLabel>
          <DropdownMenuSeparator />
          
          <TicketActions 
            empleado={empleado} 
            onViewTickets={() => setShowTicketsDialog(true)} 
          />
          
          <DropdownMenuSeparator />
          
          <AccessActions empleado={empleado} />

          <DropdownMenuSeparator />
          
          <BajaActions 
            onBajaTemporalClick={onBajaTemporalClick}
            onBajaTotalClick={onBajaTotalClick}
          />

          {empleado.estado === 'inactivo' && (
            <EmpleadoStatusIndicator />
          )}
        </DropdownMenuContent>
      </DropdownMenu>

      <TicketsDialog 
        empleado={empleado} 
        open={showTicketsDialog} 
        onOpenChange={setShowTicketsDialog}
      />
    </>
  );
};
