import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSession } from "@supabase/auth-helpers-react";
import { Button } from "@/components/ui/button";
import { supabase } from "@/integrations/supabase/client";
import { Plus, Loader2, Filter, SlidersHorizontal } from "lucide-react";
import { TicketList } from "@/components/tickets/TicketList";
import { CreateTicketDialog } from "@/components/tickets/CreateTicketDialog";
import { useTickets } from "@/hooks/useTickets";
import { toast } from "sonner";
import { TicketStats } from "@/components/tickets/TicketStats";
import { AdvancedFilters } from "@/components/tickets/AdvancedFilters";
import { TicketExportOptions } from "@/components/tickets/TicketExportOptions";
import { Card } from "@/components/ui/card";
import { motion } from "framer-motion";

const TicketsPage = () => {
  const session = useSession();
  const navigate = useNavigate();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const [selectedPriorities, setSelectedPriorities] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

  const { data: tickets = [], isLoading, error } = useTickets();

  const filteredTickets = tickets.filter(ticket => {
    const priorityMatch = selectedPriorities.length === 0 || selectedPriorities.includes(ticket.priority);
    const categoryMatch = selectedCategories.length === 0 || selectedCategories.includes(ticket.category);
    const statusMatch = selectedStatuses.length === 0 || selectedStatuses.includes(ticket.status);
    return priorityMatch && categoryMatch && statusMatch;
  });

  const stats = {
    total: tickets.length,
    open: tickets.filter(t => t.status === 'open').length,
    inProgress: tickets.filter(t => t.status === 'in_progress').length,
    closed: tickets.filter(t => t.status === 'closed').length,
    byPriority: {
      urgent: tickets.filter(t => t.priority === 'urgent').length,
      high: tickets.filter(t => t.priority === 'high').length,
      medium: tickets.filter(t => t.priority === 'medium').length,
      low: tickets.filter(t => t.priority === 'low').length,
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data: { session }, error } = await supabase.auth.getSession();
        
        if (error) {
          console.error('Auth check error:', error);
          toast.error("Error al verificar la autenticación");
          navigate("/login");
          return;
        }

        if (!session) {
          console.log('No session found, redirecting to login');
          navigate("/login");
          return;
        }

      } catch (error) {
        console.error('Unexpected error:', error);
        toast.error("Error inesperado al verificar la autenticación");
        navigate("/login");
      } finally {
        setIsCheckingAuth(false);
      }
    };

    checkAuth();
  }, [navigate]);

  if (isCheckingAuth) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
      </div>
    );
  }

  if (error) {
    toast.error("Error al cargar los tickets");
    return (
      <div className="container mx-auto p-8 pt-24">
        <div className="text-center text-red-500">
          Error al cargar los tickets. Por favor, intente nuevamente.
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900">
      <div className="container mx-auto p-8 pt-24 space-y-6">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between mb-6">
            <div>
              <h1 className="text-3xl font-bold text-white mb-2">Sistema de Tickets</h1>
              <p className="text-gray-400">Gestiona y da seguimiento a tickets de soporte</p>
            </div>
            <div className="flex flex-wrap gap-2 items-center">
              <TicketExportOptions tickets={filteredTickets} />
              <AdvancedFilters
                onFilterChange={({ priorities, categories, statuses }) => {
                  setSelectedPriorities(priorities);
                  setSelectedCategories(categories);
                  setSelectedStatuses(statuses);
                }}
              />
              <Button 
                onClick={() => setIsCreateDialogOpen(true)}
                className="bg-blue-500 hover:bg-blue-600"
              >
                <Plus className="w-4 h-4 mr-2" />
                Nuevo Ticket
              </Button>
            </div>
          </div>

          <TicketStats stats={stats} />

          <Card className="p-6 bg-gray-800/50 border-gray-700">
            <TicketList 
              tickets={filteredTickets} 
              isLoading={isLoading} 
            />
          </Card>

          <CreateTicketDialog
            isOpen={isCreateDialogOpen} 
            onOpenChange={setIsCreateDialogOpen}
          />
        </motion.div>
      </div>
    </div>
  );
};

export default TicketsPage;
