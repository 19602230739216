import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { EditDialogProps } from "./types";
import { useState, useEffect } from "react";

export const EditDialog = ({ hire, open, onOpenChange, onUpdate }: EditDialogProps) => {
  const [formData, setFormData] = useState(hire || {
    id: "",
    name: "",
    cdsid: "",
    market: "",
    start_date: "",
    status: "pending" as "pending" | "in_progress" | "completed"
  });

  useEffect(() => {
    if (hire) {
      setFormData(hire);
    }
  }, [hire]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (hire) {
      onUpdate(formData);
      onOpenChange(false);
    }
  };

  if (!hire) return null;

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="bg-gray-800 text-white">
        <DialogHeader>
          <DialogTitle>Editar Incorporación</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label>Nombre</Label>
            <Input
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              className="bg-gray-700"
            />
          </div>
          
          <div className="space-y-2">
            <Label>CDSID</Label>
            <Input
              value={formData.cdsid}
              onChange={(e) => setFormData({ ...formData, cdsid: e.target.value.toUpperCase() })}
              className="bg-gray-700"
            />
          </div>

          <div className="space-y-2">
            <Label>Mercado</Label>
            <Select
              value={formData.market}
              onValueChange={(value) => setFormData({ ...formData, market: value })}
            >
              <SelectTrigger className="bg-gray-700">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="IBERIA">IBERIA</SelectItem>
                <SelectItem value="ITALY">ITALY</SelectItem>
                <SelectItem value="FRANCE">FRANCE</SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="space-y-2">
            <Label>Fecha de Incorporación</Label>
            <Input
              type="date"
              value={formData.start_date.split('T')[0]}
              onChange={(e) => setFormData({ ...formData, start_date: e.target.value })}
              className="bg-gray-700"
            />
          </div>

          <div className="space-y-2">
            <Label>Estado</Label>
            <Select
              value={formData.status}
              onValueChange={(value: 'pending' | 'in_progress' | 'completed') => 
                setFormData({ ...formData, status: value })}
            >
              <SelectTrigger className="bg-gray-700">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="pending">Pendiente</SelectItem>
                <SelectItem value="in_progress">En Proceso</SelectItem>
                <SelectItem value="completed">Completado</SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="flex justify-end gap-2">
            <Button 
              variant="outline" 
              type="button"
              onClick={() => onOpenChange(false)}
            >
              Cancelar
            </Button>
            <Button type="submit">
              Guardar Cambios
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};