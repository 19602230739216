import { useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { ExportForm } from "./config-exporter/ExportForm";
import { ConfigurationWizard } from "./config-exporter/ConfigurationWizard";

export const ConfigExporter = () => {
  const [isLoading, setIsLoading] = useState(false);

  const exportConfig = async () => {
    try {
      setIsLoading(true);
      console.log('Exporting company configurations...');
      
      // Fetch all necessary data
      const { data: companies, error: companiesError } = await supabase
        .from('companies')
        .select(`
          *,
          portal_config (*),
          portal_scripts (*),
          portal_themes (*)
        `);

      if (companiesError) throw companiesError;

      // Create export file with metadata
      const exportData = {
        version: "1.0",
        exportDate: new Date().toISOString(),
        companies,
        metadata: {
          totalCompanies: companies?.length || 0,
          exportType: "full"
        }
      };

      // Download file
      const blob = new Blob([JSON.stringify(exportData, null, 2)], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `portal-config-${new Date().toISOString().split('T')[0]}.json`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      toast.success("Configuración exportada correctamente");
    } catch (error) {
      console.error('Error exporting config:', error);
      toast.error("Error al exportar la configuración");
    } finally {
      setIsLoading(false);
    }
  };

  const importConfig = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setIsLoading(true);
      const file = event.target.files?.[0];
      if (!file) return;

      const reader = new FileReader();
      
      reader.onload = async (e) => {
        try {
          const importedData = JSON.parse(e.target?.result as string);
          
          if (!importedData.version || !importedData.companies) {
            throw new Error('Invalid import file format');
          }

          for (const company of importedData.companies) {
            // Create company with its configurations
            const { data: newCompany, error: companyError } = await supabase
              .from('companies')
              .insert({
                name: `${company.name} (Imported)`,
                logo_url: company.logo_url,
                active: company.active
              })
              .select()
              .single();

            if (companyError) throw companyError;

            // Import portal configuration
            if (company.portal_config) {
              await supabase
                .from('portal_config')
                .insert({
                  ...company.portal_config,
                  company_id: newCompany.id
                });
            }

            // Import scripts
            if (company.portal_scripts) {
              await supabase
                .from('portal_scripts')
                .insert(
                  company.portal_scripts.map((script: any) => ({
                    ...script,
                    company_id: newCompany.id
                  }))
                );
            }

            // Import themes
            if (company.portal_themes) {
              await supabase
                .from('portal_themes')
                .insert(
                  company.portal_themes.map((theme: any) => ({
                    ...theme,
                    company_id: newCompany.id
                  }))
                );
            }
          }

          toast.success("Configuración importada correctamente");
        } catch (error) {
          console.error('Error processing import:', error);
          toast.error("Error al procesar el archivo de importación");
        }
      };

      reader.readAsText(file);
    } catch (error) {
      console.error('Error importing config:', error);
      toast.error("Error al importar la configuración");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <ConfigurationWizard />
      <ExportForm 
        isLoading={isLoading}
        onExport={exportConfig}
        onImport={importConfig}
      />
    </div>
  );
};