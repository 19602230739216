
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card } from "@/components/ui/card";
import { toast } from "sonner";
import { Loader2, AlertCircle } from "lucide-react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

interface LoginFormProps {
  onSuccess?: () => void;
}

const loginSchema = z.object({
  email: z.string().email("Correo electrónico inválido").min(1, "El correo electrónico es requerido"),
  password: z.string().min(1, "La contraseña es requerida"),
});

type LoginFormValues = z.infer<typeof loginSchema>;

export const LoginForm = ({ onSuccess }: LoginFormProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [loginAttempts, setLoginAttempts] = useState(0);

  const from = location.state?.from || "/dashboard";

  const form = useForm<LoginFormValues>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  // Check if the user is already logged in on mount
  useEffect(() => {
    const checkSession = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (session?.user) {
          console.log('User already logged in, redirecting to:', from);
          onSuccess ? onSuccess() : navigate(from);
        }
      } catch (error) {
        console.error('Error checking session:', error);
      }
    };

    checkSession();
  }, [navigate, from, onSuccess]);

  const handleSubmit = async (values: LoginFormValues) => {
    if (isLoading) return;

    try {
      setIsLoading(true);
      console.log("LoginForm - Attempting login...");

      const { data, error: signInError } = await supabase.auth.signInWithPassword({
        email: values.email,
        password: values.password,
      });

      if (signInError) {
        console.error("LoginForm - Login error:", signInError);
        setLoginAttempts(prev => prev + 1);
        
        // Provide user-friendly error messages
        if (signInError.message.includes("Invalid login credentials")) {
          throw new Error("Credenciales inválidas. Por favor verifique su correo y contraseña.");
        }
        
        if (signInError.message.includes("Email not confirmed")) {
          throw new Error("Correo no confirmado. Por favor verifique su bandeja de entrada.");
        }
        
        throw signInError;
      }

      if (!data.session) {
        throw new Error("No se pudo crear la sesión");
      }

      console.log("LoginForm - Login successful, redirecting to:", from);
      toast.success("Inicio de sesión exitoso");
      
      // Reset login attempts on successful login
      setLoginAttempts(0);
      
      if (onSuccess) {
        onSuccess();
      } else {
        navigate(from);
      }
    } catch (error: unknown) {
      console.error("LoginForm - Login error:", error);
      toast.error("Error al iniciar sesión", {
        description: (error instanceof Error ? error.message : "Error desconocido"),
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="w-full max-w-md p-6 bg-gray-800/50 border-gray-700">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
          {loginAttempts >= 3 && (
            <div className="bg-yellow-500/20 p-3 rounded-md flex items-start gap-2 mb-4">
              <AlertCircle className="text-yellow-500 h-5 w-5 mt-0.5" />
              <div className="text-sm text-yellow-200">
                <p className="font-semibold">Múltiples intentos de inicio de sesión</p>
                <p>Asegúrese de que está ingresando las credenciales correctas. Si olvidó su contraseña, contacte a soporte.</p>
              </div>
            </div>
          )}
          
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-sm font-medium text-gray-200">Email</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="email"
                    className="bg-gray-700/50 border-gray-600"
                    disabled={isLoading}
                    placeholder="usuario@ejemplo.com"
                  />
                </FormControl>
                <FormMessage className="text-red-400" />
              </FormItem>
            )}
          />
          
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-sm font-medium text-gray-200">Contraseña</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="password"
                    className="bg-gray-700/50 border-gray-600"
                    disabled={isLoading}
                  />
                </FormControl>
                <FormMessage className="text-red-400" />
              </FormItem>
            )}
          />

          <Button 
            type="submit" 
            className="w-full"
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Iniciando sesión...
              </>
            ) : (
              "Iniciar sesión"
            )}
          </Button>
        </form>
      </Form>
    </Card>
  );
};
