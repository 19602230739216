import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

export const useTicketComments = (ticketId: string) => {
  const [comments, setComments] = useState<any[]>([]);

  useEffect(() => {
    if (ticketId) {
      loadComments();
    }
  }, [ticketId]);

  const loadComments = async () => {
    console.log('Loading comments for ticket:', ticketId);
    try {
      const { data: commentsData, error } = await supabase
        .from("ticket_comments")
        .select("*")
        .eq("ticket_id", ticketId)
        .order("created_at", { ascending: true });

      if (error) {
        console.error('Error loading comments:', error);
        throw error;
      }

      const commentsWithUserDetails = await Promise.all(
        (commentsData || []).map(async (comment) => {
          try {
            const { data: userData, error: userError } = await supabase
              .from("users")
              .select("full_name")
              .eq("id", comment.author)
              .maybeSingle();

            if (userError) {
              console.error('Error fetching user data:', userError);
              return {
                ...comment,
                user: { full_name: 'Unknown User' }
              };
            }

            return {
              ...comment,
              user: { full_name: userData?.full_name || 'Unknown User' }
            };
          } catch (error) {
            console.error('Error in user data fetch:', error);
            return {
              ...comment,
              user: { full_name: 'Unknown User' }
            };
          }
        })
      );

      console.log('Comments loaded successfully:', commentsWithUserDetails?.length, 'comments');
      setComments(commentsWithUserDetails);
    } catch (error) {
      console.error('Error in loadComments:', error);
      toast.error("Error loading comments");
    }
  };

  const handleAddComment = async (content: string) => {
    try {
      const { data: userData } = await supabase.auth.getUser();
      if (!userData.user) throw new Error("Usuario no autenticado");

      const { error } = await supabase
        .from("ticket_comments")
        .insert({
          ticket_id: ticketId,
          content,
          author: userData.user.id,
        });

      if (error) throw error;

      await supabase.functions.invoke("notify-ticket-updates", {
        body: {
          notification: {
            ticketId,
            type: "comment_added",
            content
          }
        }
      });

      loadComments();
      toast.success("Comentario añadido correctamente");
    } catch (error: any) {
      console.error("Error adding comment:", error);
      toast.error("No se pudo añadir el comentario");
    }
  };

  return {
    comments,
    handleAddComment,
    loadComments
  };
};