import { Button } from "@/components/ui/button";
import { Plus, Database, Upload } from "lucide-react";
import { useNavigate } from "react-router-dom";

export const InventoryHeader = () => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-between items-center bg-white/5 backdrop-blur-lg rounded-lg p-6 border border-blue-500/10 shadow-lg">
      <div className="flex items-center gap-4">
        <div className="p-3 bg-blue-500/10 rounded-lg">
          <Database className="w-6 h-6 text-blue-400" />
        </div>
        <div>
          <h1 className="text-2xl font-bold text-white">Inventario IT</h1>
          <p className="text-blue-300">Gestión de equipos y recursos tecnológicos</p>
        </div>
      </div>
      <div className="flex gap-3">
        <Button 
          onClick={() => navigate("/inventory/return")} 
          variant="outline"
          className="bg-blue-500/10 hover:bg-blue-500/20 text-blue-400 border-blue-500/30"
        >
          <Upload className="w-4 h-4 mr-2" />
          Registrar Devolución
        </Button>
        <Button 
          onClick={() => navigate("/inventory/new")} 
          className="bg-blue-500 hover:bg-blue-600 text-white"
        >
          <Plus className="w-4 h-4 mr-2" />
          Añadir Equipo
        </Button>
      </div>
    </div>
  );
};