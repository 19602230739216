import { useState, useEffect } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';

export const useAlertAffectedUsers = (alertId: string, currentUserCDSID?: string) => {
  const [isReporting, setIsReporting] = useState(false);
  const [hasReported, setHasReported] = useState(false);
  const [affectedUsersCount, setAffectedUsersCount] = useState(0);
  const [affectedUsers, setAffectedUsers] = useState<Array<{ user_cdsid: string, reported_at: string }>>([]);

  // Verificar si el usuario actual ya ha reportado esta alerta
  useEffect(() => {
    const checkIfUserReported = async () => {
      if (!currentUserCDSID) return;

      const { data, error } = await supabase
        .from('alert_affected_users')
        .select('*')
        .eq('alert_id', alertId)
        .eq('user_cdsid', currentUserCDSID)
        .maybeSingle();

      if (error) {
        console.error('Error checking user report:', error);
        return;
      }

      setHasReported(!!data);
    };

    checkIfUserReported();
  }, [alertId, currentUserCDSID]);

  // Suscribirse a cambios en tiempo real de usuarios afectados
  useEffect(() => {
    console.log('Setting up realtime subscription for alert:', alertId);
    
    const channel = supabase
      .channel(`alert_affected_users_${alertId}`)
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'alert_affected_users',
          filter: `alert_id=eq.${alertId}`
        },
        async (payload) => {
          console.log('Received realtime update:', payload);
          // Actualizar el contador
          const { count } = await supabase
            .from('alert_affected_users')
            .select('*', { count: 'exact', head: true })
            .eq('alert_id', alertId);
          
          console.log('Updated affected users count:', count);
          setAffectedUsersCount(count || 0);
        }
      )
      .subscribe();

    // Cargar el conteo inicial
    const fetchInitialCount = async () => {
      const { count } = await supabase
        .from('alert_affected_users')
        .select('*', { count: 'exact', head: true })
        .eq('alert_id', alertId);
      
      setAffectedUsersCount(count || 0);
    };

    fetchInitialCount();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [alertId]);

  const reportAsAffected = async (cdsid: string) => {
    if (!cdsid) {
      toast.error("Por favor ingresa tu CDSID para reportar que estás afectado");
      return;
    }

    setIsReporting(true);
    try {
      console.log('Reporting user as affected:', { alertId, cdsid });
      
      const { error } = await supabase
        .from("alert_affected_users")
        .insert({
          alert_id: alertId,
          user_cdsid: cdsid,
        });

      if (error) {
        console.error('Error reporting affected user:', error);
        if (error.code === "23505") {
          setHasReported(true);
          toast.error("Ya has reportado que estás afectado por esta alerta");
        } else {
          toast.error("Error al reportar que estás afectado");
        }
      } else {
        console.log('Successfully reported user as affected');
        setHasReported(true);
        setAffectedUsersCount(prev => prev + 1);
        toast.success("¡Gracias por reportar!", {
          description: "Hemos registrado que estás afectado por esta alerta.",
        });
      }
    } catch (error) {
      console.error("Error reporting affected user:", error);
      toast.error("Error al reportar que estás afectado");
    } finally {
      setIsReporting(false);
    }
  };

  const fetchAffectedUsers = async () => {
    try {
      const { data, error } = await supabase
        .from('alert_affected_users')
        .select('*')
        .eq('alert_id', alertId)
        .order('reported_at', { ascending: false });

      if (error) throw error;
      setAffectedUsers(data);
      return data;
    } catch (error) {
      console.error('Error fetching affected users:', error);
      toast.error("Error al cargar usuarios afectados");
      return [];
    }
  };

  return {
    isReporting,
    hasReported,
    affectedUsersCount,
    affectedUsers,
    reportAsAffected,
    fetchAffectedUsers
  };
};