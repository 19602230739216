import { Building2 } from "lucide-react";
import { CompanyForm } from "./company-settings/CompanyForm";
import { ConfigExporter } from "./company-settings/ConfigExporter";
import { ScriptManager } from "./company-settings/ScriptManager";
import { ThemeCustomizer } from "./company-settings/ThemeCustomizer";

export const CompanySettings = () => {
  return (
    <div className="space-y-6">
      <div className="flex items-center gap-2">
        <Building2 className="h-5 w-5" />
        <h2 className="text-xl font-semibold">Gestión de Empresas</h2>
      </div>

      <CompanyForm />
      <ScriptManager />
      <ThemeCustomizer />
      <ConfigExporter />
    </div>
  );
};