import { format } from "date-fns";
import { Badge } from "@/components/ui/badge";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Check, Clock, AlertCircle, Edit, MessageSquare, MessageCircle } from "lucide-react";
import { Issue } from "../IssueList";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";

interface Comment {
  id: string;
  content: string;
  author: string;
  created_at: string;
}

interface IssueCardProps {
  issue: Issue;
  onStatusUpdate: (id: string, status: string) => Promise<void>;
  onEdit: (issue: Issue) => void;
  onComment: (issue: Issue) => void;
  isUpdating: boolean;
}

export const IssueCard = ({ 
  issue, 
  onStatusUpdate, 
  onEdit, 
  onComment,
  isUpdating 
}: IssueCardProps) => {
  const [comments, setComments] = useState<Comment[]>([]);

  useEffect(() => {
    const fetchComments = async () => {
      const { data, error } = await supabase
        .from('pending_issue_comments')
        .select('*')
        .eq('issue_id', issue.id)
        .order('created_at', { ascending: true });

      if (error) {
        console.error('Error fetching comments:', error);
      } else {
        setComments(data || []);
      }
    };

    fetchComments();

    // Subscribe to real-time changes
    const channel = supabase
      .channel('pending-issue-comments')
      .on(
        'postgres_changes',
        { 
          event: '*', 
          schema: 'public', 
          table: 'pending_issue_comments',
          filter: `issue_id=eq.${issue.id}`
        },
        () => {
          fetchComments();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [issue.id]);

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'resolved':
        return 'bg-green-500/20 text-green-400';
      case 'in_progress':
        return 'bg-blue-500/20 text-blue-400';
      case 'pending':
        return 'bg-yellow-500/20 text-yellow-400';
      default:
        return 'bg-gray-500/20 text-gray-400';
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'resolved':
        return <Check className="w-4 h-4" />;
      case 'in_progress':
        return <Clock className="w-4 h-4" />;
      case 'pending':
        return <AlertCircle className="w-4 h-4" />;
      default:
        return null;
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Card className="bg-gray-800/50 p-6 hover:bg-gray-800/70 transition-colors">
        <div className="flex justify-between items-start gap-4">
          <div className="space-y-2 flex-1">
            <h3 className="text-lg font-medium text-white">{issue.title}</h3>
            <p className="text-gray-400 whitespace-pre-line">{issue.description}</p>
          </div>
          <Badge className={`${getStatusColor(issue.status)} flex items-center gap-2`}>
            {getStatusIcon(issue.status)}
            {issue.status}
          </Badge>
        </div>
        
        <div className="mt-4 flex flex-wrap gap-4 text-sm text-gray-400">
          <span>Reportado por: {issue.reporter}</span>
          <span>•</span>
          <span>Usuarios afectados: {issue.affected_users}</span>
          <span>•</span>
          <span>Fecha: {format(new Date(issue.created_at), 'dd/MM/yyyy HH:mm')}</span>
        </div>

        {comments.length > 0 && (
          <div className="mt-6 space-y-4">
            <div className="flex items-center gap-2 text-blue-400">
              <MessageCircle className="w-5 h-5" />
              <h4 className="font-medium">
                {comments.length} {comments.length === 1 ? 'Comentario' : 'Comentarios'}
              </h4>
            </div>
            <div className="space-y-4 pl-4 border-l-2 border-gray-700">
              {comments.map((comment) => (
                <motion.div
                  key={comment.id}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  className="relative"
                >
                  <div className="absolute -left-[41px] top-0 w-5 h-5 rounded-full bg-gray-700 border-2 border-gray-600 flex items-center justify-center">
                    <div className="w-2 h-2 rounded-full bg-blue-400" />
                  </div>
                  <div className="bg-gray-700/30 backdrop-blur-sm rounded-lg p-4 hover:bg-gray-700/40 transition-colors">
                    <div className="flex justify-between items-center mb-2">
                      <span className="text-blue-400 font-medium">{comment.author}</span>
                      <span className="text-xs text-gray-500">
                        {format(new Date(comment.created_at), 'dd/MM/yyyy HH:mm')}
                      </span>
                    </div>
                    <p className="text-gray-300 text-sm leading-relaxed">{comment.content}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        )}

        <div className="mt-6 flex gap-2 flex-wrap">
          <Button 
            variant="outline" 
            size="sm"
            onClick={() => onStatusUpdate(issue.id, 'pending')}
            disabled={isUpdating}
            className={`${issue.status === 'pending' ? 'bg-yellow-500/20 text-yellow-400' : ''}`}
          >
            <AlertCircle className="w-4 h-4 mr-2" />
            Pendiente
          </Button>
          <Button 
            variant="outline"
            size="sm"
            onClick={() => onStatusUpdate(issue.id, 'in_progress')}
            disabled={isUpdating}
            className={`${issue.status === 'in_progress' ? 'bg-blue-500/20 text-blue-400' : ''}`}
          >
            <Clock className="w-4 h-4 mr-2" />
            En Progreso
          </Button>
          <Button 
            variant="outline"
            size="sm"
            onClick={() => onStatusUpdate(issue.id, 'resolved')}
            disabled={isUpdating}
            className={`${issue.status === 'resolved' ? 'bg-green-500/20 text-green-400' : ''}`}
          >
            <Check className="w-4 h-4 mr-2" />
            Resuelto
          </Button>
          <Button 
            variant="outline" 
            size="sm"
            onClick={() => onEdit(issue)}
          >
            <Edit className="w-4 h-4 mr-2" />
            Editar
          </Button>
          <Button 
            variant="outline" 
            size="sm"
            onClick={() => onComment(issue)}
          >
            <MessageSquare className="w-4 h-4 mr-2" />
            Comentar
          </Button>
        </div>
      </Card>
    </motion.div>
  );
};