
import { Shield, Ticket } from "lucide-react";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { useNavigate } from "react-router-dom";
import { Empleado } from "@/integrations/supabase/types/tables";

interface TicketActionsProps {
  empleado: Empleado;
  onViewTickets: () => void;
}

export const TicketActions = ({ empleado, onViewTickets }: TicketActionsProps) => {
  const navigate = useNavigate();

  const handleCreateTicket = () => {
    console.log('Creating ticket for employee:', empleado);
    navigate("/tickets", {
      state: { 
        prefilledData: {
          submitter_cdsid: empleado.cdsid,
          submitter_name: `${empleado.nombre} ${empleado.apellidos}`,
          submitter_email: empleado.email,
          affected_cdsid: empleado.cdsid
        }
      }
    });
  };

  return (
    <>
      <DropdownMenuItem onClick={handleCreateTicket}>
        <Shield className="h-4 w-4 mr-2" />
        Crear Ticket
      </DropdownMenuItem>
      
      <DropdownMenuItem onClick={onViewTickets}>
        <Ticket className="h-4 w-4 mr-2" />
        Ver Tickets
      </DropdownMenuItem>
    </>
  );
};
