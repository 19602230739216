
import { Calendar, UserMinus } from "lucide-react";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";

interface BajaActionsProps {
  onBajaTemporalClick: () => void;
  onBajaTotalClick: () => void;
}

export const BajaActions = ({ 
  onBajaTemporalClick, 
  onBajaTotalClick 
}: BajaActionsProps) => {
  return (
    <>
      <DropdownMenuItem onClick={onBajaTemporalClick}>
        <Calendar className="h-4 w-4 mr-2" />
        Baja Temporal
      </DropdownMenuItem>
      
      <DropdownMenuItem 
        onClick={onBajaTotalClick}
        className="text-red-600 focus:text-red-600"
      >
        <UserMinus className="h-4 w-4 mr-2" />
        Baja Total
      </DropdownMenuItem>
    </>
  );
};
