import { Card } from "@/components/ui/card";
import { Equipment } from "@/types/inventory";
import { User } from "lucide-react";
import { motion } from "framer-motion";

interface UserAssignmentSectionProps {
  inventory: Equipment[];
  users: { cdsid: string; full_name: string }[];
}

export const UserAssignmentSection = ({ inventory, users }: UserAssignmentSectionProps) => {
  const assignedEquipment = inventory.filter(item => item.asignado_a);

  const userAssignments = assignedEquipment.reduce((acc, item) => {
    const user = users.find(u => u.cdsid === item.asignado_a);
    if (!user) return acc;

    if (!acc[user.cdsid]) {
      acc[user.cdsid] = {
        user,
        equipment: []
      };
    }
    acc[user.cdsid].equipment.push(item);
    return acc;
  }, {} as Record<string, { user: typeof users[0], equipment: Equipment[] }>);

  return (
    <div className="space-y-4">
      <h2 className="text-xl font-bold text-white flex items-center gap-2">
        <User className="w-5 h-5 text-blue-400" />
        Asignaciones de Equipos
      </h2>

      <div className="grid gap-4">
        {Object.values(userAssignments).map(({ user, equipment }, index) => (
          <motion.div
            key={user.cdsid}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: index * 0.1 }}
          >
            <Card className="p-6 bg-gray-800/50">
              <div className="flex items-center gap-3 mb-4">
                <div className="p-2 rounded-full bg-blue-500/10">
                  <User className="h-5 w-5 text-blue-400" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-white">{user.full_name}</h3>
                  <p className="text-sm text-gray-400">
                    {equipment.length} {equipment.length === 1 ? "equipo" : "equipos"} asignados
                  </p>
                </div>
              </div>

              <div className="space-y-3">
                {equipment.map((item) => (
                  <div
                    key={item.id}
                    className="flex items-center justify-between p-3 rounded-lg bg-gray-900/50 border border-gray-700"
                  >
                    <div>
                      <p className="font-medium text-gray-200">{item.nombre}</p>
                      <p className="text-sm text-gray-400">
                        {item.tipo_equipo} - SN: {item.serial_number || "N/A"}
                      </p>
                    </div>
                    <span className="text-sm px-2 py-1 rounded-full bg-blue-500/10 text-blue-400">
                      {item.estado}
                    </span>
                  </div>
                ))}
              </div>
            </Card>
          </motion.div>
        ))}

        {Object.keys(userAssignments).length === 0 && (
          <Card className="p-6 text-center bg-gray-800/50">
            <p className="text-gray-400">No hay asignaciones activas</p>
          </Card>
        )}
      </div>
    </div>
  );
};