import { Button } from "@/components/ui/button";
import { FormFields } from "./form/FormFields";
import { useAccessRequest } from "@/hooks/useAccessRequest";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { useEmpleadoAccesos } from "@/hooks/useEmpleadoAccesos";

export const AccessRequestForm = () => {
  const location = useLocation();
  const prefilledData = location.state?.prefilledData;

  const {
    loading,
    cdsid,
    setCdsid,
    nombre,
    setNombre,
    apellidos,
    setApellidos,
    mercado,
    setMercado,
    plataforma,
    setPlataforma,
    tipoSolicitud,
    setTipoSolicitud,
    platforms,
    requestTypes,
    handleSubmit
  } = useAccessRequest();

  const { data: missingAccesos } = useEmpleadoAccesos(
    prefilledData?.empleadoId
  );

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="p-6 rounded-lg bg-gray-800/40 backdrop-blur-lg border border-white/10 
                 hover:bg-gray-800/50 transition-all duration-300 shadow-lg"
    >
      <form onSubmit={handleSubmit} className="space-y-6">
        <FormFields
          cdsid={cdsid}
          setCdsid={setCdsid}
          nombre={nombre}
          setNombre={setNombre}
          apellidos={apellidos}
          setApellidos={setApellidos}
          mercado={mercado}
          setMercado={setMercado}
          plataforma={plataforma}
          setPlataforma={setPlataforma}
          tipoSolicitud={tipoSolicitud}
          setTipoSolicitud={setTipoSolicitud}
          platforms={platforms}
          requestTypes={requestTypes}
          missingAccesos={missingAccesos}
          isPrefilledEmployee={!!prefilledData}
        />
        
        <Button
          type="submit"
          className="w-full bg-blue-600 hover:bg-blue-700 text-white transition-all duration-300 
                   transform hover:scale-[1.02] disabled:opacity-50 disabled:cursor-not-allowed 
                   disabled:hover:scale-100 shadow-lg hover:shadow-xl"
          disabled={loading}
        >
          {loading ? (
            <div className="flex items-center justify-center space-x-2">
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white" />
              <span>Procesando solicitud...</span>
            </div>
          ) : (
            "Enviar Solicitud"
          )}
        </Button>
      </form>
    </motion.div>
  );
};