import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Search } from "lucide-react";
import { PriorityFilter } from "./filters/PriorityFilter";
import { CategoryFilter } from "./filters/CategoryFilter";
import { SortOptions } from "./filters/SortOptions";

interface FilterState {
  priorities: string[];
  categories: string[];
  statuses: string[];
  searchTerm: string;
  sortBy: 'created_at' | 'priority' | 'status';
  sortOrder: 'asc' | 'desc';
}

interface EnhancedTicketFiltersProps {
  onFilterChange: (filters: FilterState) => void;
}

export const EnhancedTicketFilters = ({ onFilterChange }: EnhancedTicketFiltersProps) => {
  const [filters, setFilters] = useState<FilterState>({
    priorities: [],
    categories: [],
    statuses: [],
    searchTerm: '',
    sortBy: 'created_at',
    sortOrder: 'desc'
  });

  const handleFilterChange = (type: keyof FilterState, value: any) => {
    const newFilters = { ...filters, [type]: value };
    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  const handleSortChange = (field: 'created_at' | 'priority' | 'status', order: 'asc' | 'desc') => {
    handleFilterChange('sortBy', field);
    handleFilterChange('sortOrder', order);
  };

  const clearFilters = () => {
    const defaultFilters: FilterState = {
      priorities: [],
      categories: [],
      statuses: [],
      searchTerm: '',
      sortBy: 'created_at',
      sortOrder: 'desc'
    };
    setFilters(defaultFilters);
    onFilterChange(defaultFilters);
  };

  return (
    <div className="flex flex-wrap gap-2 items-center bg-gray-800/50 p-4 rounded-lg">
      <div className="flex-1 min-w-[200px] relative">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-400" />
        <Input
          placeholder="Buscar tickets..."
          value={filters.searchTerm}
          onChange={(e) => handleFilterChange('searchTerm', e.target.value)}
          className="bg-gray-700/50 border-gray-600 pl-9"
        />
      </div>

      <PriorityFilter
        selectedPriorities={filters.priorities}
        onPriorityChange={(priorities) => handleFilterChange('priorities', priorities)}
      />

      <CategoryFilter
        selectedCategories={filters.categories}
        onCategoryChange={(categories) => handleFilterChange('categories', categories)}
      />

      <SortOptions
        sortBy={filters.sortBy}
        sortOrder={filters.sortOrder}
        onSortChange={handleSortChange}
      />

      {(filters.priorities.length > 0 || 
        filters.categories.length > 0 || 
        filters.statuses.length > 0 || 
        filters.searchTerm) && (
        <Button
          variant="ghost"
          size="sm"
          onClick={clearFilters}
          className="text-gray-400 hover:text-white"
        >
          Limpiar filtros
        </Button>
      )}
    </div>
  );
};