import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

export interface Ticket {
  id: string;
  title: string;
  description: string;
  status: string;
  priority: string;
  category: string;
  creator_id: string;
  creator_email: string;
  assigned_to: string | null;
  created_at: string;
  submitter_cdsid?: string;
  submitter_name?: string;
  submitter_market?: string;
  ticket_number: number;
  submitter_email?: string;
  tags?: string[];
  resolution_time?: number;
  closed_at?: string;
  related_tickets?: string[];
  affected_cdsid?: string | null;
}

interface UseTicketsOptions {
  status?: string[];
  priority?: string[];
  category?: string[];
  assignedTo?: string;
  dateRange?: { start: Date; end: Date };
  searchTerm?: string;
  staleTime?: number;
  gcTime?: number;
  enabled?: boolean;
}

export const useTickets = (options: UseTicketsOptions = {}) => {
  return useQuery({
    queryKey: ["tickets", options],
    queryFn: async () => {
      console.log('Fetching tickets data with options:', options);
      let query = supabase
        .from("tickets")
        .select("*");

      // Apply filters
      if (options.status?.length) {
        query = query.in('status', options.status);
      }

      if (options.priority?.length) {
        query = query.in('priority', options.priority);
      }

      if (options.category?.length) {
        query = query.in('category', options.category);
      }

      if (options.assignedTo) {
        query = query.eq('assigned_to', options.assignedTo);
      }

      if (options.dateRange) {
        query = query
          .gte('created_at', options.dateRange.start.toISOString())
          .lte('created_at', options.dateRange.end.toISOString());
      }

      if (options.searchTerm) {
        query = query.or(`title.ilike.%${options.searchTerm}%,description.ilike.%${options.searchTerm}%`);
      }

      const { data, error } = await query
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching tickets:', error);
        toast.error("Error al cargar los tickets");
        throw error;
      }

      // Log activity for new tickets
      if (data?.length) {
        const latestTicket = data[0];
        if (new Date(latestTicket.created_at).getTime() > Date.now() - 5000) { // If created in last 5 seconds
          console.log('Logging activity for new ticket:', latestTicket.id);
          await supabase.from('activity_log').insert({
            action_type: 'ticket_created',
            description: `Nuevo ticket creado: ${latestTicket.title}`,
            user_id: latestTicket.creator_id,
            metadata: {
              ticket_id: latestTicket.id,
              ticket_title: latestTicket.title,
              ticket_status: latestTicket.status
            }
          });
        }
      }

      console.log('Tickets data fetched:', data?.length, 'items');
      return data as Ticket[];
    },
    staleTime: options.staleTime,
    gcTime: options.gcTime,
    enabled: options.enabled !== false,
    meta: {
      onError: (error: unknown) => {
        console.error('Query error:', error);
        toast.error("Error de conexión con el servidor");
      }
    }
  });
};

interface UseTicketStatsOptions {
  staleTime?: number;
  enabled?: boolean;
}

export const useTicketStats = (options: UseTicketStatsOptions = {}) => {
  return useQuery({
    queryKey: ["ticket-stats"],
    queryFn: async () => {
      console.log('Fetching ticket stats...');
      const { data: tickets, error } = await supabase
        .from("tickets")
        .select("status, priority");

      if (error) throw error;

      const stats = {
        total: tickets.length,
        byStatus: {
          open: tickets.filter(t => t.status === "open").length,
          inProgress: tickets.filter(t => t.status === "in_progress").length,
          closed: tickets.filter(t => t.status === "closed").length
        },
        byPriority: {
          urgent: tickets.filter(t => t.priority === "urgent").length,
          high: tickets.filter(t => t.priority === "high").length,
          medium: tickets.filter(t => t.priority === "medium").length,
          low: tickets.filter(t => t.priority === "low").length
        }
      };

      console.log('Ticket stats calculated:', stats);
      return stats;
    },
    staleTime: options.staleTime,
    enabled: options.enabled !== false,
    meta: {
      onError: (error: unknown) => {
        console.error('Stats query error:', error);
        toast.error("Error al cargar las estadísticas");
      }
    }
  });
};
