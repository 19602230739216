import { useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { CalendarPlus } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

interface AddEventDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onEventAdded: () => void;
}

export const AddEventDialog = ({ isOpen, onOpenChange, onEventAdded }: AddEventDialogProps) => {
  const [newEvent, setNewEvent] = useState({
    title: "",
    description: "",
    start_date: "",
    end_date: "",
    event_type: "maintenance"
  });

  const handleAddEvent = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      
      const { error } = await supabase
        .from("calendar_events")
        .insert([{
          ...newEvent,
          created_by: user?.id
        }]);

      if (error) throw error;

      toast.success("Evento añadido correctamente");
      onOpenChange(false);
      setNewEvent({
        title: "",
        description: "",
        start_date: "",
        end_date: "",
        event_type: "maintenance"
      });
      onEventAdded();
    } catch (error) {
      console.error("Error adding event:", error);
      toast.error("Error al añadir el evento");
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        <Button className="bg-blue-600 hover:bg-blue-700">
          <CalendarPlus className="w-4 h-4 mr-2" />
          Nuevo Evento
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Añadir Nuevo Evento</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid gap-2">
            <Label htmlFor="title">Título</Label>
            <Input
              id="title"
              value={newEvent.title}
              onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
            />
          </div>
          <div className="grid gap-2">
            <Label htmlFor="description">Descripción</Label>
            <Textarea
              id="description"
              value={newEvent.description}
              onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })}
            />
          </div>
          <div className="grid gap-2">
            <Label htmlFor="start_date">Fecha Inicio</Label>
            <Input
              id="start_date"
              type="datetime-local"
              value={newEvent.start_date}
              onChange={(e) => setNewEvent({ ...newEvent, start_date: e.target.value })}
            />
          </div>
          <div className="grid gap-2">
            <Label htmlFor="end_date">Fecha Fin</Label>
            <Input
              id="end_date"
              type="datetime-local"
              value={newEvent.end_date}
              onChange={(e) => setNewEvent({ ...newEvent, end_date: e.target.value })}
            />
          </div>
          <Button onClick={handleAddEvent}>Guardar Evento</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};