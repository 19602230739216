
import { motion } from "framer-motion";

interface TicketAnalysisProps {
  analysis: string;
}

export const TicketAnalysis = ({ analysis }: TicketAnalysisProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div className="bg-gray-800/50 border border-gray-700 rounded-lg p-4 mb-4">
        <h3 className="text-sm font-medium mb-2 text-blue-400">Análisis de IA</h3>
        <p className="text-sm text-gray-300 whitespace-pre-line">{analysis}</p>
      </div>
    </motion.div>
  );
};
