import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { Loader2, Pencil } from "lucide-react";
import { useSession } from "@supabase/auth-helpers-react";
import { Button } from "@/components/ui/button";
import { EditAccessRequestDialog } from "./EditAccessRequestDialog";

interface AccessRequest {
  id: string;
  estado: string;
  created_at: string;
  plataforma_id: string;
  tipo_solicitud: string;
  updated_at: string;
  user_id: string;
  plataformas_acceso: {
    nombre: string;
    url: string;
  };
  tipos_solicitud_acceso: {
    nombre: string;
  };
}

export const AccessRequestList = () => {
  const session = useSession();
  const [selectedRequest, setSelectedRequest] = useState<AccessRequest | null>(null);

  const { data: requests, isLoading, error, refetch } = useQuery({
    queryKey: ["accessRequests", session?.user?.id],
    queryFn: async () => {
      console.log("Fetching access requests for user:", session?.user?.id);
      if (!session?.user) {
        console.log("No user session found");
        return [];
      }

      const { data, error } = await supabase
        .from("solicitudes_plataforma")
        .select(`
          *,
          plataformas_acceso (
            nombre,
            url
          ),
          tipos_solicitud_acceso (
            nombre
          )
        `)
        .eq("user_id", session.user.id)
        .order('created_at', { ascending: false });

      if (error) {
        console.error("Error fetching requests:", error);
        throw error;
      }
      
      console.log("Fetched requests:", data);
      return data as AccessRequest[];
    },
    enabled: !!session?.user,
  });

  const getStatusBadge = (status: string) => {
    switch (status) {
      case "aprobado":
        return <Badge className="bg-green-500">Aprobado</Badge>;
      case "rechazado":
        return <Badge variant="destructive">Rechazado</Badge>;
      case "en_proceso":
        return <Badge className="bg-blue-500">En Proceso</Badge>;
      case "pendiente":
      default:
        return <Badge variant="secondary">Pendiente</Badge>;
    }
  };

  if (!session?.user) {
    return (
      <div className="text-center py-8 text-gray-400">
        Inicia sesión para ver tus solicitudes
      </div>
    );
  }

  if (error) {
    console.error("Error in AccessRequestList:", error);
    return (
      <div className="text-center py-8 text-red-400">
        Error al cargar las solicitudes
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex justify-center p-8">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  if (!requests?.length) {
    return (
      <div className="text-center py-8 text-gray-400">
        No hay solicitudes de acceso
      </div>
    );
  }

  return (
    <>
      <div className="rounded-md border border-gray-700">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Plataforma</TableHead>
              <TableHead>Tipo</TableHead>
              <TableHead>Estado</TableHead>
              <TableHead>Fecha</TableHead>
              <TableHead className="w-[50px]">Acciones</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {requests.map((request) => (
              <TableRow key={request.id}>
                <TableCell className="font-medium">
                  {request.plataformas_acceso?.nombre}
                </TableCell>
                <TableCell>
                  {request.tipos_solicitud_acceso?.nombre}
                </TableCell>
                <TableCell>{getStatusBadge(request.estado || 'pendiente')}</TableCell>
                <TableCell>
                  {format(new Date(request.created_at), "PPp", { locale: es })}
                </TableCell>
                <TableCell>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => setSelectedRequest(request)}
                    className="hover:bg-gray-800"
                  >
                    <Pencil className="h-4 w-4" />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      {selectedRequest && (
        <EditAccessRequestDialog
          isOpen={!!selectedRequest}
          onOpenChange={(open) => !open && setSelectedRequest(null)}
          request={selectedRequest}
          onRequestUpdated={refetch}
        />
      )}
    </>
  );
};