import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { UserPlus, Search, Filter } from "lucide-react";
import { Equipment } from "@/types/inventory";
import { EQUIPMENT_TYPES } from "@/constants/inventory";
import { toast } from "sonner";
import { motion } from "framer-motion";
import { Input } from "@/components/ui/input";
import { EquipmentStatusActions } from "./actions/EquipmentStatusActions";
import { getStatusColor } from "@/utils/statusColors";

interface EquipmentTableProps {
  equipment: Equipment[];
  users: { cdsid: string; full_name: string }[];
  onAssignUser: (equipmentId: string, userId: string | null) => void;
}

export const EquipmentTable = ({ equipment, users, onAssignUser }: EquipmentTableProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState<string>("all");

  const handleAssignment = async (equipmentId: string, userId: string | null) => {
    try {
      await onAssignUser(equipmentId, userId);
    } catch (error) {
      console.error('Error en la asignación:', error);
      toast.error("Error al actualizar la asignación");
    }
  };

  const filteredEquipment = equipment.filter(item => {
    const matchesSearch = 
      item.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.serial_number?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.tipo_equipo.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesStatus = statusFilter === "all" || item.estado === statusFilter;
    
    return matchesSearch && matchesStatus;
  });

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="rounded-lg border bg-white/5 backdrop-blur-md border-blue-500/10 shadow-xl"
    >
      <div className="p-4 space-y-4">
        <div className="flex flex-col sm:flex-row gap-4 justify-between items-center">
          <div className="relative w-full sm:w-96">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground h-4 w-4" />
            <Input
              placeholder="Buscar por nombre, serial o tipo..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 bg-white/5 border-blue-500/10 focus:border-blue-500/30 transition-colors"
            />
          </div>
          <div className="flex gap-2 w-full sm:w-auto">
            <Select value={statusFilter} onValueChange={setStatusFilter}>
              <SelectTrigger className="w-[180px] bg-white/5 border-blue-500/10">
                <Filter className="w-4 h-4 mr-2" />
                <SelectValue placeholder="Filtrar por estado" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">Todos los estados</SelectItem>
                <SelectItem value="disponible">Disponible</SelectItem>
                <SelectItem value="asignado">Asignado</SelectItem>
                <SelectItem value="mantenimiento">Mantenimiento</SelectItem>
                <SelectItem value="dañado">Dañado</SelectItem>
                <SelectItem value="reemplazado">Reemplazado</SelectItem>
                <SelectItem value="baja">Baja</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>

      <Table>
        <TableHeader>
          <TableRow className="hover:bg-transparent border-blue-500/10">
            <TableHead className="text-blue-400">Tipo</TableHead>
            <TableHead className="text-blue-400">Nombre</TableHead>
            <TableHead className="text-blue-400">Serial</TableHead>
            <TableHead className="text-blue-400">Estado</TableHead>
            <TableHead className="text-blue-400">Asignado a</TableHead>
            <TableHead className="text-blue-400">Acciones</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {filteredEquipment.map((item) => {
            const Icon = EQUIPMENT_TYPES[item.tipo_equipo]?.icon;

            return (
              <TableRow 
                key={item.id}
                className="border-blue-500/10 transition-colors hover:bg-blue-500/5"
              >
                <TableCell>
                  <div className="flex items-center gap-2">
                    {Icon && <Icon className="h-4 w-4 text-blue-400" />}
                    {EQUIPMENT_TYPES[item.tipo_equipo]?.label}
                  </div>
                </TableCell>
                <TableCell className="font-medium">{item.nombre}</TableCell>
                <TableCell>{item.serial_number}</TableCell>
                <TableCell>
                  <Badge className={getStatusColor(item.estado)}>
                    {item.estado}
                  </Badge>
                </TableCell>
                <TableCell>
                  <Select
                    value={item.asignado_a || "unassigned"}
                    onValueChange={(value) => handleAssignment(item.id, value === "unassigned" ? null : value)}
                  >
                    <SelectTrigger className="w-[200px] bg-white/5 border-blue-500/10">
                      <SelectValue placeholder="Sin asignar" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="unassigned">Sin asignar</SelectItem>
                      {users?.map((user) => (
                        <SelectItem key={user.cdsid} value={user.cdsid}>
                          {user.full_name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </TableCell>
                <TableCell>
                  <div className="flex items-center gap-2">
                    {!item.asignado_a && (
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleAssignment(item.id, null)}
                        className="hover:text-blue-400 text-muted-foreground"
                        title="Asignar usuario"
                      >
                        <UserPlus className="h-4 w-4" />
                      </Button>
                    )}
                    <EquipmentStatusActions
                      equipmentId={item.id}
                      currentStatus={item.estado}
                      onStatusChange={() => {
                        // Refresh will be handled by the parent's query invalidation
                      }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </motion.div>
  );
};