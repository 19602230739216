
import { useTickets, useTicketStats } from "@/hooks/useTickets";
import { useInventoryData } from "@/hooks/useInventoryData";
import { toast } from "sonner";

export const useDashboardData = (isEnabled: boolean = true) => {
  // Use suspense + error boundaries for better loading & error states
  const { 
    data: tickets = [], 
    isLoading: ticketsLoading, 
    error: ticketsError,
    refetch: refetchTickets
  } = useTickets({
    staleTime: 3 * 60 * 1000,    // Cache for 3 minutes
    gcTime: 10 * 60 * 1000,      // Keep in cache for 10 minutes
    enabled: isEnabled,
  });

  const { 
    data: inventory = [], 
    isLoading: inventoryLoading,
    error: inventoryError,
    refetch: refetchInventory
  } = useInventoryData({
    staleTime: 5 * 60 * 1000,
    enabled: isEnabled
  });

  const { 
    data: ticketStats = {}, 
    isLoading: statsLoading,
    error: statsError,
    refetch: refetchStats
  } = useTicketStats({
    staleTime: 3 * 60 * 1000,
    enabled: isEnabled
  });

  // Aggregated loading and error states
  const isLoading = ticketsLoading || inventoryLoading || statsLoading;
  const error = ticketsError || inventoryError || statsError;

  // Combined refetch function
  const refetchAll = () => {
    toast.info("Actualizando datos del dashboard...");
    return Promise.all([
      refetchTickets(),
      refetchInventory(),
      refetchStats()
    ]).then(() => {
      toast.success("Datos actualizados correctamente");
    }).catch((error) => {
      console.error("Error refreshing dashboard data:", error);
      toast.error("Error al actualizar los datos");
    });
  };

  return {
    tickets,
    inventory,
    ticketStats,
    isLoading,
    error,
    refetchAll
  };
};
