import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { format } from "date-fns";
import { Json } from "@/integrations/supabase/types/json";

type SystemLog = {
  id: string;
  action: string;
  details: Json;
  created_at: string;
  user_id: string | null;
};

export const AdminLogs = () => {
  const { data: logs, isLoading } = useQuery<SystemLog[]>({
    queryKey: ["systemLogs"],
    queryFn: async () => {
      console.log("Fetching system logs...");
      const { data, error } = await supabase
        .from("system_logs")
        .select(`
          id,
          action,
          details,
          created_at,
          user_id
        `)
        .order("created_at", { ascending: false });

      if (error) {
        console.error("Error fetching logs:", error);
        throw error;
      }
      
      console.log("Fetched logs:", data);
      return data;
    },
  });

  if (isLoading) {
    return <div>Cargando logs...</div>;
  }

  return (
    <div className="rounded-md border border-gray-700 bg-gray-800/50">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Acción</TableHead>
            <TableHead>Usuario</TableHead>
            <TableHead>Detalles</TableHead>
            <TableHead>Fecha</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {logs?.map((log) => (
            <TableRow key={log.id}>
              <TableCell>{log.action}</TableCell>
              <TableCell>{log.user_id || "Sistema"}</TableCell>
              <TableCell>
                <pre className="text-sm">
                  {JSON.stringify(log.details, null, 2)}
                </pre>
              </TableCell>
              <TableCell>
                {format(new Date(log.created_at), "dd/MM/yyyy HH:mm:ss")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
