import { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuCheckboxItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Filter, SlidersHorizontal } from "lucide-react";

interface AdvancedFiltersProps {
  onFilterChange: (filters: any) => void;
}

export const AdvancedFilters = ({ onFilterChange }: AdvancedFiltersProps) => {
  const [selectedPriorities, setSelectedPriorities] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

  const priorities = [
    { label: "Urgente", value: "urgent" },
    { label: "Alta", value: "high" },
    { label: "Media", value: "medium" },
    { label: "Baja", value: "low" }
  ];

  const categories = [
    { label: "Hardware", value: "hardware" },
    { label: "Software", value: "software" },
    { label: "Red", value: "network" },
    { label: "Accesos", value: "access" },
    { label: "Otros", value: "other" }
  ];

  const statuses = [
    { label: "Abierto", value: "open" },
    { label: "En Progreso", value: "in_progress" },
    { label: "Cerrado", value: "closed" }
  ];

  const handleFilterChange = (type: string, value: string) => {
    let newFilters: string[];
    switch (type) {
      case 'priority':
        newFilters = selectedPriorities.includes(value)
          ? selectedPriorities.filter(p => p !== value)
          : [...selectedPriorities, value];
        setSelectedPriorities(newFilters);
        break;
      case 'category':
        newFilters = selectedCategories.includes(value)
          ? selectedCategories.filter(c => c !== value)
          : [...selectedCategories, value];
        setSelectedCategories(newFilters);
        break;
      case 'status':
        newFilters = selectedStatuses.includes(value)
          ? selectedStatuses.filter(s => s !== value)
          : [...selectedStatuses, value];
        setSelectedStatuses(newFilters);
        break;
    }

    onFilterChange({
      priorities: selectedPriorities,
      categories: selectedCategories,
      statuses: selectedStatuses
    });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="ml-2">
          <SlidersHorizontal className="w-4 h-4 mr-2" />
          Filtros Avanzados
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>Prioridad</DropdownMenuLabel>
        {priorities.map(priority => (
          <DropdownMenuCheckboxItem
            key={priority.value}
            checked={selectedPriorities.includes(priority.value)}
            onCheckedChange={() => handleFilterChange('priority', priority.value)}
          >
            {priority.label}
          </DropdownMenuCheckboxItem>
        ))}

        <DropdownMenuSeparator />

        <DropdownMenuLabel>Categoría</DropdownMenuLabel>
        {categories.map(category => (
          <DropdownMenuCheckboxItem
            key={category.value}
            checked={selectedCategories.includes(category.value)}
            onCheckedChange={() => handleFilterChange('category', category.value)}
          >
            {category.label}
          </DropdownMenuCheckboxItem>
        ))}

        <DropdownMenuSeparator />

        <DropdownMenuLabel>Estado</DropdownMenuLabel>
        {statuses.map(status => (
          <DropdownMenuCheckboxItem
            key={status.value}
            checked={selectedStatuses.includes(status.value)}
            onCheckedChange={() => handleFilterChange('status', status.value)}
          >
            {status.label}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};