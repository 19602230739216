import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSession } from "@supabase/auth-helpers-react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { motion } from "framer-motion";
import { InventoryHeader } from "@/components/inventory/InventoryHeader";
import { InventoryStats } from "@/components/inventory/InventoryStats";
import { EquipmentTable } from "@/components/inventory/EquipmentTable";
import { UserAssignmentSection } from "@/components/inventory/assignments/UserAssignmentSection";

const Inventory = () => {
  const session = useSession();
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        console.log('No session found, redirecting to login');
        navigate("/login");
        return;
      }
      console.log('Session found:', session.user.id);
    };

    checkAuth();
  }, [navigate]);

  const { data: users = [], isLoading: usersLoading } = useQuery({
    queryKey: ["active-users"],
    queryFn: async () => {
      console.log('Fetching active users...');
      const { data, error } = await supabase
        .from("empleados")
        .select("cdsid, nombre, apellidos")
        .eq('estado', 'activo');

      if (error) {
        console.error('Error fetching users:', error);
        throw error;
      }

      return data.map(user => ({
        cdsid: user.cdsid,
        full_name: `${user.nombre} ${user.apellidos}`.trim()
      }));
    },
    enabled: !!session
  });

  const { data: inventory = [], isLoading: inventoryLoading, error, refetch } = useQuery({
    queryKey: ["inventory"],
    queryFn: async () => {
      console.log('Fetching inventory data...');
      const { data, error } = await supabase
        .from("piezas")
        .select("*");

      if (error) {
        console.error('Error fetching inventory:', error);
        toast.error("Error al cargar el inventario");
        throw error;
      }

      return data;
    },
    enabled: !!session
  });

  const handleAssignUser = async (equipmentId: string, userId: string | null) => {
    try {
      console.log('Assigning equipment:', equipmentId, 'to user:', userId);
      
      const { error } = await supabase
        .from("piezas")
        .update({
          asignado_a: userId,
          estado: userId ? 'asignado' : 'disponible',
          fecha_asignacion: userId ? new Date().toISOString() : null
        })
        .eq("id", equipmentId);

      if (error) throw error;

      await refetch();
      toast.success(userId ? "Equipo asignado correctamente" : "Asignación removida correctamente");
    } catch (error) {
      console.error('Error updating equipment:', error);
      toast.error("Error al actualizar el equipo");
    }
  };

  if (error) {
    console.error('Query error:', error);
    toast.error("Error al cargar el inventario");
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-blue-900/20 to-gray-900">
      <div className="container mx-auto p-8 pt-24 space-y-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="space-y-8"
        >
          <InventoryHeader />
          
          {(inventoryLoading || usersLoading) ? (
            <div className="flex items-center justify-center h-64">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
            </div>
          ) : (
            <div className="space-y-8">
              <InventoryStats inventory={inventory} />
              
              <div className="grid lg:grid-cols-2 gap-8">
                <motion.div 
                  className="space-y-6"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3, delay: 0.2 }}
                >
                  <EquipmentTable 
                    equipment={inventory}
                    users={users}
                    onAssignUser={handleAssignUser}
                  />
                </motion.div>
                
                <motion.div
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3, delay: 0.3 }}
                >
                  <UserAssignmentSection 
                    inventory={inventory}
                    users={users}
                  />
                </motion.div>
              </div>
            </div>
          )}
        </motion.div>
      </div>
    </div>
  );
};

export default Inventory;