import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuCheckboxItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Filter } from "lucide-react";

interface PriorityFilterProps {
  selectedPriorities: string[];
  onPriorityChange: (priorities: string[]) => void;
}

export const PriorityFilter = ({ selectedPriorities, onPriorityChange }: PriorityFilterProps) => {
  const priorities = [
    { label: "Urgente", value: "urgent", color: "bg-red-500" },
    { label: "Alta", value: "high", color: "bg-orange-500" },
    { label: "Media", value: "medium", color: "bg-yellow-500" },
    { label: "Baja", value: "low", color: "bg-green-500" }
  ];

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="gap-2">
          <Filter className="w-4 h-4" />
          Prioridad {selectedPriorities.length > 0 && `(${selectedPriorities.length})`}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="bg-gray-800 border-gray-700">
        {priorities.map((priority) => (
          <DropdownMenuCheckboxItem
            key={priority.value}
            checked={selectedPriorities.includes(priority.value)}
            onCheckedChange={(checked) => {
              const newPriorities = checked
                ? [...selectedPriorities, priority.value]
                : selectedPriorities.filter(p => p !== priority.value);
              onPriorityChange(newPriorities);
            }}
            className="gap-2"
          >
            <div className={`w-2 h-2 rounded-full ${priority.color}`} />
            {priority.label}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};