
import { useState, useEffect, createContext, useContext, ReactNode } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { User, Session } from '@supabase/supabase-js';
import { toast } from "sonner";

interface UserWithRole extends User {
  role?: string;
}

interface AuthContextType {
  user: UserWithRole | null;
  session: Session | null;
  isAdmin: boolean;
  isLoading: boolean;
  logout: () => Promise<void>;
  refreshUserRole: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<UserWithRole | null>(null);
  const [session, setSession] = useState<Session | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    // Set up auth state listener FIRST
    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, newSession) => {
      console.log('Auth state changed:', event);
      
      // Handle auth events synchronously
      setSession(newSession);
      
      if (newSession?.user) {
        // Basic user information is set immediately
        setUser(newSession.user as UserWithRole);
        
        // Fetch the role asynchronously using setTimeout to prevent deadlocks
        setTimeout(() => {
          fetchUserRole(newSession.user as UserWithRole);
        }, 0);
      } else {
        setUser(null);
        setIsAdmin(false);
      }
    });

    // THEN check for existing session
    const initializeAuth = async () => {
      try {
        const { data: { session: initialSession }, error } = await supabase.auth.getSession();
        
        if (error) {
          console.error('Error getting session:', error);
          setIsLoading(false);
          return;
        }
        
        if (initialSession?.user) {
          setSession(initialSession);
          setUser(initialSession.user as UserWithRole);
          fetchUserRole(initialSession.user as UserWithRole);
        }
      } catch (error) {
        console.error('Error initializing auth:', error);
      } finally {
        setIsLoading(false);
      }
    };

    initializeAuth();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const fetchUserRole = async (authUser: UserWithRole) => {
    try {
      console.log('Fetching role for user:', authUser.id);
      
      const { data, error } = await supabase
        .from('users')
        .select('role')
        .eq('id', authUser.id)
        .single();

      if (error) {
        console.error('Error fetching user role:', error);
        return;
      }
      
      if (data) {
        // Update user with role information
        const userWithRole = { ...authUser, role: data.role };
        setUser(userWithRole);
        setIsAdmin(data.role === 'admin' || data.role === 'superadmin');
        console.log('User role updated:', data.role, 'isAdmin:', data.role === 'admin' || data.role === 'superadmin');
      }
    } catch (error) {
      console.error('Error in fetchUserRole:', error);
    }
  };

  const refreshUserRole = async () => {
    if (user) {
      await fetchUserRole(user);
    }
  };

  const logout = async () => {
    try {
      setIsLoading(true);
      await supabase.auth.signOut();
      setUser(null);
      setSession(null);
      setIsAdmin(false);
      toast.success("Sesión cerrada correctamente");
    } catch (error) {
      console.error('Error logging out:', error);
      toast.error("Error al cerrar sesión");
    } finally {
      setIsLoading(false);
    }
  };

  const value = {
    user,
    session,
    isAdmin,
    isLoading,
    logout,
    refreshUserRole
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
