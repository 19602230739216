import { useState } from "react";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { Loader2, Sparkles } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

interface DescriptionFieldProps {
  title: string;
  description: string;
  setDescription: (value: string) => void;
  label?: string;
  placeholder?: string;
}

export const DescriptionField = ({
  title,
  description,
  setDescription,
  label = "Descripción",
  placeholder = "Proporciona más detalles sobre el problema"
}: DescriptionFieldProps) => {
  const [isEnhancing, setIsEnhancing] = useState(false);

  const enhanceDescription = async () => {
    if (!description.trim()) {
      toast.error("Por favor, añade una descripción primero");
      return;
    }

    setIsEnhancing(true);
    try {
      console.log('Solicitando mejora de descripción...');
      const { data, error } = await supabase.functions.invoke('enhance-ticket-description', {
        body: { title, description }
      });

      if (error) throw error;
      console.log('Mejora recibida:', data);

      setDescription(data.enhancedDescription);
      toast.success("Descripción mejorada con IA");
    } catch (error) {
      console.error('Error al mejorar la descripción:', error);
      toast.error("No se pudo mejorar la descripción");
    } finally {
      setIsEnhancing(false);
    }
  };

  return (
    <div className="space-y-2">
      <div className="flex justify-between items-center">
        <label className="text-sm font-medium">{label}</label>
        <Button
          type="button"
          variant="outline"
          size="sm"
          onClick={enhanceDescription}
          disabled={isEnhancing || !description.trim()}
          className="gap-2"
        >
          {isEnhancing ? (
            <Loader2 className="h-4 w-4 animate-spin" />
          ) : (
            <Sparkles className="h-4 w-4" />
          )}
          Mejorar con IA
        </Button>
      </div>
      <Textarea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder={placeholder}
        required
        rows={4}
        className="min-h-[100px]"
      />
    </div>
  );
};