import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { Wand2 } from "lucide-react";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";

interface ConfigurationData {
  companyName: string;
  modules: {
    employees: boolean;
    inventory: boolean;
    tickets: boolean;
    calendar: boolean;
  };
  adminEmail: string;
  customDomain?: string;
}

interface ConfigurationWizardProps {
  selectedPlan?: string | null;
}

export const ConfigurationWizard = ({ selectedPlan }: ConfigurationWizardProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [config, setConfig] = useState<ConfigurationData>({
    companyName: "",
    modules: {
      employees: true,
      inventory: selectedPlan === "Profesional" || selectedPlan === "Enterprise",
      tickets: true,
      calendar: selectedPlan === "Profesional" || selectedPlan === "Enterprise",
    },
    adminEmail: "",
  });

  const handleGeneratePortal = async () => {
    try {
      setIsLoading(true);
      
      // Validaciones básicas
      if (!config.companyName.trim()) {
        toast.error("El nombre de la empresa es obligatorio");
        return;
      }

      if (!config.adminEmail.trim()) {
        toast.error("El email del administrador es obligatorio");
        return;
      }

      toast.info("Iniciando generación del portal...");

      // 1. Crear empresa
      const { data: company, error: companyError } = await supabase
        .from("companies")
        .insert({
          name: config.companyName,
          active: true,
        })
        .select()
        .single();

      if (companyError) throw companyError;

      toast.info("Empresa creada correctamente");

      // 2. Crear configuración del portal
      const { error: configError } = await supabase
        .from("portal_config")
        .insert({
          company_id: company.id,
          name: config.companyName,
          modules: config.modules,
        });

      if (configError) throw configError;

      toast.info("Configuración base creada");

      // 3. Crear tema por defecto
      const { error: themeError } = await supabase
        .from("portal_themes")
        .insert({
          company_id: company.id,
          name: "Tema Principal",
          description: "Tema por defecto para " + config.companyName,
          is_active: true,
        });

      if (themeError) throw themeError;

      // 4. Crear administrador
      const { error: adminError } = await supabase
        .from("portal_admins")
        .insert({
          username: config.adminEmail,
          email: config.adminEmail,
        });

      if (adminError) throw adminError;

      toast.success("¡Portal generado correctamente!", {
        description: "Se ha creado el portal con todas sus configuraciones",
      });

      // Resetear el formulario
      setConfig({
        companyName: "",
        modules: {
          employees: true,
          inventory: true,
          tickets: true,
          calendar: true,
        },
        adminEmail: "",
      });

    } catch (error) {
      console.error("Error generating portal:", error);
      toast.error("Error al generar el portal", {
        description: "Por favor, inténtalo de nuevo",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <Wand2 className="h-5 w-5" />
          Asistente de Configuración
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className="space-y-4">
          <div className="space-y-2">
            <Label>Nombre de la Empresa</Label>
            <Input
              value={config.companyName}
              onChange={(e) =>
                setConfig({ ...config, companyName: e.target.value })
              }
              placeholder="Mi Empresa"
            />
          </div>

          <div className="space-y-2">
            <Label>Email del Administrador</Label>
            <Input
              type="email"
              value={config.adminEmail}
              onChange={(e) =>
                setConfig({ ...config, adminEmail: e.target.value })
              }
              placeholder="admin@empresa.com"
            />
          </div>

          <div className="space-y-2">
            <Label>Dominio Personalizado (Opcional)</Label>
            <Input
              value={config.customDomain}
              onChange={(e) =>
                setConfig({ ...config, customDomain: e.target.value })
              }
              placeholder="portal.miempresa.com"
            />
          </div>
        </div>

        <div className="space-y-4">
          <Label>Módulos a Activar</Label>
          <div className="grid gap-4 md:grid-cols-2">
            <div className="flex items-center justify-between">
              <Label>Empleados</Label>
              <Switch
                checked={config.modules.employees}
                onCheckedChange={(checked) =>
                  setConfig({
                    ...config,
                    modules: { ...config.modules, employees: checked },
                  })
                }
              />
            </div>

            <div className="flex items-center justify-between">
              <Label>Inventario</Label>
              <Switch
                checked={config.modules.inventory}
                disabled={selectedPlan === "Básico"}
                onCheckedChange={(checked) =>
                  setConfig({
                    ...config,
                    modules: { ...config.modules, inventory: checked },
                  })
                }
              />
            </div>

            <div className="flex items-center justify-between">
              <Label>Tickets</Label>
              <Switch
                checked={config.modules.tickets}
                onCheckedChange={(checked) =>
                  setConfig({
                    ...config,
                    modules: { ...config.modules, tickets: checked },
                  })
                }
              />
            </div>

            <div className="flex items-center justify-between">
              <Label>Calendario</Label>
              <Switch
                checked={config.modules.calendar}
                disabled={selectedPlan === "Básico"}
                onCheckedChange={(checked) =>
                  setConfig({
                    ...config,
                    modules: { ...config.modules, calendar: checked },
                  })
                }
              />
            </div>
          </div>
        </div>

        <Button 
          onClick={handleGeneratePortal} 
          className="w-full"
          disabled={isLoading}
        >
          <Wand2 className="w-4 h-4 mr-2" />
          {isLoading ? "Generando Portal..." : "Generar Portal"}
        </Button>
      </CardContent>
    </Card>
  );
};