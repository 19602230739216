import { format } from "date-fns";
import { es } from "date-fns/locale";
import { motion } from "framer-motion";
import { 
  Activity, 
  AlertCircle, 
  CheckCircle2, 
  Clock, 
  Box,
  UserPlus,
  UserMinus,
  Truck,
  Wrench,
  AlertOctagon
} from "lucide-react";
import { ActivityItem as ActivityItemType } from "@/types/activity";
import { Badge } from "@/components/ui/badge";

interface ActivityItemProps {
  activity: ActivityItemType;
  index: number;
}

export const ActivityItem = ({ activity, index }: ActivityItemProps) => {
  const getActivityIcon = (action_type: string) => {
    switch (action_type) {
      case "ticket_created":
        return <Activity className="w-4 h-4 text-blue-400" />;
      case "ticket_completed":
        return <CheckCircle2 className="w-4 h-4 text-green-400" />;
      case "ticket_updated":
        return <Clock className="w-4 h-4 text-yellow-400" />;
      case "employee_created":
        return <UserPlus className="w-4 h-4 text-purple-400" />;
      case "employee_updated":
        return <UserMinus className="w-4 h-4 text-orange-400" />;
      case "collection_created":
        return <Box className="w-4 h-4 text-indigo-400" />;
      case "collection_updated":
        return <Truck className="w-4 h-4 text-pink-400" />;
      case "inventory_updated":
        return <Wrench className="w-4 h-4 text-cyan-400" />;
      default:
        return <AlertCircle className="w-4 h-4 text-gray-400" />;
    }
  };

  const getActivityBadge = (action_type: string) => {
    if (action_type === "ticket_created") {
      return (
        <Badge variant="outline" className="bg-red-500/10 text-red-500 border-red-500/20">
          <AlertOctagon className="w-3 h-3 mr-1" />
          Nuevo Ticket
        </Badge>
      );
    }
    return null;
  };

  return (
    <motion.div
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.3, delay: index * 0.1 }}
      className="flex items-start gap-3 p-3 rounded-lg bg-gray-700/30 hover:bg-gray-700/50 transition-all duration-200"
    >
      <div className="p-2 rounded-full bg-gray-800/50">
        {getActivityIcon(activity.action_type)}
      </div>
      <div className="flex-1">
        <div className="flex items-center justify-between">
          <p className="text-sm text-gray-200">{activity.description}</p>
          {getActivityBadge(activity.action_type)}
        </div>
        <p className="text-xs text-gray-400 mt-1">
          {format(new Date(activity.created_at), "d 'de' MMMM 'a las' HH:mm", {
            locale: es,
          })}
        </p>
      </div>
    </motion.div>
  );
};