import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { MessageSquare, Send } from "lucide-react";
import { formatDistanceToNow } from "date-fns";
import { es } from "date-fns/locale";

interface Comment {
  id: string;
  content: string;
  created_at: string;
  user: {
    full_name: string;
  };
}

interface TicketCommentsProps {
  comments: Comment[];
  onAddComment: (content: string) => Promise<void>;
  isSubmitting: boolean;
}

export const TicketComments = ({ comments, onAddComment, isSubmitting }: TicketCommentsProps) => {
  const [newComment, setNewComment] = useState("");

  const handleSubmit = async () => {
    if (!newComment.trim() || isSubmitting) return;
    await onAddComment(newComment.trim());
    setNewComment("");
  };

  return (
    <div className="border-t border-gray-700 pt-4 mt-6">
      <h4 className="text-lg font-semibold mb-4 flex items-center gap-2">
        <MessageSquare className="h-5 w-5" />
        Comentarios
      </h4>
      
      <div className="space-y-4 max-h-[300px] overflow-y-auto">
        {comments.map((comment) => (
          <div
            key={comment.id}
            className="bg-gray-700/50 p-4 rounded-lg space-y-2 animate-in slide-in-from-bottom-2"
          >
            <div className="flex justify-between text-sm text-gray-300">
              <span>{comment.user?.full_name}</span>
              <span>
                {formatDistanceToNow(new Date(comment.created_at), {
                  addSuffix: true,
                  locale: es,
                })}
              </span>
            </div>
            <p className="text-gray-100">{comment.content}</p>
          </div>
        ))}
      </div>

      <div className="flex gap-2 mt-4">
        <Textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Escribe un comentario..."
          className="bg-gray-700/50 border-gray-600"
        />
        <Button
          onClick={handleSubmit}
          disabled={isSubmitting || !newComment.trim()}
          className="bg-blue-500 hover:bg-blue-600"
        >
          <Send className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};