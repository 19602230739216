import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Empleado } from "@/integrations/supabase/types/tables";
import { EmpleadoStatusBadge } from "./EmpleadoStatusBadge";
import { motion } from "framer-motion";

interface EmpleadosListProps {
  empleados: Empleado[];
}

export const EmpleadosList = ({ empleados }: EmpleadosListProps) => {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      className="rounded-md border border-gray-700 bg-gray-800/50 overflow-hidden"
    >
      <Table>
        <TableHeader>
          <TableRow className="border-gray-700 hover:bg-gray-800/50">
            <TableHead className="text-gray-400">CDSID</TableHead>
            <TableHead className="text-gray-400">Nombre</TableHead>
            <TableHead className="text-gray-400">Apellidos</TableHead>
            <TableHead className="text-gray-400">Email</TableHead>
            <TableHead className="text-gray-400">Mercado</TableHead>
            <TableHead className="text-gray-400">Posición</TableHead>
            <TableHead className="text-gray-400">Estado</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {empleados.map((empleado, index) => (
            <motion.tr
              key={empleado.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.05 }}
              className="border-gray-700 hover:bg-gray-800/50"
            >
              <TableCell className="font-medium text-gray-300">{empleado.cdsid}</TableCell>
              <TableCell className="text-gray-300">{empleado.nombre}</TableCell>
              <TableCell className="text-gray-300">{empleado.apellidos}</TableCell>
              <TableCell className="text-gray-300">{empleado.email || empleado.msxi_email}</TableCell>
              <TableCell className="text-gray-300">{empleado.mercado}</TableCell>
              <TableCell className="text-gray-300">{empleado.posicion || '-'}</TableCell>
              <TableCell>
                <EmpleadoStatusBadge status={empleado.estado || 'activo'} />
              </TableCell>
            </motion.tr>
          ))}
        </TableBody>
      </Table>
    </motion.div>
  );
};