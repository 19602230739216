import { motion } from "framer-motion";
import { Card } from "@/components/ui/card";
import { quickAccessCards } from "@/constants/dashboard";
import { useNavigate } from "react-router-dom";
import { Badge } from "@/components/ui/badge";
import { AlertOctagon } from "lucide-react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";

export const QuickAccessGrid = () => {
  const navigate = useNavigate();

  const { data: openTickets = 0 } = useQuery({
    queryKey: ['open-tickets-count'],
    queryFn: async () => {
      const { count, error } = await supabase
        .from('tickets')
        .select('*', { count: 'exact', head: true })
        .eq('status', 'open');

      if (error) throw error;
      return count || 0;
    },
    staleTime: 30000, // 30 seconds
  });

  const getCardBadge = (link: string) => {
    if (link === '/tickets' && openTickets > 0) {
      return (
        <Badge 
          variant="outline" 
          className="absolute top-2 right-2 bg-red-500/10 text-red-500 border-red-500/20"
        >
          <AlertOctagon className="w-3 h-3 mr-1" />
          {openTickets} {openTickets === 1 ? 'ticket pendiente' : 'tickets pendientes'}
        </Badge>
      );
    }
    return null;
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {quickAccessCards.map((card, index) => (
        <motion.div
          key={card.title}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: index * 0.1 }}
        >
          <Card 
            className="dashboard-card group cursor-pointer relative" 
            onClick={() => navigate(card.link)}
          >
            {getCardBadge(card.link)}
            <div className="flex items-start justify-between">
              <div className="space-y-2">
                <div className={`p-2 rounded-lg inline-block ${card.color}`}>
                  <card.icon className="w-5 h-5" />
                </div>
                <h3 className="text-lg font-semibold text-white group-hover:text-blue-400 transition-colors">
                  {card.title}
                </h3>
                <p className="text-sm text-gray-400">
                  {card.description}
                </p>
              </div>
            </div>
          </Card>
        </motion.div>
      ))}
    </div>
  );
};