import { useQuery } from "@tanstack/react-query";
import { Card } from "@/components/ui/card";
import { supabase } from "@/integrations/supabase/client";
import { subDays } from "date-fns";
import { Activity, Loader2 } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { toast } from "sonner";
import { ActivityItem as ActivityItemType } from "@/types/activity";
import { ActivityItem } from "./ActivityItem";

export const RecentActivity = () => {
  const channelRef = useRef<ReturnType<typeof supabase.channel> | null>(null);
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const { data: activities = [], refetch } = useQuery<ActivityItemType[]>({
    queryKey: ["recent-activities"],
    queryFn: async () => {
      console.log('Fetching recent activities...');
      const sevenDaysAgo = subDays(new Date(), 7).toISOString();
      
      const { data, error } = await supabase
        .from("activity_log")
        .select("*")
        .gte("created_at", sevenDaysAgo)
        .order("created_at", { ascending: false })
        .limit(20);

      if (error) {
        console.error('Error fetching activities:', error);
        throw error;
      }

      console.log('Activities fetched:', data?.length);
      return data || [];
    },
    staleTime: 30000, // 30 seconds
    refetchInterval: 60000, // Refetch every minute
  });

  useEffect(() => {
    console.log('Setting up real-time subscriptions...');
    
    if (!channelRef.current) {
      channelRef.current = supabase
        .channel('activity_changes')
        .on(
          'postgres_changes',
          { event: '*', schema: 'public', table: 'activity_log' },
          (payload) => {
            console.log('Activity change detected:', payload);
            const event = payload.eventType;
            const newData = payload.new as any;
            
            if (event === 'INSERT') {
              toast.info('Nueva actividad', {
                description: newData.description,
              });
              setShouldRefetch(true);
            }
          }
        )
        .subscribe((status) => {
          console.log('Subscription status:', status);
        });
    }

    return () => {
      console.log('Cleaning up subscriptions...');
      if (channelRef.current) {
        supabase.removeChannel(channelRef.current);
        channelRef.current = null;
      }
    };
  }, []); 

  useEffect(() => {
    if (shouldRefetch) {
      const timeoutId = setTimeout(() => {
        refetch();
        setShouldRefetch(false);
      }, 300);

      return () => clearTimeout(timeoutId);
    }
  }, [shouldRefetch, refetch]);

  if (!activities) {
    return (
      <Card className="p-6 bg-gray-800/50 backdrop-blur-lg border border-gray-700/50 shadow-xl">
        <div className="flex items-center justify-center h-48">
          <Loader2 className="w-8 h-8 text-blue-400 animate-spin" />
        </div>
      </Card>
    );
  }

  return (
    <Card className="p-6 bg-gray-800/50 backdrop-blur-lg border border-gray-700/50 shadow-xl">
      <h2 className="text-xl font-bold text-blue-400 mb-4 flex items-center gap-2">
        <Activity className="w-5 h-5" />
        Actividad Reciente
      </h2>
      <div className="space-y-3 max-h-[500px] overflow-y-auto">
        {activities.map((activity, index) => (
          <ActivityItem 
            key={activity.id} 
            activity={activity} 
            index={index}
          />
        ))}

        {activities.length === 0 && (
          <div className="text-center py-8 text-gray-400">
            <Activity className="w-12 h-12 mx-auto mb-3 opacity-50" />
            <p>No hay actividad reciente</p>
          </div>
        )}
      </div>
    </Card>
  );
};