import { Card } from "@/components/ui/card";
import { PendingIssuesForm } from "@/components/pending-issues/PendingIssuesForm";
import { Shield } from "lucide-react";
import { motion } from "framer-motion";

const PendingIssues = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 flex items-center justify-center p-4">
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -inset-[10px] opacity-30">
          <div className="absolute top-0 -left-4 w-96 h-96 bg-blue-500/30 rounded-full mix-blend-multiply filter blur-3xl animate-blob"></div>
          <div className="absolute top-0 -right-4 w-96 h-96 bg-purple-500/30 rounded-full mix-blend-multiply filter blur-3xl animate-blob animation-delay-2000"></div>
          <div className="absolute -bottom-8 left-20 w-96 h-96 bg-indigo-500/30 rounded-full mix-blend-multiply filter blur-3xl animate-blob animation-delay-4000"></div>
        </div>
      </div>

      <div className="container max-w-4xl mx-auto relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center space-y-4 mb-8"
        >
          <div className="flex items-center justify-center space-x-3 mb-6">
            <Shield className="w-12 h-12 text-blue-400" />
            <h1 className="text-4xl font-bold tracking-tighter text-white sm:text-5xl">
              Asuntos Pendientes
            </h1>
          </div>
          <p className="text-gray-400 md:text-lg max-w-[600px] mx-auto leading-relaxed">
            Reporta cualquier problema pendiente relacionado con accesos, sistemas o cualquier otra incidencia que requiera atención.
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="w-full"
        >
          <Card className="backdrop-blur-lg bg-white/5 border-white/20 shadow-2xl p-6">
            <PendingIssuesForm />
          </Card>
        </motion.div>
      </div>
    </div>
  );
};

export default PendingIssues;