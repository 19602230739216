import { Badge } from "@/components/ui/badge";
import { AlertCircle, AlertTriangle, Clock, CheckCircle } from "lucide-react";

interface PriorityBadgeProps {
  priority: string;
  size?: 'sm' | 'default' | 'lg';
}

export const PriorityBadge = ({ priority, size = 'default' }: PriorityBadgeProps) => {
  const getPriorityConfig = (priority: string) => {
    switch (priority) {
      case 'urgent':
        return {
          icon: AlertCircle,
          label: 'Urgente',
          class: 'bg-red-500/10 text-red-500 border-red-500/20 animate-pulse'
        };
      case 'high':
        return {
          icon: AlertTriangle,
          label: 'Alta',
          class: 'bg-orange-500/10 text-orange-500 border-orange-500/20'
        };
      case 'medium':
        return {
          icon: Clock,
          label: 'Media',
          class: 'bg-yellow-500/10 text-yellow-500 border-yellow-500/20'
        };
      case 'low':
        return {
          icon: CheckCircle,
          label: 'Baja',
          class: 'bg-green-500/10 text-green-500 border-green-500/20'
        };
      default:
        return {
          icon: Clock,
          label: priority,
          class: 'bg-gray-500/10 text-gray-500 border-gray-500/20'
        };
    }
  };

  const config = getPriorityConfig(priority);
  const Icon = config.icon;

  return (
    <Badge 
      variant="outline" 
      className={`
        ${config.class}
        ${size === 'sm' ? 'text-xs px-2 py-0' : ''}
        ${size === 'lg' ? 'text-base px-4 py-1' : ''}
        flex items-center gap-1
      `}
    >
      <Icon className={`
        ${size === 'sm' ? 'w-3 h-3' : ''}
        ${size === 'lg' ? 'w-5 h-5' : ''}
        ${size === 'default' ? 'w-4 h-4' : ''}
      `} />
      {config.label}
    </Badge>
  );
};