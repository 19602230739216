import { jsPDF } from "jspdf";

export const applyHeaderStyle = (doc: jsPDF) => {
  doc.setFillColor(41, 128, 185);
  doc.rect(0, 0, doc.internal.pageSize.width, 40, 'F');
  doc.setFontSize(24);
  doc.setTextColor(255, 255, 255);
};

export const applyBodyStyle = (doc: jsPDF) => {
  doc.setFontSize(10);
  doc.setTextColor(60, 60, 60);
};

export const applyTitleStyle = (doc: jsPDF) => {
  doc.setFontSize(16);
  doc.setTextColor(44, 62, 80);
};