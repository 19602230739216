
import { Shield } from "lucide-react";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { useNavigate } from "react-router-dom";
import { Empleado } from "@/integrations/supabase/types/tables";

interface AccessActionsProps {
  empleado: Empleado;
}

export const AccessActions = ({ empleado }: AccessActionsProps) => {
  const navigate = useNavigate();

  const handleAccessRequest = () => {
    navigate("/access-requests", {
      state: { 
        prefilledData: {
          empleadoId: empleado.id,
          cdsid: empleado.cdsid,
          nombre: empleado.nombre,
          apellidos: empleado.apellidos,
          mercado: empleado.mercado
        }
      }
    });
  };

  return (
    <DropdownMenuItem onClick={handleAccessRequest}>
      <Shield className="h-4 w-4 mr-2" />
      Solicitar Acceso
    </DropdownMenuItem>
  );
};
