import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import type { Empleado } from "@/integrations/supabase/types/tables";
import { toast } from "sonner";

export const useEmpleadoInfo = (cdsid: string | null | undefined) => {
  return useQuery({
    queryKey: ['empleado', cdsid],
    queryFn: async () => {
      if (!cdsid) return null;
      
      console.log('Fetching empleado info for:', cdsid);
      const { data, error } = await supabase
        .from('empleados')
        .select('*')
        .eq('cdsid', cdsid.trim())
        .maybeSingle(); // Use maybeSingle instead of single to avoid 406 error when no employee is found

      if (error) {
        console.error('Error fetching empleado:', error);
        toast.error("Error al cargar información del empleado");
        throw error;
      }

      console.log('Empleado data:', data);
      return data as Empleado | null;
    },
    enabled: Boolean(cdsid),
    gcTime: 10 * 60 * 1000, // Keep in cache for 10 minutes
    staleTime: 5 * 60 * 1000, // Consider data stale after 5 minutes
  });
};