
import { useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Brain } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { Empleado } from "@/integrations/supabase/types/tables";
import { TicketsList } from "./TicketsList";
import { TicketAnalysis } from "./TicketAnalysis";

interface TicketsDialogProps {
  empleado: Empleado;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const TicketsDialog = ({ 
  empleado, 
  open, 
  onOpenChange 
}: TicketsDialogProps) => {
  const [tickets, setTickets] = useState<any[]>([]);
  const [ticketAnalysis, setTicketAnalysis] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchTickets = async () => {
    if (open && tickets.length === 0) {
      setIsLoading(true);
      try {
        const { data, error } = await supabase
          .from('tickets')
          .select('*')
          .eq('submitter_cdsid', empleado.cdsid)
          .order('created_at', { ascending: false });

        if (error) throw error;
        setTickets(data || []);
      } catch (error) {
        console.error('Error fetching tickets:', error);
        toast.error("Error al cargar los tickets");
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Fetch tickets when dialog opens
  if (open && tickets.length === 0 && !isLoading) {
    fetchTickets();
  }

  const analyzeTickets = async () => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase.functions.invoke('ticket-ai', {
        body: {
          type: 'analyze_tickets_batch',
          data: { tickets }
        }
      });

      if (error) throw error;
      setTicketAnalysis(data.analysis);
      toast.success("Análisis completado");
    } catch (error) {
      console.error('Error analyzing tickets:', error);
      toast.error("Error al analizar los tickets");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-3xl max-h-[80vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Tickets de {empleado.nombre} {empleado.apellidos}</DialogTitle>
        </DialogHeader>
        
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <p className="text-sm text-gray-500">
              Total tickets: {tickets.length}
            </p>
            <Button 
              onClick={analyzeTickets} 
              disabled={isLoading || tickets.length === 0}
              size="sm"
            >
              <Brain className="h-4 w-4 mr-2" />
              Analizar con IA
            </Button>
          </div>

          {ticketAnalysis && (
            <TicketAnalysis analysis={ticketAnalysis} />
          )}

          <TicketsList 
            tickets={tickets} 
            isLoading={isLoading} 
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
