import { Card } from "@/components/ui/card";
import { Empleado } from "@/integrations/supabase/types/tables";
import { Users, Building2, MapPin } from "lucide-react";

interface EmpleadoStatsProps {
  empleados: Empleado[];
}

export const EmpleadoStats = ({ empleados }: EmpleadoStatsProps) => {
  const stats = {
    total: empleados.length,
    byMarket: empleados.reduce((acc, emp) => {
      acc[emp.mercado] = (acc[emp.mercado] || 0) + 1;
      return acc;
    }, {} as Record<string, number>),
    active: empleados.filter(emp => emp.estado === 'activo').length
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
      <Card className="p-4 bg-gray-800 border-gray-700">
        <div className="flex items-center gap-4">
          <div className="p-3 bg-blue-500/10 rounded-lg">
            <Users className="h-6 w-6 text-blue-500" />
          </div>
          <div>
            <p className="text-sm text-gray-400">Total Empleados</p>
            <h3 className="text-2xl font-bold text-white">{stats.total}</h3>
          </div>
        </div>
      </Card>

      <Card className="p-4 bg-gray-800 border-gray-700">
        <div className="flex items-center gap-4">
          <div className="p-3 bg-green-500/10 rounded-lg">
            <Building2 className="h-6 w-6 text-green-500" />
          </div>
          <div>
            <p className="text-sm text-gray-400">Empleados Activos</p>
            <h3 className="text-2xl font-bold text-white">{stats.active}</h3>
          </div>
        </div>
      </Card>

      <Card className="p-4 bg-gray-800 border-gray-700">
        <div className="flex items-center gap-4">
          <div className="p-3 bg-purple-500/10 rounded-lg">
            <MapPin className="h-6 w-6 text-purple-500" />
          </div>
          <div>
            <p className="text-sm text-gray-400">Mercados</p>
            <h3 className="text-2xl font-bold text-white">
              {Object.keys(stats.byMarket).length}
            </h3>
          </div>
        </div>
      </Card>
    </div>
  );
};