import {
  Users,
  FileText,
  Laptop,
  Calendar,
  Bell,
  BarChart,
  Settings,
  Shield,
  Activity
} from "lucide-react";

export const quickAccessCards = [
  {
    title: "Gestión de Empleados",
    description: "Administra altas, bajas y modificaciones de empleados",
    icon: Users,
    color: "bg-blue-500/20 text-blue-400",
    link: "/empleados"
  },
  {
    title: "Solicitudes de Acceso",
    description: "Gestiona tus solicitudes de acceso a plataformas",
    icon: Shield,
    color: "bg-purple-500/20 text-purple-400",
    link: "/access-requests"
  },
  {
    title: "Seguimiento de Accesos",
    description: "Monitoriza el estado de los accesos solicitados",
    icon: Activity,
    color: "bg-emerald-500/20 text-emerald-400",
    link: "/access-tracking"
  },
  {
    title: "Tickets de Soporte",
    description: "Gestiona tickets de soporte técnico",
    icon: FileText,
    color: "bg-green-500/20 text-green-400",
    link: "/tickets"
  },
  {
    title: "Inventario IT",
    description: "Gestiona equipos y recursos tecnológicos",
    icon: Laptop,
    color: "bg-yellow-500/20 text-yellow-400",
    link: "/inventory"
  },
  {
    title: "Calendario IT",
    description: "Programa mantenimientos y eventos importantes",
    icon: Calendar,
    color: "bg-pink-500/20 text-pink-400",
    link: "/calendar"
  },
  {
    title: "Notificaciones",
    description: "Centro de notificaciones y alertas",
    icon: Bell,
    color: "bg-red-500/20 text-red-400",
    link: "/notifications"
  },
  {
    title: "Reportes y Análisis",
    description: "Visualiza estadísticas y genera informes",
    icon: BarChart,
    color: "bg-indigo-500/20 text-indigo-400",
    link: "/reports"
  },
  {
    title: "Configuración",
    description: "Personaliza la configuración del sistema",
    icon: Settings,
    color: "bg-gray-500/20 text-gray-400",
    link: "/settings"
  }
];