import { motion } from "framer-motion";
import { AlertCircle, Clock, CheckCircle, BarChart2 } from "lucide-react";

interface TicketStatsProps {
  stats: {
    total: number;
    open: number;
    inProgress: number;
    closed: number;
  };
}

export const TicketStatCards = ({ stats }: TicketStatsProps) => {
  const cards = [
    {
      title: "Total Tickets",
      value: stats.total,
      icon: BarChart2,
      color: "text-gray-100",
      bgColor: "bg-gray-700/50",
      borderColor: "border-gray-600"
    },
    {
      title: "Abiertos",
      value: stats.open,
      icon: AlertCircle,
      color: "text-green-400",
      bgColor: "bg-green-900/30",
      borderColor: "border-green-700/30"
    },
    {
      title: "En Progreso",
      value: stats.inProgress,
      icon: Clock,
      color: "text-blue-400",
      bgColor: "bg-blue-900/30",
      borderColor: "border-blue-700/30"
    },
    {
      title: "Cerrados",
      value: stats.closed,
      icon: CheckCircle,
      color: "text-gray-300",
      bgColor: "bg-gray-700/50",
      borderColor: "border-gray-600"
    }
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
      {cards.map((card, index) => {
        const Icon = card.icon;
        return (
          <motion.div
            key={card.title}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: index * 0.1 }}
          >
            <div className={`p-4 rounded-lg border ${card.bgColor} ${card.borderColor} backdrop-blur-sm`}>
              <div className="flex items-center justify-between mb-2">
                <div className="text-sm text-gray-400">{card.title}</div>
                <Icon className={`h-4 w-4 ${card.color}`} />
              </div>
              <div className={`text-2xl font-bold ${card.color}`}>
                {card.value}
              </div>
            </div>
          </motion.div>
        );
      })}
    </div>
  );
};