
import { Button } from "@/components/ui/button";
import { AlertOctagon, RefreshCw } from "lucide-react";
import { motion } from "framer-motion";

interface EmpleadosErrorProps {
  onRetry: () => void;
}

export const EmpleadosError = ({ onRetry }: EmpleadosErrorProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col items-center justify-center min-h-[60vh] p-8"
    >
      <div className="p-4 rounded-full bg-red-500/10 mb-6">
        <AlertOctagon className="w-12 h-12 text-red-500" />
      </div>
      <h2 className="text-2xl font-bold text-white mb-2">Error al cargar empleados</h2>
      <p className="text-gray-400 mb-6 text-center max-w-md">
        No se pudieron cargar los datos de empleados. Por favor verifica tu conexión y vuelve a intentarlo.
      </p>
      <Button 
        onClick={onRetry}
        className="flex items-center gap-2 bg-blue-600 hover:bg-blue-700"
      >
        <RefreshCw className="w-4 h-4" />
        Reintentar
      </Button>
    </motion.div>
  );
};
