import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { format } from "date-fns";
import { Button } from "@/components/ui/button";
import { Trash2, Edit2 } from "lucide-react";
import { useState } from "react";
import { EditDialog } from "./EditDialog";
import { useNewHires } from "./useNewHires";
import { NewHire } from "./types";

export const NewHiresList = () => {
  const [editingHire, setEditingHire] = useState<NewHire | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const { 
    newHires, 
    isLoading, 
    updateNewHireMutation, 
    deleteNewHireMutation 
  } = useNewHires();

  const handleDelete = (id: string) => {
    if (window.confirm('¿Estás seguro de que quieres eliminar esta incorporación?')) {
      deleteNewHireMutation.mutate(id);
    }
  };

  const handleEdit = (hire: NewHire) => {
    setEditingHire(hire);
    setEditDialogOpen(true);
  };

  const handleUpdate = (updatedHire: NewHire) => {
    updateNewHireMutation.mutate(updatedHire);
    setEditDialogOpen(false);
  };

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  return (
    <>
      <div className="rounded-md border border-gray-700 bg-gray-800/50">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Nombre</TableHead>
              <TableHead>CDSID</TableHead>
              <TableHead>Mercado</TableHead>
              <TableHead>Fecha Incorporación</TableHead>
              <TableHead>Estado</TableHead>
              <TableHead>Notificaciones</TableHead>
              <TableHead>Acciones</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {newHires?.map((hire) => (
              <TableRow key={hire.id}>
                <TableCell>{hire.name}</TableCell>
                <TableCell>{hire.cdsid}</TableCell>
                <TableCell>{hire.market}</TableCell>
                <TableCell>
                  {format(new Date(hire.start_date), 'dd/MM/yyyy')}
                </TableCell>
                <TableCell>
                  <Badge
                    className={
                      hire.status === 'completed'
                        ? 'bg-green-500/20 text-green-400'
                        : hire.status === 'in_progress'
                        ? 'bg-yellow-500/20 text-yellow-400'
                        : 'bg-blue-500/20 text-blue-400'
                    }
                  >
                    {hire.status === 'completed'
                      ? 'Completado'
                      : hire.status === 'in_progress'
                      ? 'En Proceso'
                      : 'Pendiente'}
                  </Badge>
                </TableCell>
                <TableCell>
                  {hire.new_hire_notifications?.map((notification) => (
                    <div key={notification.email} className="text-sm text-gray-400">
                      {notification.email}
                    </div>
                  ))}
                </TableCell>
                <TableCell>
                  <div className="flex gap-2">
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => handleEdit(hire)}
                      className="text-blue-400 hover:text-blue-500 hover:bg-blue-500/10"
                    >
                      <Edit2 className="h-4 w-4" />
                    </Button>
                    <Button
                      variant="ghost"
                      size="icon"
                      className="text-red-400 hover:text-red-500 hover:bg-red-500/10"
                      onClick={() => handleDelete(hire.id)}
                    >
                      <Trash2 className="h-4 w-4" />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <EditDialog
        hire={editingHire}
        open={editDialogOpen}
        onOpenChange={setEditDialogOpen}
        onUpdate={handleUpdate}
      />
    </>
  );
};