import { useState, useCallback } from "react";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Platform, RequestType } from "@/integrations/supabase/types/tables";
import { useQuery } from "@tanstack/react-query";

export const useAccessRequest = () => {
  const [loading, setLoading] = useState(false);
  const [cdsid, setCdsid] = useState("");
  const [nombre, setNombre] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [mercado, setMercado] = useState("");
  const [plataforma, setPlataforma] = useState("");
  const [tipoSolicitud, setTipoSolicitud] = useState("");
  const { toast } = useToast();

  // Usar React Query para manejar el estado y caché de los datos
  const { data: platforms = [], isLoading: platformsLoading } = useQuery({
    queryKey: ["platforms"],
    queryFn: async () => {
      console.log("Fetching platforms...");
      const { data, error } = await supabase
        .from("plataformas_acceso")
        .select("*")
        .order("nombre");

      if (error) {
        console.error("Error fetching platforms:", error);
        throw error;
      }

      console.log("Platforms fetched:", data);
      return data as Platform[];
    },
    retry: 1,
    staleTime: 300000, // 5 minutos
  });

  const { data: requestTypes = [], isLoading: requestTypesLoading } = useQuery({
    queryKey: ["requestTypes"],
    queryFn: async () => {
      console.log("Fetching request types...");
      const { data, error } = await supabase
        .from("tipos_solicitud_acceso")
        .select("*")
        .order("nombre");

      if (error) {
        console.error("Error fetching request types:", error);
        throw error;
      }

      console.log("Request types fetched:", data);
      return data as RequestType[];
    },
    retry: 1,
    staleTime: 300000, // 5 minutos
  });

  const resetForm = () => {
    setCdsid("");
    setNombre("");
    setApellidos("");
    setMercado("");
    setPlataforma("");
    setTipoSolicitud("");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { data: empleados, error: empleadoError } = await supabase
        .from("empleados")
        .select("*")
        .eq("cdsid", cdsid.toUpperCase());

      if (empleadoError) throw empleadoError;

      if (!empleados?.length) {
        toast({
          title: "Error",
          description: "El CDSID proporcionado no existe en el sistema",
          variant: "destructive",
        });
        return;
      }

      const { error: accessError } = await supabase
        .from("solicitudes_acceso")
        .insert({
          cdsid,
          nombre,
          apellidos,
          mercado,
          accesos_solicitados: [{
            plataforma_id: plataforma,
            tipo_solicitud: tipoSolicitud
          }],
          estado: "pendiente"
        });

      if (accessError) throw accessError;

      const { error: platformError } = await supabase
        .from("solicitudes_plataforma")
        .insert({
          plataforma_id: plataforma,
          tipo_solicitud: tipoSolicitud,
          estado: "pendiente",
          send_email: true
        });

      if (platformError) throw platformError;

      toast({
        title: "Solicitud enviada",
        description: "Tu solicitud ha sido enviada correctamente",
      });

      resetForm();
    } catch (error: any) {
      console.error("Error submitting access request:", error);
      toast({
        title: "Error",
        description: error.message || "Error al enviar la solicitud",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    loading: loading || platformsLoading || requestTypesLoading,
    cdsid,
    setCdsid,
    nombre,
    setNombre,
    apellidos,
    setApellidos,
    mercado,
    setMercado,
    plataforma,
    setPlataforma,
    tipoSolicitud,
    setTipoSolicitud,
    platforms,
    requestTypes,
    handleSubmit
  };
};