import { usePortalConfig } from "@/contexts/PortalConfigContext";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Paintbrush, Settings } from "lucide-react";
import { useState } from "react";

export const PortalConfig = () => {
  const { config, updateConfig, isLoading } = usePortalConfig();
  const [localConfig, setLocalConfig] = useState(config);

  if (isLoading) {
    return <div>Cargando configuración...</div>;
  }

  if (!config || !localConfig) {
    return <div>No se encontró configuración</div>;
  }

  const handleSave = async () => {
    if (!localConfig) return;
    await updateConfig(localConfig);
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-2">
        <Settings className="h-5 w-5" />
        <h2 className="text-xl font-semibold">Configuración del Portal</h2>
      </div>

      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <Paintbrush className="h-5 w-5" />
            Personalización
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="grid gap-4 md:grid-cols-2">
            <div className="space-y-2">
              <Label>Nombre del Portal</Label>
              <Input
                value={localConfig.name}
                onChange={(e) => setLocalConfig({ ...localConfig, name: e.target.value })}
                placeholder="Mi Portal"
              />
            </div>

            <div className="space-y-2">
              <Label>URL del Logo</Label>
              <Input
                value={localConfig.logoUrl || ''}
                onChange={(e) => setLocalConfig({ ...localConfig, logoUrl: e.target.value })}
                placeholder="https://ejemplo.com/logo.png"
              />
            </div>

            <div className="space-y-2">
              <Label>Color Primario</Label>
              <div className="flex gap-2">
                <Input
                  type="color"
                  value={localConfig.primaryColor}
                  onChange={(e) => setLocalConfig({ ...localConfig, primaryColor: e.target.value })}
                  className="w-20"
                />
                <Input
                  value={localConfig.primaryColor}
                  onChange={(e) => setLocalConfig({ ...localConfig, primaryColor: e.target.value })}
                  placeholder="#000000"
                />
              </div>
            </div>

            <div className="space-y-2">
              <Label>Color Secundario</Label>
              <div className="flex gap-2">
                <Input
                  type="color"
                  value={localConfig.secondaryColor}
                  onChange={(e) => setLocalConfig({ ...localConfig, secondaryColor: e.target.value })}
                  className="w-20"
                />
                <Input
                  value={localConfig.secondaryColor}
                  onChange={(e) => setLocalConfig({ ...localConfig, secondaryColor: e.target.value })}
                  placeholder="#000000"
                />
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <h3 className="text-lg font-medium">Módulos Activos</h3>
            <div className="grid gap-4 md:grid-cols-2">
              <div className="flex items-center justify-between">
                <Label>Empleados</Label>
                <Switch
                  checked={localConfig.modules.employees}
                  onCheckedChange={(checked) => 
                    setLocalConfig({
                      ...localConfig,
                      modules: { ...localConfig.modules, employees: checked }
                    })
                  }
                />
              </div>

              <div className="flex items-center justify-between">
                <Label>Inventario</Label>
                <Switch
                  checked={localConfig.modules.inventory}
                  onCheckedChange={(checked) => 
                    setLocalConfig({
                      ...localConfig,
                      modules: { ...localConfig.modules, inventory: checked }
                    })
                  }
                />
              </div>

              <div className="flex items-center justify-between">
                <Label>Tickets</Label>
                <Switch
                  checked={localConfig.modules.tickets}
                  onCheckedChange={(checked) => 
                    setLocalConfig({
                      ...localConfig,
                      modules: { ...localConfig.modules, tickets: checked }
                    })
                  }
                />
              </div>

              <div className="flex items-center justify-between">
                <Label>Calendario</Label>
                <Switch
                  checked={localConfig.modules.calendar}
                  onCheckedChange={(checked) => 
                    setLocalConfig({
                      ...localConfig,
                      modules: { ...localConfig.modules, calendar: checked }
                    })
                  }
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <Button onClick={handleSave}>
              Guardar Cambios
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};