import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { RefreshCw, XSquare, CheckSquare, Trash } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";

interface EquipmentStatusActionsProps {
  equipmentId: string;
  currentStatus: string;
  onStatusChange: () => void;
}

export const EquipmentStatusActions = ({ 
  equipmentId, 
  currentStatus,
  onStatusChange 
}: EquipmentStatusActionsProps) => {
  
  const updateEquipmentStatus = async (newStatus: string) => {
    try {
      const { error } = await supabase
        .from("piezas")
        .update({ 
          estado: newStatus,
          updated_at: new Date().toISOString()
        })
        .eq("id", equipmentId);

      if (error) throw error;

      toast.success(`Estado actualizado a: ${newStatus}`);
      onStatusChange();
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error("Error al actualizar el estado");
    }
  };

  return (
    <div className="flex gap-2">
      <Button
        variant="ghost"
        size="icon"
        onClick={() => updateEquipmentStatus('bueno')}
        className="hover:text-green-500"
        title="Marcar como en buen estado"
      >
        <CheckSquare className="h-4 w-4" />
      </Button>
      
      <Button
        variant="ghost"
        size="icon"
        onClick={() => updateEquipmentStatus('dañado')}
        className="hover:text-red-500"
        title="Marcar como dañado"
      >
        <XSquare className="h-4 w-4" />
      </Button>
      
      <Button
        variant="ghost"
        size="icon"
        onClick={() => updateEquipmentStatus('reemplazado')}
        className="hover:text-blue-500"
        title="Marcar como reemplazado"
      >
        <RefreshCw className="h-4 w-4" />
      </Button>
      
      <Button
        variant="ghost"
        size="icon"
        onClick={() => updateEquipmentStatus('baja')}
        className="hover:text-red-500"
        title="Dar de baja"
      >
        <Trash className="h-4 w-4" />
      </Button>
    </div>
  );
};