import { AssetReturnForm } from "@/components/inventory/returns/AssetReturnForm";

const InventoryReturn = () => {
  return (
    <div className="container mx-auto p-8 pt-24">
      <h1 className="text-2xl font-bold mb-8 text-white">Registrar Devolución de Activos</h1>
      <AssetReturnForm />
    </div>
  );
};

export default InventoryReturn;