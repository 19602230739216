import { useState } from "react";
import { Calendar as CalendarComponent } from "@/components/ui/calendar";
import { Card } from "@/components/ui/card";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { motion } from "framer-motion";
import type { CalendarEvent } from "@/types/calendar";
import { AddEventDialog } from "@/components/calendar/AddEventDialog";
import { EventsList } from "@/components/calendar/EventsList";
import { WorkScheduleCalendar } from "@/components/calendar/WorkScheduleCalendar";
import { es } from "date-fns/locale";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

const Calendar = () => {
  const [date, setDate] = useState<Date | undefined>(new Date());
  const [isAddEventOpen, setIsAddEventOpen] = useState(false);

  const { data: events = [], refetch } = useQuery({
    queryKey: ["calendar-events"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("calendar_events")
        .select("*")
        .order("start_date", { ascending: true });

      if (error) {
        console.error("Error fetching calendar events:", error);
        throw error;
      }

      return data as CalendarEvent[];
    },
  });

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 p-8 pt-24">
      <div className="max-w-7xl mx-auto">
        <Tabs defaultValue="events" className="w-full">
          <TabsList className="mb-8">
            <TabsTrigger value="events">Eventos IT</TabsTrigger>
            <TabsTrigger value="schedule">Calendario Oficina - Remoto Iván</TabsTrigger>
          </TabsList>

          <TabsContent value="events">
            <div className="flex justify-between items-center mb-8">
              <div>
                <h1 className="text-3xl font-bold text-white mb-2">Calendario IT</h1>
                <p className="text-gray-400">Gestiona eventos y mantenimientos programados</p>
              </div>
              
              <AddEventDialog 
                isOpen={isAddEventOpen}
                onOpenChange={setIsAddEventOpen}
                onEventAdded={refetch}
              />
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="lg:col-span-2"
              >
                <Card className="p-6 bg-gray-800/50 backdrop-blur-xl border-gray-700/50">
                  <CalendarComponent
                    mode="single"
                    selected={date}
                    onSelect={setDate}
                    className="bg-gray-800/50 text-white rounded-lg p-4"
                    locale={es}
                  />
                </Card>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <EventsList date={date} events={events} />
              </motion.div>
            </div>
          </TabsContent>

          <TabsContent value="schedule">
            <div className="flex justify-between items-center mb-8">
              <div>
                <h1 className="text-3xl font-bold text-white mb-2">Horario de Trabajo</h1>
                <p className="text-gray-400">Gestiona tus días de oficina y teletrabajo</p>
              </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <WorkScheduleCalendar />
              </motion.div>

              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="bg-gray-800/50 backdrop-blur-xl border-gray-700/50 rounded-lg p-6"
              >
                <h3 className="text-xl font-semibold text-white mb-4">Leyenda</h3>
                <div className="space-y-4">
                  <div className="flex items-center gap-2">
                    <div className="w-4 h-4 rounded bg-blue-500"></div>
                    <span className="text-white">Día en oficina</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-4 h-4 rounded bg-green-500"></div>
                    <span className="text-white">Día en teletrabajo</span>
                  </div>
                </div>
              </motion.div>
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export default Calendar;