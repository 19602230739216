import { createContext, useContext, useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

interface PortalConfig {
  name: string;
  logoUrl: string | null;
  primaryColor: string;
  secondaryColor: string;
  accentColor: string;
  fontFamily: string;
  modules: {
    employees: boolean;
    inventory: boolean;
    tickets: boolean;
    calendar: boolean;
  };
  customFields: Record<string, any>;
}

interface PortalConfigContextType {
  config: PortalConfig | null;
  isLoading: boolean;
  error: Error | null;
  updateConfig: (newConfig: Partial<PortalConfig>) => Promise<void>;
}

const PortalConfigContext = createContext<PortalConfigContextType | undefined>(undefined);

export function PortalConfigProvider({ children }: { children: React.ReactNode }) {
  const [config, setConfig] = useState<PortalConfig | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const loadConfig = async () => {
      try {
        console.log('Loading portal configuration...');
        
        const { data, error } = await supabase
          .from('portal_config')
          .select('*')
          .limit(1)
          .maybeSingle();

        if (error) {
          console.error('Error loading portal config:', error);
          throw error;
        }

        if (data) {
          console.log('Portal config loaded:', data);
          setConfig({
            name: data.name,
            logoUrl: data.logo_url,
            primaryColor: data.primary_color,
            secondaryColor: data.secondary_color,
            accentColor: data.accent_color,
            fontFamily: data.font_family,
            modules: data.modules,
            customFields: data.custom_fields,
          });
        } else {
          console.log('No portal configuration found, creating default...');
          const defaultConfig = {
            name: 'Mi Portal',
            logo_url: null,
            primary_color: '#0052CC',
            secondary_color: '#F4F5F7',
            accent_color: '#36B37E',
            font_family: 'Inter',
            modules: {
              employees: true,
              inventory: true,
              tickets: true,
              calendar: true
            },
            custom_fields: {}
          };

          const { data: newConfig, error: insertError } = await supabase
            .from('portal_config')
            .insert(defaultConfig)
            .select()
            .single();

          if (insertError) {
            console.error('Error creating default config:', insertError);
            throw insertError;
          }

          console.log('Default config created:', newConfig);
          setConfig({
            name: newConfig.name,
            logoUrl: newConfig.logo_url,
            primaryColor: newConfig.primary_color,
            secondaryColor: newConfig.secondary_color,
            accentColor: newConfig.accent_color,
            fontFamily: newConfig.font_family,
            modules: newConfig.modules,
            customFields: newConfig.custom_fields,
          });
        }
      } catch (err: any) {
        console.error('Error loading portal config:', err);
        setError(err);
        toast.error("Error al cargar la configuración del portal");
      } finally {
        setIsLoading(false);
      }
    };

    loadConfig();
  }, []);

  const updateConfig = async (newConfig: Partial<PortalConfig>) => {
    try {
      console.log('Updating portal configuration:', newConfig);
      
      const { error } = await supabase
        .from('portal_config')
        .update({
          name: newConfig.name,
          logo_url: newConfig.logoUrl,
          primary_color: newConfig.primaryColor,
          secondary_color: newConfig.secondaryColor,
          accent_color: newConfig.accentColor,
          font_family: newConfig.fontFamily,
          modules: newConfig.modules,
          custom_fields: newConfig.customFields,
        })
        .eq('id', 1);

      if (error) throw error;

      setConfig(prev => prev ? { ...prev, ...newConfig } : null);
      toast.success("Configuración actualizada correctamente");
    } catch (err: any) {
      console.error('Error updating portal config:', err);
      toast.error("Error al actualizar la configuración");
      throw err;
    }
  };

  return (
    <PortalConfigContext.Provider value={{ config, isLoading, error, updateConfig }}>
      {children}
    </PortalConfigContext.Provider>
  );
}

export function usePortalConfig() {
  const context = useContext(PortalConfigContext);
  if (context === undefined) {
    throw new Error('usePortalConfig must be used within a PortalConfigProvider');
  }
  return context;
}