import { AssetCollectionForm } from "@/components/asset-returns/AssetCollectionForm";
import { ProtectedRoute } from "@/components/auth/ProtectedRoute";

const NewAssetCollection = () => {
  return (
    <ProtectedRoute>
      <div className="container mx-auto p-8 pt-24">
        <h1 className="text-2xl font-bold mb-8 text-white">Nueva Recogida de Activos</h1>
        <AssetCollectionForm />
      </div>
    </ProtectedRoute>
  );
};

export default NewAssetCollection;