import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Mail, Plus, X } from "lucide-react";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";

interface NotificationDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onSend: (emails: string[]) => Promise<void>;
}

export const NotificationDialog = ({ isOpen, onOpenChange, onSend }: NotificationDialogProps) => {
  const [notificationEmails, setNotificationEmails] = useState<string[]>([]);
  const [newEmail, setNewEmail] = useState("");
  const [defaultRecipients, setDefaultRecipients] = useState<{id: string, name: string, email: string}[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadDefaultRecipients();
  }, []);

  const loadDefaultRecipients = async () => {
    try {
      const { data, error } = await supabase
        .from('new_hire_default_recipients')
        .select('*')
        .order('name');

      if (error) throw error;
      setDefaultRecipients(data || []);
    } catch (error) {
      console.error('Error loading default recipients:', error);
      toast.error("Error al cargar destinatarios predefinidos");
    }
  };

  const addDefaultRecipients = () => {
    const newEmails = [...new Set([...notificationEmails, ...defaultRecipients.map(r => r.email)])];
    setNotificationEmails(newEmails);
  };

  const handleSend = async () => {
    try {
      setIsLoading(true);
      if (!notificationEmails.length) {
        toast.error('Por favor ingresa al menos un email');
        return;
      }

      await onSend(notificationEmails);
      setNotificationEmails([]);
      onOpenChange(false);
    } catch (error) {
      console.error('Error sending notification:', error);
      toast.error('Error al enviar la notificación');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px] bg-gray-900 text-white">
        <DialogHeader>
          <DialogTitle>Enviar Notificación de Estado</DialogTitle>
        </DialogHeader>
        <div className="space-y-6 py-4">
          <div className="flex justify-between items-center">
            <h3 className="text-sm font-medium">Destinatarios</h3>
            <Button
              type="button"
              variant="outline"
              size="sm"
              onClick={addDefaultRecipients}
              className="gap-2 bg-gray-800 hover:bg-gray-700"
            >
              <Mail className="h-4 w-4" />
              Añadir Destinatarios Predefinidos
            </Button>
          </div>

          <div className="space-y-4">
            <div className="flex gap-2">
              <Input
                type="email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                placeholder="Añadir email adicional"
                className="flex-1 bg-gray-800 border-gray-700"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && newEmail) {
                    e.preventDefault();
                    if (!notificationEmails.includes(newEmail)) {
                      setNotificationEmails([...notificationEmails, newEmail]);
                      setNewEmail("");
                    }
                  }
                }}
              />
              <Button 
                type="button" 
                variant="outline"
                onClick={() => {
                  if (newEmail && !notificationEmails.includes(newEmail)) {
                    setNotificationEmails([...notificationEmails, newEmail]);
                    setNewEmail("");
                  }
                }}
                className="bg-gray-800 hover:bg-gray-700"
              >
                <Plus className="h-4 w-4" />
              </Button>
            </div>

            {notificationEmails.length > 0 && (
              <div className="space-y-2">
                {notificationEmails.map((email) => {
                  const recipient = defaultRecipients.find(r => r.email === email);
                  return (
                    <div key={email} className="flex items-center gap-2 bg-gray-800/50 p-2 rounded border border-gray-700">
                      <span className="flex-1 text-sm">
                        {recipient ? `${recipient.name} (${email})` : email}
                      </span>
                      <Button
                        type="button"
                        variant="ghost"
                        size="sm"
                        onClick={() => setNotificationEmails(notificationEmails.filter(e => e !== email))}
                        className="hover:bg-gray-700"
                      >
                        <X className="h-4 w-4" />
                      </Button>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <Button
            onClick={handleSend}
            disabled={isLoading || notificationEmails.length === 0}
            className="w-full bg-blue-600 hover:bg-blue-700"
          >
            {isLoading ? (
              <div className="flex items-center justify-center space-x-2">
                <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white" />
                <span>Enviando...</span>
              </div>
            ) : (
              <>
                <Mail className="mr-2 h-4 w-4" />
                Enviar Notificación
              </>
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};