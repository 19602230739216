import { motion } from "framer-motion";
import { ServiceAlertsList } from "@/components/alerts/ServiceAlertsList";
import { Card } from "@/components/ui/card";
import { Bell } from "lucide-react";

const PublicServiceAlerts = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900">
      <div className="container max-w-6xl mx-auto px-4 py-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="space-y-6"
        >
          <Card className="p-6 bg-gray-800/50 backdrop-blur-lg border-gray-700/50">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-3 bg-blue-500/10 rounded-lg animate-float">
                <Bell className="w-6 h-6 text-blue-400" />
              </div>
              <div>
                <h1 className="text-2xl font-bold text-white">Estado del Sistema</h1>
                <p className="text-gray-400">
                  Mantente informado sobre el estado de nuestros servicios y plataformas
                </p>
              </div>
            </div>
            
            <ServiceAlertsList isLoginPage={true} />
          </Card>

          <div className="text-center text-sm text-gray-500 mt-4">
            <p>¿Necesitas ayuda? Contacta con el equipo de soporte IT</p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default PublicServiceAlerts;