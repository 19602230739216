import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { AlertCircle } from "lucide-react";
import { ServiceAlertCard } from "./ServiceAlertCard";
import { Skeleton } from "@/components/ui/skeleton";
import { useEffect } from "react";

interface ServiceAlert {
  id: string;
  title: string;
  description: string;
  type: string;
  severity: "low" | "medium" | "high" | "critical";
  status: string;
  affected_services: string[];
  created_at: string;
  updated_at: string;
  resolved_at: string | null;
  affected_users_count: number;
}

interface ServiceAlertsListProps {
  isLoginPage?: boolean;
  currentUserCDSID?: string;
}

export const ServiceAlertsList = ({ isLoginPage = false, currentUserCDSID }: ServiceAlertsListProps) => {
  // Prefetch inmediatamente al montar el componente
  useEffect(() => {
    const prefetchAlerts = async () => {
      console.log('Prefetching service alerts...');
      const { data, error } = await supabase
        .from('service_alerts')
        .select(`
          *,
          affected_users:alert_affected_users(count)
        `)
        .eq('status', 'active')
        .order('severity', { ascending: false })
        .limit(5);
      
      if (error) {
        console.error('Error prefetching alerts:', error);
      } else {
        console.log('Prefetched alerts:', data?.length || 0, 'alerts');
        console.log('Alert details:', data); // Log alert details for debugging
      }
      
      return data;
    };

    prefetchAlerts();
  }, []);

  const { data: alerts, isLoading, error } = useQuery({
    queryKey: ['service-alerts'],
    queryFn: async () => {
      try {
        console.log('Fetching service alerts...');
        const { data, error } = await supabase
          .from('service_alerts')
          .select(`
            *,
            affected_users:alert_affected_users(count)
          `)
          .eq('status', 'active')
          .order('severity', { ascending: false })
          .limit(5);

        if (error) throw error;
        console.log('Service alerts fetched successfully:', data?.length || 0, 'alerts');
        console.log('Alert details:', data); // Log alert details for debugging
        return data as ServiceAlert[];
      } catch (error) {
        console.error('Error fetching alerts:', error);
        throw error;
      }
    },
    staleTime: 60000, // 1 minuto
    gcTime: 300000, // 5 minutos
    refetchInterval: 30000, // 30 segundos
    refetchOnMount: "always",
    refetchOnWindowFocus: true,
    retry: 2,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    initialData: [] as ServiceAlert[],
  });

  if (error) {
    return (
      <Alert variant="destructive" className="mb-4">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>
          No se pudieron cargar las alertas. Por favor, intenta más tarde.
        </AlertDescription>
      </Alert>
    );
  }

  if (isLoading) {
    return (
      <div className="space-y-3 mb-6">
        <Skeleton className="h-[100px] w-full bg-gray-800/50" />
        <Skeleton className="h-[100px] w-full bg-gray-800/50" />
      </div>
    );
  }

  if (!alerts || alerts.length === 0) {
    return null;
  }

  return (
    <div className={`space-y-4 ${isLoginPage ? 'mb-6' : ''}`}>
      {alerts.map((alert) => (
        <ServiceAlertCard
          key={alert.id}
          alert={alert}
          isLoginPage={isLoginPage}
          currentUserCDSID={currentUserCDSID}
        />
      ))}
    </div>
  );
};