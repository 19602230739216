import { motion } from "framer-motion";

interface PriorityStats {
  urgent: number;
  high: number;
  medium: number;
  low: number;
}

interface TicketPriorityBreakdownProps {
  stats: {
    total: number;
    byPriority: PriorityStats;
  };
}

export const TicketPriorityBreakdown = ({ stats }: TicketPriorityBreakdownProps) => {
  const priorities = [
    { key: 'urgent', label: 'Urgente', color: 'bg-red-500' },
    { key: 'high', label: 'Alta', color: 'bg-orange-500' },
    { key: 'medium', label: 'Media', color: 'bg-yellow-500' },
    { key: 'low', label: 'Baja', color: 'bg-green-500' }
  ];

  return (
    <div className="space-y-4">
      {priorities.map((priority, index) => {
        const count = stats.byPriority[priority.key as keyof PriorityStats];
        const percentage = ((count / stats.total) * 100) || 0;
        
        return (
          <motion.div
            key={priority.key}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3, delay: index * 0.1 }}
            className="space-y-2"
          >
            <div className="flex justify-between text-sm">
              <span className="text-gray-300">{priority.label}</span>
              <span className="text-gray-400">{count} tickets</span>
            </div>
            <div className="relative h-2 bg-gray-700 rounded-full overflow-hidden">
              <motion.div
                initial={{ width: 0 }}
                animate={{ width: `${percentage}%` }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className={`absolute h-full ${priority.color} rounded-full`}
              />
            </div>
          </motion.div>
        );
      })}
    </div>
  );
};