
import { ReactNode, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "@/hooks/useAuth";
import { Loader2 } from "lucide-react";
import { toast } from "sonner";

interface ProtectedRouteProps {
  children: ReactNode;
  requiredRole?: 'admin' | 'user';
}

export const ProtectedRoute = ({ 
  children, 
  requiredRole 
}: ProtectedRouteProps) => {
  const { user, isAdmin, isLoading } = useAuth();
  const location = useLocation();
  const [initialCheckDone, setInitialCheckDone] = useState(false);

  useEffect(() => {
    // Set a timeout to ensure we don't stay in loading state forever
    const timer = setTimeout(() => {
      if (isLoading) {
        setInitialCheckDone(true);
      }
    }, 3000);

    if (!isLoading) {
      setInitialCheckDone(true);
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [isLoading]);

  // First check - is the auth still loading?
  if (isLoading && !initialCheckDone) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen p-4">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500 mb-4" />
        <p className="text-gray-500 text-center">Verificando autenticación...</p>
      </div>
    );
  }

  // Second check - is the user authenticated?
  if (!user) {
    toast.error("Debes iniciar sesión para acceder a esta página", {
      id: "auth-required"
    });
    
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  // Third check - check role-based access if required
  if (requiredRole === 'admin' && !isAdmin) {
    toast.error("No tienes permisos para acceder a esta página", {
      id: "admin-required"
    });
    
    return <Navigate to="/dashboard" replace />;
  }

  // User is authenticated and has the required role (if any)
  return <>{children}</>;
};
