import { motion } from "framer-motion";
import { EmpleadoCard } from "./EmpleadoCard";
import { Empleado } from "@/integrations/supabase/types/tables";

interface EmpleadoMarketSectionProps {
  mercado: string;
  empleados: Empleado[];
  onUpdate: () => void;
}

export const EmpleadoMarketSection = ({ 
  mercado, 
  empleados,
  onUpdate 
}: EmpleadoMarketSectionProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.2 }}
      className="space-y-4"
    >
      <div className="flex items-center gap-2">
        <h2 className="text-xl font-semibold text-white">{mercado}</h2>
        <span className="bg-gray-800 text-gray-400 text-sm px-2 py-1 rounded">
          {empleados.length} empleados
        </span>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {empleados.map((empleado) => (
          <EmpleadoCard 
            key={empleado.id} 
            empleado={empleado} 
            onUpdate={onUpdate}
          />
        ))}
      </div>
    </motion.div>
  );
};