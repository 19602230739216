
import { Empleado } from "@/integrations/supabase/types/tables";
import { ActionsList } from "./actions/ActionsList";

interface EmpleadoActionsProps {
  empleado: Empleado;
  onBajaTemporalClick: () => void;
  onBajaTotalClick: () => void;
}

export const EmpleadoActions = ({ 
  empleado,
  onBajaTemporalClick,
  onBajaTotalClick
}: EmpleadoActionsProps) => {
  return (
    <ActionsList
      empleado={empleado}
      onBajaTemporalClick={onBajaTemporalClick}
      onBajaTotalClick={onBajaTotalClick}
    />
  );
};
