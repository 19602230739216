import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { supabase } from "@/integrations/supabase/client";

interface EmpleadoEditDialogProps {
  empleado: any;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onUpdate: () => void;
}

export const EmpleadoEditDialog = ({
  empleado,
  open,
  onOpenChange,
  onUpdate,
}: EmpleadoEditDialogProps) => {
  const [formData, setFormData] = useState({
    nombre: empleado.nombre,
    apellidos: empleado.apellidos,
    email: empleado.email,
    msxi_email: empleado.msxi_email,
    extension_telefonica: empleado.extension_telefonica,
    posicion: empleado.posicion,
    mercado: empleado.mercado,
    region: empleado.region,
    estado: empleado.estado,
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const { error } = await supabase
      .from('empleados')
      .update(formData)
      .eq('id', empleado.id);

    if (error) {
      console.error('Error updating employee:', error);
      return;
    }

    onUpdate();
    onOpenChange(false);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="bg-gray-800 text-white">
        <DialogHeader>
          <DialogTitle>Editar Empleado</DialogTitle>
        </DialogHeader>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <label>Nombre</label>
              <Input
                value={formData.nombre}
                onChange={(e) => setFormData({ ...formData, nombre: e.target.value })}
                className="bg-gray-700"
              />
            </div>

            <div className="space-y-2">
              <label>Apellidos</label>
              <Input
                value={formData.apellidos}
                onChange={(e) => setFormData({ ...formData, apellidos: e.target.value })}
                className="bg-gray-700"
              />
            </div>
          </div>

          <div className="space-y-2">
            <label>Email Ford</label>
            <Input
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              className="bg-gray-700"
            />
          </div>

          <div className="space-y-2">
            <label>Email MSXI</label>
            <Input
              value={formData.msxi_email || ''}
              onChange={(e) => setFormData({ ...formData, msxi_email: e.target.value })}
              className="bg-gray-700"
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <label>Extensión</label>
              <Input
                value={formData.extension_telefonica || ''}
                onChange={(e) => setFormData({ ...formData, extension_telefonica: e.target.value })}
                className="bg-gray-700"
              />
            </div>

            <div className="space-y-2">
              <label>Posición</label>
              <Input
                value={formData.posicion || ''}
                onChange={(e) => setFormData({ ...formData, posicion: e.target.value })}
                className="bg-gray-700"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <label>Mercado</label>
              <Select
                value={formData.mercado}
                onValueChange={(value) => setFormData({ ...formData, mercado: value })}
              >
                <SelectTrigger className="bg-gray-700">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="IBERIA">IBERIA</SelectItem>
                  <SelectItem value="ITALY">ITALY</SelectItem>
                  <SelectItem value="FRANCE">FRANCE</SelectItem>
                  <SelectItem value="TRANSVERSAL">TRANSVERSAL</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <label>Estado</label>
              <Select
                value={formData.estado}
                onValueChange={(value) => setFormData({ ...formData, estado: value })}
              >
                <SelectTrigger className="bg-gray-700">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="activo">Activo</SelectItem>
                  <SelectItem value="espera">En espera</SelectItem>
                  <SelectItem value="inactivo">Inactivo</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>

          <div className="flex justify-end gap-2">
            <Button variant="outline" onClick={() => onOpenChange(false)}>
              Cancelar
            </Button>
            <Button type="submit">Guardar cambios</Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};