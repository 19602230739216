import { Monitor, Laptop, Usb, Keyboard, Mouse, Headphones, Cable, Signal, Package, Smartphone } from "lucide-react";

export const EQUIPMENT_TYPES = {
  telefono: { label: 'Teléfono', icon: Smartphone },
  pantalla: { label: 'Pantalla', icon: Monitor },
  docking: { label: 'Docking Station', icon: Usb },
  teclado: { label: 'Teclado', icon: Keyboard },
  raton: { label: 'Ratón', icon: Mouse },
  bolsa: { label: 'Bolsa', icon: Package },
  cascos: { label: 'Cascos', icon: Headphones },
  modem_4g: { label: 'Modem 4G Vodafone', icon: Signal },
  cable_hdmi: { label: 'Cable HDMI', icon: Cable },
  otros: { label: 'Otros', icon: Package },
} as const;