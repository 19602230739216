import { useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { AccessRequestHistory } from "./AccessRequestHistory";

interface EditAccessRequestDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  request: {
    id: string;
    estado: string;
    plataforma_id: string;
    tipo_solicitud: string;
    plataformas_acceso?: {
      nombre: string;
    };
    tipos_solicitud_acceso?: {
      nombre: string;
    };
  };
  onRequestUpdated: () => void;
}

export const EditAccessRequestDialog = ({
  isOpen,
  onOpenChange,
  request,
  onRequestUpdated
}: EditAccessRequestDialogProps) => {
  const [status, setStatus] = useState(request.estado);
  const [sendEmail, setSendEmail] = useState(false);
  const [notes, setNotes] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      console.log('Updating access request:', { id: request.id, status, sendEmail, notes });

      // Update request status
      const { error: updateError } = await supabase
        .from('solicitudes_plataforma')
        .update({ 
          estado: status,
          updated_at: new Date().toISOString()
        })
        .eq('id', request.id);

      if (updateError) throw updateError;

      // Add to history
      const { error: historyError } = await supabase
        .from('access_request_history')
        .insert({
          request_id: request.id,
          status,
          notes
        });

      if (historyError) throw historyError;

      // Send email notification if requested
      if (sendEmail) {
        const { error: notificationError } = await supabase.functions.invoke('notify-access-request', {
          body: { 
            requestId: request.id,
            type: 'status_update',
            additionalMessage: notes
          }
        });

        if (notificationError) {
          console.error('Error sending notification:', notificationError);
          toast.error('Error al enviar la notificación por email');
        }
      }

      toast.success('Solicitud actualizada correctamente');
      onRequestUpdated();
      onOpenChange(false);
    } catch (error) {
      console.error('Error updating access request:', error);
      toast.error('Error al actualizar la solicitud');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px] bg-gray-900 text-white">
        <DialogHeader>
          <DialogTitle>Editar Solicitud de Acceso</DialogTitle>
        </DialogHeader>
        
        <div className="space-y-6 py-4">
          <div className="space-y-2">
            <Label>Plataforma</Label>
            <p className="text-sm text-gray-400">{request.plataformas_acceso?.nombre}</p>
          </div>

          <div className="space-y-2">
            <Label>Tipo de Solicitud</Label>
            <p className="text-sm text-gray-400">{request.tipos_solicitud_acceso?.nombre}</p>
          </div>

          <div className="space-y-2">
            <Label>Estado</Label>
            <Select value={status} onValueChange={setStatus}>
              <SelectTrigger className="bg-gray-800 border-gray-700">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="pendiente">Pendiente</SelectItem>
                <SelectItem value="en_proceso">En Proceso</SelectItem>
                <SelectItem value="aprobado">Aprobado</SelectItem>
                <SelectItem value="rechazado">Rechazado</SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="space-y-2">
            <Label>Notas</Label>
            <Textarea
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Añade notas sobre el cambio de estado..."
              className="bg-gray-800 border-gray-700"
            />
          </div>

          <div className="flex items-center space-x-2">
            <Switch
              id="send-email"
              checked={sendEmail}
              onCheckedChange={setSendEmail}
            />
            <Label htmlFor="send-email">Enviar notificación por email</Label>
          </div>

          <AccessRequestHistory requestId={request.id} />
        </div>

        <div className="flex justify-end space-x-2">
          <Button
            variant="outline"
            onClick={() => onOpenChange(false)}
            className="bg-gray-800 hover:bg-gray-700"
          >
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={isSubmitting}
            className="bg-blue-600 hover:bg-blue-700"
          >
            {isSubmitting ? 'Guardando...' : 'Guardar Cambios'}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};