import { Building2, Mail, Phone, Key, Laptop } from "lucide-react";
import { motion } from "framer-motion";

interface EmpleadoInfoProps {
  empleado: any;
}

export const EmpleadoInfo = ({ empleado }: EmpleadoInfoProps) => {
  return (
    <div className="space-y-3">
      <motion.div 
        className="flex items-center text-gray-300"
        whileHover={{ x: 5 }}
        transition={{ duration: 0.2 }}
      >
        <Building2 className="w-4 h-4 mr-2 text-blue-400" />
        <span className="text-sm">{empleado.posicion || 'No especificado'}</span>
      </motion.div>

      <motion.div 
        className="flex items-center text-gray-300"
        whileHover={{ x: 5 }}
        transition={{ duration: 0.2 }}
      >
        <Mail className="w-4 h-4 mr-2 text-purple-400" />
        <span className="text-sm">{empleado.msxi_email || empleado.email}</span>
      </motion.div>

      <motion.div 
        className="flex items-center text-gray-300"
        whileHover={{ x: 5 }}
        transition={{ duration: 0.2 }}
      >
        <Phone className="w-4 h-4 mr-2 text-green-400" />
        <span className="text-sm">{empleado.extension_telefonica || 'No disponible'}</span>
      </motion.div>

      <motion.div 
        className="flex items-center text-gray-300"
        whileHover={{ x: 5 }}
        transition={{ duration: 0.2 }}
      >
        <Key className="w-4 h-4 mr-2 text-yellow-400" />
        <span className="text-sm">
          {empleado.accesos_vigentes?.join(', ') || 'Sin accesos'}
        </span>
      </motion.div>

      <motion.div 
        className="flex items-center text-gray-300"
        whileHover={{ x: 5 }}
        transition={{ duration: 0.2 }}
      >
        <Laptop className="w-4 h-4 mr-2 text-red-400" />
        <span className="text-sm">
          {empleado.equipos?.[0]?.codigo_equipo || 'Sin equipo asignado'}
        </span>
      </motion.div>
    </div>
  );
};