import { formatDistanceToNow } from "date-fns";
import { es } from "date-fns/locale";
import { TicketBadges } from "./TicketBadges";
import type { Ticket } from "@/hooks/useTickets";
import { User } from "lucide-react";
import { useEmpleadoInfo } from "./hooks/useEmpleadoInfo";

interface TicketContentProps {
  ticket: Ticket;
}

export const TicketContent = ({ ticket }: TicketContentProps) => {
  const { data: empleado, isLoading } = useEmpleadoInfo(ticket.affected_cdsid);

  return (
    <div className="space-y-4 animate-in fade-in duration-300">
      <div className="flex justify-between items-start">
        <h3 className="text-xl font-semibold">{ticket.title}</h3>
        <TicketBadges status={ticket.status} priority={ticket.priority} />
      </div>
      
      <p className="text-gray-300 whitespace-pre-wrap">{ticket.description}</p>
      
      <div className="flex flex-col gap-2 text-sm text-gray-300">
        <div className="flex items-center gap-2">
          <span>Creado por: {ticket.submitter_name || 'Usuario del sistema'}</span>
          <span>•</span>
          <span>
            {formatDistanceToNow(new Date(ticket.created_at), {
              addSuffix: true,
              locale: es,
            })}
          </span>
        </div>
        
        {ticket.affected_cdsid && (
          <div className="flex items-center gap-2 text-blue-400">
            <User className="h-4 w-4" />
            <div className="flex flex-col">
              <span className="font-medium">Usuario Afectado: {ticket.affected_cdsid}</span>
              {isLoading ? (
                <span className="text-sm text-gray-400">
                  Cargando información del empleado...
                </span>
              ) : empleado ? (
                <span className="text-sm text-gray-400">
                  {empleado.nombre} {empleado.apellidos} - {empleado.mercado}
                </span>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};