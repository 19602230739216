import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import { TicketStats } from "../types";

export const addStatsSection = (doc: jsPDF, stats: TicketStats, startY: number): number => {
  const statsData = [
    ["Total de Tickets", stats.total?.toString() || "0"],
    ["Tickets Abiertos", stats.open?.toString() || "0"],
    ["En Progreso", stats.inProgress?.toString() || "0"],
    ["Cerrados", stats.closed?.toString() || "0"]
  ];

  autoTable(doc, {
    startY,
    head: [["Métrica", "Cantidad"]],
    body: statsData,
    theme: 'grid',
    headStyles: {
      fillColor: [41, 128, 185],
      textColor: 255,
      fontSize: 12,
      fontStyle: 'bold'
    },
    styles: {
      fontSize: 10,
      cellPadding: 8,
      lineColor: [220, 220, 220]
    },
    columnStyles: {
      0: { cellWidth: 100 },
      1: { cellWidth: 40, halign: 'center' }
    },
    alternateRowStyles: {
      fillColor: [245, 247, 250]
    }
  });

  // @ts-ignore - lastAutoTable is added by jspdf-autotable
  return doc.lastAutoTable?.finalY || startY + 100;
};