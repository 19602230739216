import { useState } from "react";
import { useTicketComments } from "./useTicketComments";
import { useTicketActions } from "./useTicketActions";
import type { Ticket } from "@/hooks/useTickets";

export const useTicketDetails = (ticket: Ticket, onOpenChange: (open: boolean) => void) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(ticket?.title);
  const [editedDescription, setEditedDescription] = useState(ticket?.description);
  const [editedPriority, setPriority] = useState(ticket?.priority || "medium");
  const [editedCategory, setCategory] = useState(ticket?.category || "hardware");
  const [editedStatus, setStatus] = useState(ticket?.status || "open");
  const [editedAffectedCdsid, setAffectedCdsid] = useState(ticket?.affected_cdsid || "");
  const [sendEmail, setSendEmail] = useState(true);
  const [customEmailSubject, setCustomEmailSubject] = useState("");
  const [additionalEmails, setAdditionalEmails] = useState<string[]>([]);

  const { comments, handleAddComment } = useTicketComments(ticket.id);
  const { isSubmitting, handleUpdate, handleDelete } = useTicketActions(ticket, onOpenChange);

  const handleSubmitUpdate = async () => {
    await handleUpdate({
      title: editedTitle,
      description: editedDescription,
      priority: editedPriority,
      category: editedCategory,
      status: editedStatus,
      affected_cdsid: editedAffectedCdsid,
      sendEmail,
      customEmailSubject,
      additionalEmails
    });
    setIsEditing(false);
  };

  return {
    isEditing,
    setIsEditing,
    editedTitle,
    setEditedTitle,
    editedDescription,
    setEditedDescription,
    editedPriority,
    setPriority,
    editedCategory,
    setCategory,
    editedStatus,
    setStatus,
    editedAffectedCdsid,
    setAffectedCdsid,
    sendEmail,
    setSendEmail,
    customEmailSubject,
    setCustomEmailSubject,
    additionalEmails,
    setAdditionalEmails,
    isSubmitting,
    comments,
    handleUpdate: handleSubmitUpdate,
    handleDelete,
    handleAddComment
  };
};