import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Calendar } from "lucide-react";

interface DateFieldsProps {
  formData: {
    fecha_baja: string;
    fecha_recogida: string;
  };
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const DateFields = ({ formData, onChange }: DateFieldsProps) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div className="space-y-2">
        <Label htmlFor="fecha_baja" className="flex items-center gap-2">
          <Calendar className="h-4 w-4 text-blue-400" />
          Fecha de Baja
        </Label>
        <Input
          id="fecha_baja"
          type="date"
          value={formData.fecha_baja}
          onChange={onChange}
          name="fecha_baja"
          className="bg-gray-800/50 border-gray-700"
          required
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="fecha_recogida" className="flex items-center gap-2">
          <Calendar className="h-4 w-4 text-blue-400" />
          Fecha de Recogida Tramitada
        </Label>
        <Input
          id="fecha_recogida"
          type="date"
          value={formData.fecha_recogida}
          onChange={onChange}
          name="fecha_recogida"
          className="bg-gray-800/50 border-gray-700"
          required
        />
      </div>
    </div>
  );
};