import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Label } from "@/components/ui/label";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { Empleado } from "@/integrations/supabase/types/tables";

interface EmpleadoDetailsDialogProps {
  empleado: Empleado | null;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onUpdate: () => void;
}

export const EmpleadoDetailsDialog = ({
  empleado,
  open,
  onOpenChange,
  onUpdate,
}: EmpleadoDetailsDialogProps) => {
  const [formData, setFormData] = useState(empleado || {
    cdsid: "",
    nombre: "",
    apellidos: "",
    email: "",
    msxi_email: "",
    extension_telefonica: "",
    posicion: "",
    mercado: "IBERIA",
    region: "IBERIA CX",
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      console.log('Submitting empleado data:', formData);
      
      const { error } = await supabase
        .from('empleados')
        .upsert({
          ...formData,
          updated_at: new Date().toISOString()
        });

      if (error) throw error;

      console.log('Empleado updated successfully');
      toast.success(empleado ? "Empleado actualizado" : "Empleado creado");
      onUpdate();
      onOpenChange(false);
    } catch (error: any) {
      console.error('Error updating empleado:', error);
      toast.error("Error al guardar: " + error.message);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="bg-gray-800 text-white">
        <DialogHeader>
          <DialogTitle>
            {empleado ? "Editar Empleado" : "Nuevo Empleado"}
          </DialogTitle>
        </DialogHeader>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label>CDSID</Label>
              <Input
                value={formData.cdsid}
                onChange={(e) => setFormData({ ...formData, cdsid: e.target.value.toUpperCase() })}
                className="bg-gray-700"
                required
              />
            </div>

            <div className="space-y-2">
              <Label>Mercado</Label>
              <Select
                value={formData.mercado}
                onValueChange={(value) => setFormData({ ...formData, mercado: value })}
              >
                <SelectTrigger className="bg-gray-700">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="IBERIA">IBERIA</SelectItem>
                  <SelectItem value="ITALY">ITALY</SelectItem>
                  <SelectItem value="FRANCE">FRANCE</SelectItem>
                  <SelectItem value="TRANSVERSAL">TRANSVERSAL</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label>Nombre</Label>
              <Input
                value={formData.nombre}
                onChange={(e) => setFormData({ ...formData, nombre: e.target.value })}
                className="bg-gray-700"
                required
              />
            </div>

            <div className="space-y-2">
              <Label>Apellidos</Label>
              <Input
                value={formData.apellidos}
                onChange={(e) => setFormData({ ...formData, apellidos: e.target.value })}
                className="bg-gray-700"
                required
              />
            </div>
          </div>

          <div className="space-y-2">
            <Label>Email Ford</Label>
            <Input
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              className="bg-gray-700"
              type="email"
            />
          </div>

          <div className="space-y-2">
            <Label>Email MSXI</Label>
            <Input
              value={formData.msxi_email || ''}
              onChange={(e) => setFormData({ ...formData, msxi_email: e.target.value })}
              className="bg-gray-700"
              type="email"
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label>Extensión</Label>
              <Input
                value={formData.extension_telefonica || ''}
                onChange={(e) => setFormData({ ...formData, extension_telefonica: e.target.value })}
                className="bg-gray-700"
              />
            </div>

            <div className="space-y-2">
              <Label>Posición</Label>
              <Input
                value={formData.posicion || ''}
                onChange={(e) => setFormData({ ...formData, posicion: e.target.value })}
                className="bg-gray-700"
              />
            </div>
          </div>

          <div className="flex justify-end gap-2">
            <Button variant="outline" onClick={() => onOpenChange(false)}>
              Cancelar
            </Button>
            <Button type="submit">
              {empleado ? "Guardar cambios" : "Crear empleado"}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};