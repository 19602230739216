import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { Ticket } from "../types";
import { applyHeaderStyle } from "../styles";

export const addTicketsSection = (doc: jsPDF, tickets: Ticket[]) => {
  doc.addPage();
  
  applyHeaderStyle(doc);
  doc.text("Detalle de Tickets", 105, 25, { align: "center" });
  
  const ticketsTableData = tickets.map(ticket => [
    ticket.ticket_number?.toString() || 'N/A',
    ticket.title || 'N/A',
    ticket.status === 'open' ? 'Abierto' : 
    ticket.status === 'in_progress' ? 'En Progreso' : 
    'Cerrado',
    ticket.priority === 'urgent' ? 'Urgente' :
    ticket.priority === 'high' ? 'Alta' :
    ticket.priority === 'medium' ? 'Media' : 'Baja',
    ticket.submitter_cdsid || 'N/A',
    ticket.affected_cdsid || 'N/A',
    ticket.submitter_name || 'N/A',
    format(new Date(ticket.created_at), 'dd/MM/yyyy'),
    ticket.category || 'N/A'
  ]);
  
  autoTable(doc, {
    startY: 45,
    head: [['#', 'Título', 'Estado', 'Prioridad', 'CDSID Creador', 'CDSID Afectado', 'Creado por', 'Fecha', 'Categoría']],
    body: ticketsTableData,
    theme: 'grid',
    headStyles: {
      fillColor: [52, 73, 94],
      textColor: 255,
      fontSize: 10,
      fontStyle: 'bold'
    },
    styles: {
      fontSize: 8,
      cellPadding: 6,
      lineColor: [220, 220, 220]
    },
    columnStyles: {
      0: { cellWidth: 20 },
      1: { cellWidth: 35 },
      2: { cellWidth: 25 },
      3: { cellWidth: 20 },
      4: { cellWidth: 25 },
      5: { cellWidth: 25 },
      6: { cellWidth: 25 },
      7: { cellWidth: 20 },
      8: { cellWidth: 20 }
    },
    alternateRowStyles: {
      fillColor: [245, 247, 250]
    }
  });
};