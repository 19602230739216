import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuCheckboxItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ArrowUpDown } from "lucide-react";

interface SortOptionsProps {
  sortBy: 'created_at' | 'priority' | 'status';
  sortOrder: 'asc' | 'desc';
  onSortChange: (field: 'created_at' | 'priority' | 'status', order: 'asc' | 'desc') => void;
}

export const SortOptions = ({ sortBy, sortOrder, onSortChange }: SortOptionsProps) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="gap-2">
          <ArrowUpDown className="w-4 h-4" />
          Ordenar
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="bg-gray-800 border-gray-700">
        <DropdownMenuLabel>Ordenar por</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuCheckboxItem
          checked={sortBy === 'created_at'}
          onCheckedChange={() => onSortChange('created_at', sortOrder)}
        >
          Fecha {sortOrder === 'desc' ? '↓' : '↑'}
        </DropdownMenuCheckboxItem>
        <DropdownMenuCheckboxItem
          checked={sortBy === 'priority'}
          onCheckedChange={() => onSortChange('priority', sortOrder)}
        >
          Prioridad {sortOrder === 'desc' ? '↓' : '↑'}
        </DropdownMenuCheckboxItem>
        <DropdownMenuCheckboxItem
          checked={sortBy === 'status'}
          onCheckedChange={() => onSortChange('status', sortOrder)}
        >
          Estado {sortOrder === 'desc' ? '↓' : '↑'}
        </DropdownMenuCheckboxItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};