import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";

interface AffectedUser {
  user_cdsid: string;
  reported_at: string;
}

interface AlertUsersDialogProps {
  isOpen: boolean;
  onClose: () => void;
  affectedUsers: AffectedUser[];
}

export const AlertUsersDialog = ({ isOpen, onClose, affectedUsers }: AlertUsersDialogProps) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="bg-gray-900 border-gray-800">
        <DialogHeader>
          <DialogTitle className="text-gray-100">Usuarios Afectados</DialogTitle>
        </DialogHeader>
        <div className="space-y-2">
          {affectedUsers.map((user) => (
            <div key={user.user_cdsid} className="flex justify-between items-center p-2 bg-gray-800 rounded">
              <span className="text-gray-200">{user.user_cdsid}</span>
              <span className="text-sm text-gray-400">
                {new Date(user.reported_at).toLocaleString()}
              </span>
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};