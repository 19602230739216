import { Card } from "@/components/ui/card";
import { Ticket, Clock, CheckCircle, AlertCircle } from "lucide-react";
import { motion } from "framer-motion";

interface TicketStats {
  total: number;
  open: number;
  inProgress: number;
  closed: number;
  byPriority: {
    low: number;
    medium: number;
    high: number;
    urgent: number;
  };
}

interface Props {
  stats: TicketStats;
}

export const TicketStats = ({ stats }: Props) => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  return (
    <motion.div 
      variants={container}
      initial="hidden"
      animate="show"
      className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4"
    >
      <motion.div variants={item}>
        <Card className="p-4 bg-gray-800/50 border-gray-700 hover:bg-gray-700/50 transition-all duration-200">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-400">Total Tickets</p>
              <h3 className="text-2xl font-bold text-white">{stats.total}</h3>
            </div>
            <div className="p-3 bg-blue-500/10 rounded-full">
              <Ticket className="w-6 h-6 text-blue-500" />
            </div>
          </div>
          <div className="mt-4 pt-4 border-t border-gray-700">
            <div className="text-xs text-gray-400">
              Distribución por prioridad:
            </div>
            <div className="mt-2 grid grid-cols-4 gap-2">
              {Object.entries(stats.byPriority).map(([priority, count]) => (
                <div 
                  key={priority}
                  className="px-2 py-1 rounded text-center text-xs font-medium"
                  style={{
                    backgroundColor: priority === 'urgent' ? 'rgba(239, 68, 68, 0.1)' :
                                   priority === 'high' ? 'rgba(249, 115, 22, 0.1)' :
                                   priority === 'medium' ? 'rgba(234, 179, 8, 0.1)' :
                                   'rgba(34, 197, 94, 0.1)',
                    color: priority === 'urgent' ? 'rgb(239, 68, 68)' :
                           priority === 'high' ? 'rgb(249, 115, 22)' :
                           priority === 'medium' ? 'rgb(234, 179, 8)' :
                           'rgb(34, 197, 94)'
                  }}
                >
                  {count}
                </div>
              ))}
            </div>
          </div>
        </Card>
      </motion.div>

      <motion.div variants={item}>
        <Card className="p-4 bg-gray-800/50 border-gray-700 hover:bg-gray-700/50 transition-all duration-200">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-400">En Progreso</p>
              <h3 className="text-2xl font-bold text-white">{stats.inProgress}</h3>
            </div>
            <div className="p-3 bg-yellow-500/10 rounded-full">
              <Clock className="w-6 h-6 text-yellow-500" />
            </div>
          </div>
          <div className="mt-4 pt-4 border-t border-gray-700">
            <div className="text-xs text-gray-400">
              {((stats.inProgress / stats.total) * 100).toFixed(1)}% del total
            </div>
            <div className="mt-2 h-2 bg-gray-700 rounded-full overflow-hidden">
              <motion.div 
                className="h-full bg-yellow-500 rounded-full"
                initial={{ width: 0 }}
                animate={{ width: `${(stats.inProgress / stats.total) * 100}%` }}
                transition={{ duration: 0.5, ease: "easeOut" }}
              />
            </div>
          </div>
        </Card>
      </motion.div>

      <motion.div variants={item}>
        <Card className="p-4 bg-gray-800/50 border-gray-700 hover:bg-gray-700/50 transition-all duration-200">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-400">Cerrados</p>
              <h3 className="text-2xl font-bold text-white">{stats.closed}</h3>
            </div>
            <div className="p-3 bg-green-500/10 rounded-full">
              <CheckCircle className="w-6 h-6 text-green-500" />
            </div>
          </div>
          <div className="mt-4 pt-4 border-t border-gray-700">
            <div className="text-xs text-gray-400">
              {((stats.closed / stats.total) * 100).toFixed(1)}% completados
            </div>
            <div className="mt-2 h-2 bg-gray-700 rounded-full overflow-hidden">
              <motion.div 
                className="h-full bg-green-500 rounded-full"
                initial={{ width: 0 }}
                animate={{ width: `${(stats.closed / stats.total) * 100}%` }}
                transition={{ duration: 0.5, ease: "easeOut" }}
              />
            </div>
          </div>
        </Card>
      </motion.div>

      <motion.div variants={item}>
        <Card className="p-4 bg-gray-800/50 border-gray-700 hover:bg-gray-700/50 transition-all duration-200">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-400">Abiertos</p>
              <h3 className="text-2xl font-bold text-white">{stats.open}</h3>
            </div>
            <div className="p-3 bg-red-500/10 rounded-full">
              <AlertCircle className="w-6 h-6 text-red-500" />
            </div>
          </div>
          <div className="mt-4 pt-4 border-t border-gray-700">
            <div className="text-xs text-gray-400">
              {((stats.open / stats.total) * 100).toFixed(1)}% pendientes
            </div>
            <div className="mt-2 h-2 bg-gray-700 rounded-full overflow-hidden">
              <motion.div 
                className="h-full bg-red-500 rounded-full"
                initial={{ width: 0 }}
                animate={{ width: `${(stats.open / stats.total) * 100}%` }}
                transition={{ duration: 0.5, ease: "easeOut" }}
              />
            </div>
          </div>
        </Card>
      </motion.div>
    </motion.div>
  );
};