import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

interface ImportEmpleadosDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onImportComplete: () => void;
}

export const ImportEmpleadosDialog = ({
  open,
  onOpenChange,
  onImportComplete,
}: ImportEmpleadosDialogProps) => {
  const [pastedData, setPastedData] = useState("");
  const [isImporting, setIsImporting] = useState(false);

  const parseEmpleados = (data: string) => {
    console.log('Starting to parse empleados data');
    const rows = data.trim().split('\n');
    
    // Create a Map to store unique entries by CDSID
    const uniqueEmpleados = new Map();
    let currentRegion = 'IBERIA CX'; // Default region
    
    rows.forEach(row => {
      const trimmedRow = row.trim();
      
      // Check if this is a region header
      if (trimmedRow.includes('CX')) {
        currentRegion = trimmedRow;
        console.log('Switching to region:', currentRegion);
        return;
      }

      // Split by tab and handle both tab and multiple spaces
      const parts = row.split(/[\t]+/);
      if (parts.length < 2) return; // Skip invalid rows

      const [nombre, cdsid] = parts;
      
      // Skip invalid rows or empty lines
      if (!cdsid?.trim() || !nombre?.trim()) {
        console.log('Skipping invalid row:', row);
        return;
      }

      const cleanCdsid = cdsid.trim().toUpperCase();
      const cleanEmail = `${cleanCdsid.toLowerCase()}`;
      
      // Extract first name and last name from the full name
      const fullName = nombre.trim();
      const [firstName, ...lastNameParts] = fullName.split(' ');
      const lastName = lastNameParts.join(' ') || 'N/A';

      // Determine market based on region
      let market = 'IBERIA';
      if (currentRegion.includes('ITALY')) market = 'ITALY';
      else if (currentRegion.includes('FRANCE')) market = 'FRANCE';
      else if (currentRegion.includes('TRANSVERSAL')) market = 'TRANSVERSAL';

      console.log(`Processing employee: ${cleanCdsid} in region: ${currentRegion}`);

      // Store in Map, overwriting any previous entry with same CDSID
      uniqueEmpleados.set(cleanCdsid, {
        cdsid: cleanCdsid,
        nombre: firstName,
        apellidos: lastName,
        email: cleanEmail,
        msxi_email: null,
        mercado: market,
        estado: 'activo',
        region: currentRegion,
      });
    });

    // Convert Map values to array
    const empleados = Array.from(uniqueEmpleados.values());
    console.log('Parsed unique empleados:', empleados.length);
    return empleados;
  };

  const handleImport = async () => {
    try {
      setIsImporting(true);
      console.log('Starting employee import...');
      const empleados = parseEmpleados(pastedData);
      
      if (empleados.length === 0) {
        toast.error("No se encontraron datos válidos para importar");
        setIsImporting(false);
        return;
      }

      console.log('Attempting to import empleados:', empleados);

      const { error } = await supabase
        .from('empleados')
        .upsert(empleados, {
          onConflict: 'cdsid'
        });

      if (error) {
        console.error('Error importing employees:', error);
        toast.error("Error al importar empleados: " + error.message);
        setIsImporting(false);
        return;
      }

      console.log('Import successful');
      toast.success(`${empleados.length} empleados importados correctamente`);
      onImportComplete();
      onOpenChange(false);
    } catch (error) {
      console.error('Error importing employees:', error);
      toast.error("Error al importar empleados");
    } finally {
      setIsImporting(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="bg-gray-800 text-white">
        <DialogHeader>
          <DialogTitle>Importar Empleados</DialogTitle>
        </DialogHeader>

        <div className="space-y-4">
          <div className="space-y-2">
            <label>Pega los datos de los empleados</label>
            <p className="text-sm text-gray-400">
              Formato: Nombre, CDSID (separados por tabulaciones)
            </p>
            <Textarea
              value={pastedData}
              onChange={(e) => setPastedData(e.target.value)}
              className="bg-gray-700 h-64 font-mono"
              placeholder="IBERIA CX&#10;Nombre&#9;CDSID"
            />
          </div>

          <div className="flex justify-end gap-2">
            <Button 
              variant="outline" 
              onClick={() => onOpenChange(false)}
              disabled={isImporting}
            >
              Cancelar
            </Button>
            <Button 
              onClick={handleImport}
              disabled={isImporting}
            >
              {isImporting ? 'Importando...' : 'Importar'}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};