import { useState } from "react";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { toast } from "sonner";
import { HelpCircle } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

export const PublicTicketForm = () => {
  const [loading, setLoading] = useState(false);
  const [cdsid, setCdsid] = useState("");
  const [market, setMarket] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("other");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (loading) return;

    if (!cdsid || !email || !title || !description) {
      toast.error("Por favor complete todos los campos requeridos");
      return;
    }

    setLoading(true);

    try {
      // Here we'll implement the ticket creation logic later
      console.log("Creating ticket...", {
        cdsid,
        market,
        email,
        title,
        description,
        category
      });

      toast.success("Ticket creado exitosamente");
      
      // Reset form
      setCdsid("");
      setMarket("");
      setEmail("");
      setTitle("");
      setDescription("");
      setCategory("other");
    } catch (error) {
      console.error("Error creating ticket:", error);
      toast.error("Error al crear el ticket");
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="space-y-6"
    >
      <div className="p-4 bg-blue-500/10 border border-blue-500/20 rounded-lg">
        <p className="text-sm text-blue-400">
          Use este formulario para crear un ticket de soporte. Nuestro equipo le responderá lo antes posible.
        </p>
      </div>

      <motion.form 
        onSubmit={handleSubmit} 
        className="space-y-6"
        initial={false}
        animate={loading ? { opacity: 0.7 } : { opacity: 1 }}
        transition={{ duration: 0.2 }}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label htmlFor="cdsid" className="flex items-center gap-2">
              CDSID
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <HelpCircle className="h-4 w-4 text-gray-400" />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Tu identificador de usuario de Ford</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </Label>
            <Input
              id="cdsid"
              value={cdsid}
              onChange={(e) => setCdsid(e.target.value.toUpperCase())}
              placeholder="Ej: ABC123"
              className="bg-gray-700/50 border-gray-600 text-white placeholder:text-gray-400"
              required
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="market" className="flex items-center gap-2">
              Mercado
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <HelpCircle className="h-4 w-4 text-gray-400" />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Tu mercado actual</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </Label>
            <Select value={market} onValueChange={setMarket}>
              <SelectTrigger className="bg-gray-700/50 border-gray-600 text-white">
                <SelectValue placeholder="Selecciona tu mercado" />
              </SelectTrigger>
              <SelectContent className="bg-gray-800 border-gray-700">
                <SelectItem value="ES" className="text-white hover:bg-gray-700">España</SelectItem>
                <SelectItem value="PT" className="text-white hover:bg-gray-700">Portugal</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="email" className="flex items-center gap-2">
            Email
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <HelpCircle className="h-4 w-4 text-gray-400" />
                </TooltipTrigger>
                <TooltipContent>
                  <p>Tu correo electrónico para notificaciones</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </Label>
          <Input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="tu.correo@ford.com"
            className="bg-gray-700/50 border-gray-600 text-white placeholder:text-gray-400"
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="title" className="flex items-center gap-2">
            Título
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <HelpCircle className="h-4 w-4 text-gray-400" />
                </TooltipTrigger>
                <TooltipContent>
                  <p>Un título breve y descriptivo para tu ticket</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </Label>
          <Input
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Ej: Problema con acceso a aplicación"
            className="bg-gray-700/50 border-gray-600 text-white placeholder:text-gray-400"
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="description" className="flex items-center gap-2">
            Descripción
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <HelpCircle className="h-4 w-4 text-gray-400" />
                </TooltipTrigger>
                <TooltipContent>
                  <p>Describe detalladamente el problema o solicitud</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </Label>
          <Textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Proporciona todos los detalles relevantes..."
            className="bg-gray-700/50 border-gray-600 text-white placeholder:text-gray-400 min-h-[100px]"
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="category" className="flex items-center gap-2">
            Categoría
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <HelpCircle className="h-4 w-4 text-gray-400" />
                </TooltipTrigger>
                <TooltipContent>
                  <p>Selecciona la categoría que mejor describe tu solicitud</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </Label>
          <Select value={category} onValueChange={setCategory}>
            <SelectTrigger className="bg-gray-700/50 border-gray-600 text-white">
              <SelectValue placeholder="Selecciona una categoría" />
            </SelectTrigger>
            <SelectContent className="bg-gray-800 border-gray-700">
              <SelectItem value="hardware" className="text-white hover:bg-gray-700">Hardware</SelectItem>
              <SelectItem value="software" className="text-white hover:bg-gray-700">Software</SelectItem>
              <SelectItem value="access" className="text-white hover:bg-gray-700">Accesos</SelectItem>
              <SelectItem value="network" className="text-white hover:bg-gray-700">Red</SelectItem>
              <SelectItem value="other" className="text-white hover:bg-gray-700">Otro</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <Button
          type="submit"
          className="w-full bg-blue-600 hover:bg-blue-700 text-white"
          disabled={loading}
        >
          {loading ? (
            <div className="flex items-center justify-center space-x-2">
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white" />
              <span>Procesando...</span>
            </div>
          ) : (
            "Crear Ticket"
          )}
        </Button>
      </motion.form>
    </motion.div>
  );
};