import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Brain, Briefcase } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { motion } from "framer-motion";

interface EmpleadoAIAnalysisProps {
  empleado: any;
}

export const EmpleadoAIAnalysis = ({ empleado }: EmpleadoAIAnalysisProps) => {
  const [loading, setLoading] = useState(false);
  const [analysis, setAnalysis] = useState("");
  const [suggestions, setSuggestions] = useState("");

  const analyzeProfile = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase.functions.invoke('analyze-employee', {
        body: { empleado, action: 'analyze_profile' }
      });

      if (error) throw error;
      setAnalysis(data.analysis);
      toast.success("Análisis completado");
    } catch (error) {
      console.error('Error analyzing profile:', error);
      toast.error("Error al analizar el perfil");
    } finally {
      setLoading(false);
    }
  };

  const suggestPositions = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase.functions.invoke('analyze-employee', {
        body: { empleado, action: 'suggest_position' }
      });

      if (error) throw error;
      setSuggestions(data.analysis);
      toast.success("Sugerencias generadas");
    } catch (error) {
      console.error('Error suggesting positions:', error);
      toast.error("Error al generar sugerencias");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex gap-2">
        <Button 
          onClick={analyzeProfile} 
          disabled={loading}
          className="gap-2"
        >
          <Brain className="w-4 h-4" />
          Analizar Perfil
        </Button>
        <Button 
          onClick={suggestPositions} 
          disabled={loading}
          className="gap-2"
        >
          <Briefcase className="w-4 h-4" />
          Sugerir Posiciones
        </Button>
      </div>

      {loading && (
        <div className="text-center text-gray-400">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-400 mx-auto mb-2" />
          Analizando...
        </div>
      )}

      {analysis && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <Card className="p-4 bg-gray-800/50 border-gray-700">
            <h3 className="text-lg font-semibold mb-2 text-blue-400">Análisis del Perfil</h3>
            <div className="text-gray-300 whitespace-pre-line">
              {analysis}
            </div>
          </Card>
        </motion.div>
      )}

      {suggestions && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <Card className="p-4 bg-gray-800/50 border-gray-700">
            <h3 className="text-lg font-semibold mb-2 text-green-400">Sugerencias de Posiciones</h3>
            <div className="text-gray-300 whitespace-pre-line">
              {suggestions}
            </div>
          </Card>
        </motion.div>
      )}
    </div>
  );
};