
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "@/pages/Dashboard";
import Inventory from "@/pages/Inventory";
import NewInventoryItem from "@/pages/NewInventoryItem";
import InventoryReturn from "@/pages/InventoryReturn";
import EmpleadosView from "@/pages/EmpleadosView";
import BajaEmpleado from "@/pages/BajaEmpleado";
import Tickets from "@/pages/Tickets";
import AdminPanel from "@/pages/AdminPanel";
import Settings from "@/pages/Settings";
import AccessRequests from "@/pages/AccessRequests";
import AccessTracking from "@/pages/AccessTracking";
import Login from "@/pages/Login";
import PendingIssues from "@/pages/PendingIssues";
import AssetCollections from "@/pages/AssetCollections";
import NewAssetCollection from "@/pages/NewAssetCollection";
import Reports from "@/pages/Reports";
import Calendar from "@/pages/Calendar";
import AssetReturn from "@/pages/AssetReturn";
import { ProtectedRoute } from "@/components/auth/ProtectedRoute";
import PublicServiceAlerts from "@/pages/PublicServiceAlerts";
import Index from "@/pages/Index";

const AppRoutes = () => {
  return (
    <Routes>
      {/* Public routes */}
      <Route path="/" element={<Index />} />
      <Route path="/login" element={<Login />} />
      <Route path="/pending-issues" element={<PendingIssues />} />
      <Route path="/service-alerts" element={<PublicServiceAlerts />} />
      
      {/* Protected routes */}
      <Route path="/dashboard" element={
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      } />
      
      <Route path="/empleados" element={
        <ProtectedRoute>
          <EmpleadosView />
        </ProtectedRoute>
      } />
      
      <Route path="/baja-empleado" element={
        <ProtectedRoute>
          <BajaEmpleado />
        </ProtectedRoute>
      } />
      
      <Route path="/inventory" element={
        <ProtectedRoute>
          <Inventory />
        </ProtectedRoute>
      } />
      
      <Route path="/inventory/new" element={
        <ProtectedRoute>
          <NewInventoryItem />
        </ProtectedRoute>
      } />

      <Route path="/inventory/return" element={
        <ProtectedRoute>
          <InventoryReturn />
        </ProtectedRoute>
      } />
      
      <Route path="/asset-collections" element={
        <ProtectedRoute>
          <AssetCollections />
        </ProtectedRoute>
      } />

      <Route path="/asset-collections/new" element={
        <ProtectedRoute>
          <NewAssetCollection />
        </ProtectedRoute>
      } />

      <Route path="/asset-collections/:id" element={
        <ProtectedRoute>
          <NewAssetCollection />
        </ProtectedRoute>
      } />
      
      <Route path="/tickets" element={
        <ProtectedRoute>
          <Tickets />
        </ProtectedRoute>
      } />
      
      <Route path="/calendar" element={
        <ProtectedRoute>
          <Calendar />
        </ProtectedRoute>
      } />
      
      <Route path="/admin" element={
        <ProtectedRoute requiredRole="admin">
          <AdminPanel />
        </ProtectedRoute>
      } />
      
      <Route path="/settings" element={
        <ProtectedRoute>
          <Settings />
        </ProtectedRoute>
      } />
      
      <Route path="/access-requests" element={
        <ProtectedRoute>
          <AccessRequests />
        </ProtectedRoute>
      } />

      <Route path="/access-tracking" element={
        <ProtectedRoute>
          <AccessTracking />
        </ProtectedRoute>
      } />
      
      {/* Catch all - redirect to dashboard if authenticated */}
      <Route path="*" element={<Navigate to="/dashboard" replace />} />
    </Routes>
  );
};

export default AppRoutes;
