import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Plus } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

export const CompanyForm = () => {
  const [newCompany, setNewCompany] = useState({ name: "", logo_url: "" });
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateCompany = async () => {
    try {
      setIsLoading(true);
      console.log('Creating new company:', newCompany);
      
      const { data: company, error: companyError } = await supabase
        .from('companies')
        .insert({
          name: newCompany.name,
          logo_url: newCompany.logo_url,
        })
        .select()
        .single();

      if (companyError) throw companyError;

      const { error: configError } = await supabase
        .from('portal_config')
        .insert({
          company_id: company.id,
          name: newCompany.name,
          logo_url: newCompany.logo_url,
          primary_color: '#0052CC',
          secondary_color: '#F4F5F7',
          accent_color: '#36B37E',
          font_family: 'Inter',
          modules: {
            employees: true,
            inventory: true,
            tickets: true,
            calendar: true
          },
          custom_fields: {}
        });

      if (configError) throw configError;

      toast.success("Empresa creada correctamente");
      setNewCompany({ name: "", logo_url: "" });
    } catch (error) {
      console.error('Error creating company:', error);
      toast.error("Error al crear la empresa");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Nueva Empresa</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="space-y-2">
          <Label>Nombre de la Empresa</Label>
          <Input
            value={newCompany.name}
            onChange={(e) => setNewCompany({ ...newCompany, name: e.target.value })}
            placeholder="Mi Empresa"
          />
        </div>

        <div className="space-y-2">
          <Label>URL del Logo</Label>
          <Input
            value={newCompany.logo_url}
            onChange={(e) => setNewCompany({ ...newCompany, logo_url: e.target.value })}
            placeholder="https://ejemplo.com/logo.png"
          />
        </div>

        <Button 
          onClick={handleCreateCompany} 
          disabled={isLoading}
          className="w-full"
        >
          <Plus className="w-4 h-4 mr-2" />
          Crear Nueva Empresa
        </Button>
      </CardContent>
    </Card>
  );
};