import { Button } from "@/components/ui/button";
import { EmpleadosFilters } from "./EmpleadosFilters";
import { EmpleadosExport } from "./EmpleadosExport";
import { Plus, LayoutGrid, List } from "lucide-react";
import { ImportEmpleadosButton } from "./ImportEmpleadosButton";
import { Empleado } from "@/integrations/supabase/types/tables";

interface EmpleadoToolbarProps {
  viewMode: 'grid' | 'list';
  setViewMode: (mode: 'grid' | 'list') => void;
  searchTerm: string;
  onSearchChange: (value: string) => void;
  empleados: Empleado[];
  onShowCreateDialog: () => void;
  onImportComplete: () => void;
}

export const EmpleadoToolbar = ({
  viewMode,
  setViewMode,
  searchTerm,
  onSearchChange,
  empleados,
  onShowCreateDialog,
  onImportComplete
}: EmpleadoToolbarProps) => {
  return (
    <div className="space-y-4">
      <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
        <div className="flex gap-2 bg-gray-800/50 p-1 rounded-lg">
          <Button
            variant={viewMode === 'grid' ? 'default' : 'ghost'}
            size="sm"
            onClick={() => setViewMode('grid')}
            className="gap-2"
          >
            <LayoutGrid className="h-4 w-4" />
            Grid
          </Button>
          <Button
            variant={viewMode === 'list' ? 'default' : 'ghost'}
            size="sm"
            onClick={() => setViewMode('list')}
            className="gap-2"
          >
            <List className="h-4 w-4" />
            Lista
          </Button>
        </div>
        
        <div className="flex flex-wrap items-center gap-3">
          <EmpleadosExport empleados={empleados} />
          
          <Button 
            onClick={onShowCreateDialog}
            className="gap-2 bg-blue-600 hover:bg-blue-700"
          >
            <Plus className="h-4 w-4" />
            Nuevo Empleado
          </Button>
          
          <ImportEmpleadosButton onImportComplete={onImportComplete} />
        </div>
      </div>

      <EmpleadosFilters 
        searchTerm={searchTerm} 
        onSearchChange={onSearchChange} 
      />
    </div>
  );
};