import { useEffect, useState } from "react";
import { Card } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { supabase } from "@/integrations/supabase/client";
import { format } from "date-fns";
import { es } from "date-fns/locale";

interface HistoryItem {
  id: string;
  status: string;
  notes: string;
  created_at: string;
}

interface AccessRequestHistoryProps {
  requestId: string;
}

export const AccessRequestHistory = ({ requestId }: AccessRequestHistoryProps) => {
  const [history, setHistory] = useState<HistoryItem[]>([]);

  useEffect(() => {
    loadHistory();
  }, [requestId]);

  const loadHistory = async () => {
    const { data, error } = await supabase
      .from('access_request_history')
      .select('*')
      .eq('request_id', requestId)
      .order('created_at', { ascending: false });

    if (!error && data) {
      setHistory(data);
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'aprobado':
        return 'bg-green-500/10 text-green-500';
      case 'rechazado':
        return 'bg-red-500/10 text-red-500';
      case 'en_proceso':
        return 'bg-yellow-500/10 text-yellow-500';
      default:
        return 'bg-gray-500/10 text-gray-500';
    }
  };

  return (
    <Card className="p-4 bg-gray-800/50 backdrop-blur-lg border border-gray-700/50">
      <h3 className="text-lg font-medium mb-4">Historial de Cambios</h3>
      
      <div className="space-y-4">
        {history.map((item) => (
          <div 
            key={item.id}
            className="p-3 bg-gray-700/30 rounded-lg border border-gray-600/50"
          >
            <div className="flex items-center justify-between mb-2">
              <Badge className={getStatusColor(item.status)}>
                {item.status}
              </Badge>
              <span className="text-sm text-gray-400">
                {format(new Date(item.created_at), "d 'de' MMMM 'a las' HH:mm", { locale: es })}
              </span>
            </div>
            {item.notes && (
              <p className="text-sm text-gray-300">{item.notes}</p>
            )}
          </div>
        ))}

        {history.length === 0 && (
          <p className="text-center text-gray-400 py-4">
            No hay cambios registrados
          </p>
        )}
      </div>
    </Card>
  );
};