import { useState, useEffect } from "react";
import { Skeleton } from "@/components/ui/skeleton";
import { Badge } from "@/components/ui/badge";
import { Card } from "@/components/ui/card";
import { formatDistanceToNow } from "date-fns";
import { es } from "date-fns/locale";
import { TicketDetails } from "./TicketDetails";
import { Clock, AlertCircle, AlertTriangle, CheckCircle, User, Timer } from "lucide-react";
import type { Ticket } from "@/hooks/useTickets";
import { motion, AnimatePresence } from "framer-motion";
import { useEmpleadoInfo } from "./hooks/useEmpleadoInfo";
import { EnhancedTicketFilters } from "./EnhancedTicketFilters";
import { PriorityBadge } from "./PriorityBadge";
import { getStatusColor, getStatusText } from "@/utils/ticketUtils";

export const TicketList = ({ tickets = [], isLoading }: { tickets?: Ticket[]; isLoading: boolean }) => {
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);
  const [filteredTickets, setFilteredTickets] = useState(tickets);

  const handleFilterChange = (filters: any) => {
    let filtered = [...tickets];

    // Apply search filter
    if (filters.searchTerm) {
      const searchLower = filters.searchTerm.toLowerCase();
      filtered = filtered.filter(ticket => 
        ticket.title.toLowerCase().includes(searchLower) ||
        ticket.description.toLowerCase().includes(searchLower) ||
        ticket.submitter_name?.toLowerCase().includes(searchLower) ||
        ticket.submitter_cdsid?.toLowerCase().includes(searchLower)
      );
    }

    // Apply priority filters
    if (filters.priorities.length > 0) {
      filtered = filtered.filter(ticket => filters.priorities.includes(ticket.priority));
    }

    // Apply category filters
    if (filters.categories.length > 0) {
      filtered = filtered.filter(ticket => filters.categories.includes(ticket.category));
    }

    // Apply status filters
    if (filters.statuses.length > 0) {
      filtered = filtered.filter(ticket => filters.statuses.includes(ticket.status));
    }

    // Apply sorting
    filtered.sort((a, b) => {
      const order = filters.sortOrder === 'desc' ? -1 : 1;
      
      switch (filters.sortBy) {
        case 'priority':
          const priorityOrder = { urgent: 0, high: 1, medium: 2, low: 3 };
          return (priorityOrder[a.priority as keyof typeof priorityOrder] - 
                 priorityOrder[b.priority as keyof typeof priorityOrder]) * order;
        case 'status':
          return (a.status.localeCompare(b.status)) * order;
        case 'created_at':
        default:
          return (new Date(b.created_at).getTime() - new Date(a.created_at).getTime()) * order;
      }
    });

    setFilteredTickets(filtered);
  };

  useEffect(() => {
    setFilteredTickets(tickets);
  }, [tickets]);

  if (isLoading) {
    return (
      <div className="space-y-4">
        {[1, 2, 3].map((i) => (
          <Card key={i} className="p-4 bg-gray-800/50 border-gray-700">
            <div className="animate-pulse">
              <Skeleton className="h-6 w-3/4 mb-2 bg-gray-700" />
              <Skeleton className="h-4 w-1/4 mb-4 bg-gray-700" />
              <div className="flex gap-2">
                <Skeleton className="h-5 w-16 bg-gray-700" />
                <Skeleton className="h-5 w-16 bg-gray-700" />
              </div>
            </div>
          </Card>
        ))}
      </div>
    );
  }

  if (!filteredTickets.length) {
    return (
      <Card className="p-8 text-center text-gray-400 bg-gray-800/50 border-gray-700">
        <div className="flex flex-col items-center gap-2">
          <AlertCircle className="w-8 h-8 text-gray-500" />
          <p>No se encontraron tickets</p>
        </div>
      </Card>
    );
  }

  return (
    <AnimatePresence mode="popLayout">
      <div className="space-y-4">
        <EnhancedTicketFilters onFilterChange={handleFilterChange} />
        
        {filteredTickets.map((ticket) => (
          <motion.div
            key={ticket.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
            layout
          >
            <Card 
              className="p-4 bg-gray-800/50 border-gray-700 hover:bg-gray-700/50 cursor-pointer transition-all duration-200 transform hover:scale-[1.01] hover:shadow-lg"
              onClick={() => setSelectedTicket(ticket)}
            >
              <div className="flex justify-between items-start mb-3">
                <div className="space-y-1">
                  <div className="flex items-center gap-2">
                    <span className="text-sm font-medium text-gray-400">#{ticket.ticket_number}</span>
                    <h3 className="text-lg font-semibold text-white">{ticket.title}</h3>
                  </div>
                  {ticket.submitter_name && (
                    <p className="text-sm text-gray-400 flex items-center gap-1">
                      <Timer className="w-4 h-4" />
                      Creado por: {ticket.submitter_name} 
                      {ticket.submitter_cdsid && `(${ticket.submitter_cdsid})`}
                      {ticket.submitter_market && ` - ${ticket.submitter_market}`}
                    </p>
                  )}
                </div>
                <div className="flex gap-2">
                  <PriorityBadge priority={ticket.priority} />
                  <Badge variant="outline" className={`${getStatusColor(ticket.status)} flex items-center gap-1 px-2 py-1`}>
                    {getStatusText(ticket.status)}
                  </Badge>
                </div>
              </div>
              
              {ticket.affected_cdsid && (
                <div className="mt-2 text-sm text-blue-400 flex items-center gap-2 bg-blue-500/5 rounded-md p-2">
                  <User className="w-4 h-4" />
                  <span>
                    {ticket.affected_cdsid}
                  </span>
                </div>
              )}
              
              <div className="mt-3 text-sm text-gray-400 flex items-center gap-2">
                <Clock className="w-4 h-4" />
                {formatDistanceToNow(new Date(ticket.created_at), {
                  addSuffix: true,
                  locale: es,
                })}
                {ticket.assigned_to && (
                  <>
                    <span className="mx-2">•</span>
                    <span>Asignado a: {ticket.assigned_to}</span>
                  </>
                )}
              </div>
            </Card>
          </motion.div>
        ))}

        {selectedTicket && (
          <TicketDetails
            ticket={selectedTicket}
            open={!!selectedTicket}
            onOpenChange={(open) => !open && setSelectedTicket(null)}
          />
        )}
      </div>
    </AnimatePresence>
  );
};
