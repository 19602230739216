import { Badge } from "@/components/ui/badge";

interface EmpleadoStatusBadgeProps {
  status: string;
}

export const EmpleadoStatusBadge = ({ status }: EmpleadoStatusBadgeProps) => {
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'activo':
        return 'bg-green-500/20 text-green-400';
      case 'espera':
        return 'bg-yellow-500/20 text-yellow-400';
      case 'inactivo':
        return 'bg-red-500/20 text-red-400';
      default:
        return 'bg-gray-500/20 text-gray-400';
    }
  };

  return (
    <Badge className={`${getStatusColor(status)}`}>
      {status.charAt(0).toUpperCase() + status.slice(1)}
    </Badge>
  );
};