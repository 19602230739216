import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

interface PlatformStatusSelectProps {
  status: string;
  onStatusChange: (value: string) => void;
}

export const PlatformStatusSelect = ({ status, onStatusChange }: PlatformStatusSelectProps) => {
  return (
    <Select value={status} onValueChange={onStatusChange}>
      <SelectTrigger className="w-full bg-gray-800/50 border-gray-600/50 hover:bg-gray-800/70 transition-colors text-base">
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="pendiente" className="text-base">Pendiente</SelectItem>
        <SelectItem value="en_proceso" className="text-base">En Proceso</SelectItem>
        <SelectItem value="activo" className="text-base">Activo</SelectItem>
        <SelectItem value="inactivo" className="text-base">Inactivo</SelectItem>
        <SelectItem value="reclamada" className="text-base">Reclamada</SelectItem>
      </SelectContent>
    </Select>
  );
};