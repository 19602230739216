import { Card } from "@/components/ui/card";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";

const Settings = () => {
  const { data: currentUser } = useQuery({
    queryKey: ["currentUser"],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No user found");

      const { data: userData, error } = await supabase
        .from("users")
        .select("*")
        .eq("id", user.id)
        .single();

      if (error) throw error;
      return userData;
    },
  });

  return (
    <div className="container mx-auto p-8 pt-24">
      <h1 className="text-3xl font-bold mb-8">Configuración</h1>
      
      <div className="grid gap-6">
        <Card className="p-6">
          <h2 className="text-xl font-semibold mb-4">Perfil de Usuario</h2>
          <div className="space-y-4">
            <div>
              <label className="text-sm text-gray-500">Nombre Completo</label>
              <p className="font-medium">{currentUser?.full_name}</p>
            </div>
            <div>
              <label className="text-sm text-gray-500">Email</label>
              <p className="font-medium">{currentUser?.email}</p>
            </div>
            <div>
              <label className="text-sm text-gray-500">CDSID</label>
              <p className="font-medium">{currentUser?.cdsid}</p>
            </div>
            <div>
              <label className="text-sm text-gray-500">Rol</label>
              <p className="font-medium capitalize">{currentUser?.role}</p>
            </div>
            <div>
              <label className="text-sm text-gray-500">Departamento</label>
              <p className="font-medium">{currentUser?.department || 'No especificado'}</p>
            </div>
            <div>
              <label className="text-sm text-gray-500">Mercado</label>
              <p className="font-medium">{currentUser?.market || 'No especificado'}</p>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Settings;