import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { MapPin, Truck } from "lucide-react";

interface AddressFieldsProps {
  formData: {
    direccion_recogida: string;
    empresa_mensajeria: string;
  };
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const AddressFields = ({ formData, onChange }: AddressFieldsProps) => {
  return (
    <>
      <div className="space-y-2">
        <Label htmlFor="direccion_recogida" className="flex items-center gap-2">
          <MapPin className="h-4 w-4 text-blue-400" />
          Dirección de Recogida
        </Label>
        <Textarea
          id="direccion_recogida"
          value={formData.direccion_recogida}
          onChange={onChange}
          name="direccion_recogida"
          className="bg-gray-800/50 border-gray-700"
          required
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="empresa_mensajeria" className="flex items-center gap-2">
          <Truck className="h-4 w-4 text-blue-400" />
          Compañía de Mensajería
        </Label>
        <Input
          id="empresa_mensajeria"
          value={formData.empresa_mensajeria}
          onChange={onChange}
          name="empresa_mensajeria"
          className="bg-gray-800/50 border-gray-700"
          required
        />
      </div>
    </>
  );
};