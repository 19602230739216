
import { motion } from "framer-motion";

interface TicketsListProps {
  tickets: any[];
  isLoading: boolean;
}

export const TicketsList = ({ tickets, isLoading }: TicketsListProps) => {
  if (isLoading) {
    return (
      <div className="text-center text-gray-400 py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-400 mx-auto mb-2" />
        Cargando tickets...
      </div>
    );
  }

  if (tickets.length === 0) {
    return (
      <div className="text-center text-gray-500 py-8">
        No se encontraron tickets
      </div>
    );
  }

  return (
    <div className="space-y-2">
      {tickets.map((ticket) => (
        <motion.div 
          key={ticket.id} 
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.2 }}
          className="border border-gray-700 rounded-lg p-4 hover:bg-gray-800/50 transition-colors"
        >
          <div className="flex justify-between items-start">
            <div>
              <h3 className="font-medium">#{ticket.ticket_number} - {ticket.title}</h3>
              <p className="text-sm text-gray-400 mt-1">{ticket.description}</p>
            </div>
            <div className="flex items-center gap-2">
              <span className={`text-xs px-2 py-1 rounded-full ${
                ticket.status === 'open' ? 'bg-green-500/10 text-green-500' :
                ticket.status === 'in_progress' ? 'bg-blue-500/10 text-blue-500' :
                'bg-gray-500/10 text-gray-500'
              }`}>
                {ticket.status}
              </span>
              <span className={`text-xs px-2 py-1 rounded-full ${
                ticket.priority === 'high' ? 'bg-red-500/10 text-red-500' :
                ticket.priority === 'medium' ? 'bg-yellow-500/10 text-yellow-500' :
                'bg-green-500/10 text-green-500'
              }`}>
                {ticket.priority}
              </span>
            </div>
          </div>
          <div className="mt-2 text-xs text-gray-500">
            Creado: {new Date(ticket.created_at).toLocaleDateString()}
          </div>
        </motion.div>
      ))}
    </div>
  );
};
