import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { AssetCollection, AssetCollectionItem } from "@/integrations/supabase/types/tables";

interface AssetCollectionWithItems extends AssetCollection {
  items: AssetCollectionItem[];
}

export const useAssetCollections = () => {
  return useQuery<AssetCollectionWithItems[]>({
    queryKey: ["asset-collections"],
    queryFn: async () => {
      console.log('Fetching asset collections...');
      const { data: collectionsData, error: collectionsError } = await supabase
        .from("asset_collections")
        .select("*")
        .order('created_at', { ascending: false });

      if (collectionsError) {
        console.error('Error fetching collections:', collectionsError);
        toast.error("Error al cargar las recogidas de activos");
        throw collectionsError;
      }

      const collectionsWithItems = await Promise.all(
        collectionsData.map(async (collection) => {
          const { data: items, error: itemsError } = await supabase
            .from("asset_collection_items")
            .select("*")
            .eq("collection_id", collection.id);

          if (itemsError) {
            console.error('Error fetching items for collection:', itemsError);
            return { ...collection, items: [] };
          }

          return { ...collection, items: items || [] };
        })
      );

      console.log('Collections fetched:', collectionsWithItems?.length);
      return collectionsWithItems;
    },
  });
};