import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { Code2, Play } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

export const ScriptManager = () => {
  const [script, setScript] = useState({
    name: "",
    description: "",
    script_type: "js",
    content: "",
    is_active: true,
    execution_order: 0
  });

  const handleSave = async () => {
    try {
      const { error } = await supabase
        .from('portal_scripts')
        .insert({
          ...script,
          company_id: null // TODO: Add company context
        });

      if (error) throw error;
      toast.success("Script guardado correctamente");
      
      // Reset form
      setScript({
        name: "",
        description: "",
        script_type: "js",
        content: "",
        is_active: true,
        execution_order: 0
      });
    } catch (error) {
      console.error('Error saving script:', error);
      toast.error("Error al guardar el script");
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <Code2 className="h-5 w-5" />
          Gestor de Scripts
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="grid gap-4 md:grid-cols-2">
          <div className="space-y-2">
            <Label>Nombre del Script</Label>
            <Input
              value={script.name}
              onChange={(e) => setScript({ ...script, name: e.target.value })}
              placeholder="Mi Script"
            />
          </div>

          <div className="space-y-2">
            <Label>Tipo de Script</Label>
            <Select
              value={script.script_type}
              onValueChange={(value) => setScript({ ...script, script_type: value })}
            >
              <SelectTrigger>
                <SelectValue placeholder="Selecciona un tipo" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="js">JavaScript</SelectItem>
                <SelectItem value="css">CSS</SelectItem>
                <SelectItem value="html">HTML</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        <div className="space-y-2">
          <Label>Descripción</Label>
          <Input
            value={script.description}
            onChange={(e) => setScript({ ...script, description: e.target.value })}
            placeholder="Descripción del script..."
          />
        </div>

        <div className="space-y-2">
          <Label>Contenido</Label>
          <Textarea
            value={script.content}
            onChange={(e) => setScript({ ...script, content: e.target.value })}
            placeholder="// Tu código aquí..."
            className="font-mono min-h-[200px]"
          />
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <Switch
              checked={script.is_active}
              onCheckedChange={(checked) => setScript({ ...script, is_active: checked })}
            />
            <Label>Activo</Label>
          </div>

          <div className="space-x-2">
            <Button variant="outline" onClick={() => console.log('Execute script')}>
              <Play className="h-4 w-4 mr-2" />
              Ejecutar
            </Button>
            <Button onClick={handleSave}>Guardar Script</Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};