import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import { TicketStats } from "../types";

export const addPrioritySection = (doc: jsPDF, stats: TicketStats, startY: number): number => {
  const priorityData = [
    ["Urgente", stats.byPriority?.urgent?.toString() || "0"],
    ["Alta", stats.byPriority?.high?.toString() || "0"],
    ["Media", stats.byPriority?.medium?.toString() || "0"],
    ["Baja", stats.byPriority?.low?.toString() || "0"]
  ];

  doc.text("Distribución por Prioridad", 14, startY + 20);

  autoTable(doc, {
    startY: startY + 25,
    head: [["Prioridad", "Cantidad"]],
    body: priorityData,
    theme: 'grid',
    headStyles: {
      fillColor: [192, 57, 43],
      textColor: 255,
      fontSize: 12,
      fontStyle: 'bold'
    },
    styles: {
      fontSize: 10,
      cellPadding: 8,
      lineColor: [220, 220, 220]
    },
    columnStyles: {
      0: { cellWidth: 100 },
      1: { cellWidth: 40, halign: 'center' }
    },
    alternateRowStyles: {
      fillColor: [245, 247, 250]
    }
  });

  // @ts-ignore - lastAutoTable is added by jspdf-autotable
  return doc.lastAutoTable?.finalY || startY + 100;
};