import { useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { Mail, Plus, Loader2 } from "lucide-react";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";

interface CommentDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSubmit: (comment: string, emails: string[]) => Promise<void>;
  title: string;
  issueId: string;
}

export const CommentDialog = ({ open, onOpenChange, onSubmit, title, issueId }: CommentDialogProps) => {
  const [comment, setComment] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAddEmail = () => {
    if (newEmail && !selectedEmails.includes(newEmail)) {
      setSelectedEmails([...selectedEmails, newEmail]);
      setNewEmail("");
    }
  };

  const handleRemoveEmail = (email: string) => {
    setSelectedEmails(selectedEmails.filter(e => e !== email));
  };

  const saveComment = async (comment: string) => {
    const { error } = await supabase
      .from('pending_issue_comments')
      .insert({
        issue_id: issueId,
        content: comment,
        author: 'System',
        is_internal: false
      });

    if (error) {
      console.error('Error saving comment:', error);
      throw error;
    }
  };

  const handleSubmit = async () => {
    if (!comment.trim()) {
      toast.error("Por favor, escriba un comentario");
      return;
    }

    setIsSubmitting(true);
    try {
      // Primero guardamos el comentario
      await saveComment(comment);
      
      // Luego enviamos las notificaciones por email
      await onSubmit(comment, selectedEmails);
      
      setComment("");
      setSelectedEmails([]);
      onOpenChange(false);
      toast.success("Comentario guardado y notificaciones enviadas");
    } catch (error) {
      console.error('Error al procesar el comentario:', error);
      toast.error("Error al procesar el comentario");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="bg-gray-800/95 backdrop-blur-md border-gray-700">
        <DialogHeader>
          <DialogTitle className="text-lg font-semibold text-white">
            Añadir Comentario - {title}
          </DialogTitle>
        </DialogHeader>

        <div className="space-y-4">
          <Textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Escribe tu comentario..."
            className="bg-gray-700/50 border-gray-600 min-h-[100px]"
          />

          <div className="space-y-2">
            <label className="text-sm text-gray-300">Notificar por email (opcional)</label>
            <div className="flex gap-2">
              <Input
                type="email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                placeholder="Añadir dirección de email"
                className="flex-1 bg-gray-700/50 border-gray-600"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleAddEmail();
                  }
                }}
              />
              <Button 
                type="button" 
                variant="outline"
                onClick={handleAddEmail}
                className="bg-gray-700/50 border-gray-600"
              >
                <Plus className="w-4 h-4 mr-2" />
                Añadir
              </Button>
            </div>

            {selectedEmails.length > 0 && (
              <div className="space-y-2">
                {selectedEmails.map((email) => (
                  <div key={email} className="flex items-center justify-between bg-gray-700/50 p-2 rounded">
                    <span className="text-sm text-gray-300">{email}</span>
                    <Button
                      type="button"
                      variant="ghost"
                      size="sm"
                      onClick={() => handleRemoveEmail(email)}
                      className="hover:bg-gray-600"
                    >
                      ×
                    </Button>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="flex justify-end gap-2 mt-4">
            <Button
              variant="outline"
              onClick={() => onOpenChange(false)}
              className="bg-gray-700/50 border-gray-600"
            >
              Cancelar
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={isSubmitting || !comment.trim()}
              className="bg-blue-600 hover:bg-blue-700"
            >
              {isSubmitting ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Enviando...
                </>
              ) : (
                <>
                  <Mail className="mr-2 h-4 w-4" />
                  Guardar y Notificar
                </>
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};