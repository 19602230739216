import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Upload } from "lucide-react";
import { ImportEmpleadosDialog } from "./ImportEmpleadosDialog";

interface ImportEmpleadosButtonProps {
  onImportComplete: () => void;
}

export const ImportEmpleadosButton = ({ onImportComplete }: ImportEmpleadosButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setIsOpen(true)} className="gap-2">
        <Upload className="h-4 w-4" />
        Importar Empleados
      </Button>

      <ImportEmpleadosDialog
        open={isOpen}
        onOpenChange={setIsOpen}
        onImportComplete={() => {
          onImportComplete();
          setIsOpen(false);
        }}
      />
    </>
  );
};