import { Button } from "@/components/ui/button";
import { FileDown, FileSpreadsheet, BarChart2 } from "lucide-react";
import { toast } from "sonner";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { Ticket } from "@/hooks/useTickets";

interface TicketExportOptionsProps {
  tickets: Ticket[];
}

export const TicketExportOptions = ({ tickets }: TicketExportOptionsProps) => {
  const exportToPDF = () => {
    try {
      const doc = new jsPDF();
      
      doc.setFontSize(16);
      doc.text("Reporte de Tickets", 14, 20);
      
      doc.setFontSize(10);
      doc.text(`Generado el ${new Date().toLocaleString()}`, 14, 30);
      
      const tableData = tickets.map(ticket => [
        ticket.ticket_number,
        ticket.title,
        ticket.submitter_name || 'N/A',
        ticket.status === 'open' ? 'Abierto' : 
        ticket.status === 'in_progress' ? 'En Progreso' : 
        'Cerrado',
        ticket.priority === 'urgent' ? 'Urgente' :
        ticket.priority === 'high' ? 'Alta' :
        ticket.priority === 'medium' ? 'Media' : 'Baja',
        new Date(ticket.created_at).toLocaleDateString(),
        ticket.category
      ]);
      
      autoTable(doc, {
        head: [['#', 'Título', 'Creado por', 'Estado', 'Prioridad', 'Fecha', 'Categoría']],
        body: tableData,
        startY: 40,
        styles: { fontSize: 8 },
        headStyles: { fillColor: [59, 130, 246] }
      });
      
      doc.save('tickets-report.pdf');
      toast.success("PDF exportado correctamente");
    } catch (error) {
      console.error('Error exporting PDF:', error);
      toast.error("Error al exportar PDF");
    }
  };

  const exportToExcel = () => {
    try {
      const data = tickets.map(ticket => ({
        'Número': ticket.ticket_number,
        'Título': ticket.title,
        'Creado por': ticket.submitter_name || 'N/A',
        'Estado': ticket.status === 'open' ? 'Abierto' : 
                 ticket.status === 'in_progress' ? 'En Progreso' : 
                 'Cerrado',
        'Prioridad': ticket.priority === 'urgent' ? 'Urgente' :
                    ticket.priority === 'high' ? 'Alta' :
                    ticket.priority === 'medium' ? 'Media' : 'Baja',
        'Fecha': new Date(ticket.created_at).toLocaleDateString(),
        'Categoría': ticket.category,
        'Descripción': ticket.description
      }));

      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Tickets");
      XLSX.writeFile(wb, 'tickets-report.xlsx');
      
      toast.success("Excel exportado correctamente");
    } catch (error) {
      console.error('Error exporting Excel:', error);
      toast.error("Error al exportar Excel");
    }
  };

  return (
    <div className="flex gap-2">
      <Button
        variant="outline"
        size="sm"
        onClick={exportToPDF}
        className="gap-2"
      >
        <FileDown className="w-4 h-4" />
        PDF
      </Button>
      <Button
        variant="outline"
        size="sm"
        onClick={exportToExcel}
        className="gap-2"
      >
        <FileSpreadsheet className="w-4 h-4" />
        Excel
      </Button>
    </div>
  );
};