import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { AssetCollectionFormData } from "../types/assetCollection";

export const useAssetCollectionActions = (
  id: string | undefined,
  formData: AssetCollectionFormData,
  additionalEmails: string[],
  setIsSubmitting: (value: boolean) => void,
  loadCollection: (id: string) => Promise<void>
) => {
  const handleStatusChange = async (newStatus: string) => {
    if (!id) return;

    setIsSubmitting(true);
    try {
      const { error } = await supabase
        .from('asset_collections')
        .update({ estado: newStatus })
        .eq('id', id);

      if (error) throw error;

      if (additionalEmails.length > 0) {
        await sendStatusUpdateEmail(newStatus);
      }

      toast.success(`Estado actualizado a: ${newStatus}`);
      await loadCollection(id);
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error("Error al actualizar el estado");
    } finally {
      setIsSubmitting(false);
    }
  };

  const sendStatusUpdateEmail = async (status: string) => {
    try {
      console.log('Sending status update email to:', additionalEmails);
      const { error: emailError } = await supabase.functions.invoke('notify-asset-collection', {
        body: {
          collection: {
            ...formData,
            estado: status
          },
          additionalEmails,
          isStatusUpdate: true
        }
      });

      if (emailError) {
        console.error('Error sending notification:', emailError);
        toast.error("Error al enviar las notificaciones");
      }
    } catch (error) {
      console.error('Error sending status update email:', error);
    }
  };

  const handleManualEmailSend = async () => {
    setIsSubmitting(true);
    try {
      console.log('Sending manual email to:', additionalEmails);
      const { error: emailError } = await supabase.functions.invoke('notify-asset-collection', {
        body: {
          collection: formData,
          additionalEmails,
          isManualSend: true
        }
      });

      if (emailError) throw emailError;
      toast.success("Notificación enviada correctamente");
    } catch (error) {
      console.error('Error sending manual notification:', error);
      toast.error("Error al enviar la notificación");
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    handleStatusChange,
    handleManualEmailSend
  };
};