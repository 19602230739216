
import { AlertCircle } from "lucide-react";
import {
  DropdownMenuSeparator,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";

export const EmpleadoStatusIndicator = () => {
  return (
    <>
      <DropdownMenuSeparator />
      <DropdownMenuItem className="text-orange-500" disabled>
        <AlertCircle className="h-4 w-4 mr-2" />
        Empleado Inactivo
      </DropdownMenuItem>
    </>
  );
};
