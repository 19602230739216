
import { useState } from "react";
import { useEmpleados } from "@/hooks/useEmpleados";
import { EmpleadosList } from "@/components/empleados/EmpleadosList";
import { EmpleadoToolbar } from "@/components/empleados/EmpleadoToolbar";
import { EmpleadoStats } from "@/components/empleados/EmpleadoStats";
import { EmpleadoMarketSection } from "@/components/empleados/EmpleadoMarketSection";
import { EmpleadoDetailsDialog } from "@/components/empleados/EmpleadoDetailsDialog";
import { EmpleadosSkeleton } from "@/components/empleados/EmpleadosSkeleton";
import { EmpleadosError } from "@/components/empleados/EmpleadosError";
import { Users } from "lucide-react";
import { motion } from "framer-motion";
import { Empleado } from "@/integrations/supabase/types/tables";
import { useQuery } from "@tanstack/react-query";

const EmpleadosView = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  
  const { 
    data: empleados = [], 
    isLoading, 
    isError,
    error,
    refetch 
  } = useEmpleados();

  const filteredEmpleados = useQuery({
    queryKey: ["empleados-filtered", searchTerm, empleados],
    queryFn: () => {
      return empleados.filter(
        (emp) =>
          emp.nombre?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          emp.cdsid?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          emp.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          emp.mercado?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          emp.apellidos?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          emp.posicion?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    },
    enabled: !isLoading && !isError,
  });

  const empleadosByMercado = useQuery({
    queryKey: ["empleados-by-mercado", filteredEmpleados.data],
    queryFn: () => {
      return (filteredEmpleados.data || []).reduce((acc, emp) => {
        const mercado = emp.mercado || "Sin Mercado";
        if (!acc[mercado]) {
          acc[mercado] = [];
        }
        acc[mercado].push(emp);
        return acc;
      }, {} as Record<string, Empleado[]>);
    },
    enabled: !filteredEmpleados.isLoading && !filteredEmpleados.isError,
  });

  if (isError) {
    return (
      <div className="container mx-auto p-8">
        <EmpleadosError onRetry={() => refetch()} />
      </div>
    );
  }

  return (
    <div className="container mx-auto p-8 space-y-8">
      <motion.div 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-lg shadow-xl p-6 mb-8"
      >
        <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
          <div className="flex items-center gap-3">
            <div className="bg-blue-500/10 p-3 rounded-lg">
              <Users className="h-6 w-6 text-blue-400" />
            </div>
            <div>
              <h1 className="text-2xl font-bold text-white">Empleados Ford</h1>
              <p className="text-gray-400 text-sm">
                Gestión de empleados y recursos humanos
              </p>
            </div>
          </div>
        </div>

        <EmpleadoStats empleados={empleados} />
        
        <EmpleadoToolbar 
          viewMode={viewMode}
          setViewMode={setViewMode}
          searchTerm={searchTerm}
          onSearchChange={setSearchTerm}
          empleados={filteredEmpleados.data || []}
          onShowCreateDialog={() => setShowCreateDialog(true)}
          onImportComplete={() => refetch()}
        />
      </motion.div>

      {isLoading ? (
        <EmpleadosSkeleton />
      ) : viewMode === 'list' ? (
        <EmpleadosList empleados={filteredEmpleados.data || []} />
      ) : (
        <div className="space-y-8">
          {Object.entries(empleadosByMercado.data || {}).map(([mercado, empleados]) => (
            <EmpleadoMarketSection
              key={mercado}
              mercado={mercado}
              empleados={empleados}
              onUpdate={refetch}
            />
          ))}
        </div>
      )}

      <EmpleadoDetailsDialog
        empleado={null}
        open={showCreateDialog}
        onOpenChange={setShowCreateDialog}
        onUpdate={refetch}
      />
    </div>
  );
};

export default EmpleadosView;
