import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Download, Upload } from "lucide-react";
import { Input } from "@/components/ui/input";

interface ExportFormProps {
  isLoading: boolean;
  onExport: () => Promise<void>;
  onImport: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
}

export const ExportForm = ({ isLoading, onExport, onImport }: ExportFormProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Importar/Exportar Configuración</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="flex gap-4">
          <Button
            variant="outline"
            onClick={onExport}
            disabled={isLoading}
            className="flex-1"
          >
            <Download className="w-4 h-4 mr-2" />
            Exportar Configuración
          </Button>

          <div className="flex-1">
            <Input
              type="file"
              accept=".json"
              onChange={onImport}
              className="hidden"
              id="config-import"
            />
            <Button
              variant="outline"
              onClick={() => document.getElementById('config-import')?.click()}
              disabled={isLoading}
              className="w-full"
            >
              <Upload className="w-4 h-4 mr-2" />
              Importar Configuración
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};