import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Equipment } from "@/types/inventory";
import { toast } from "sonner";

interface UseInventoryDataOptions {
  staleTime?: number;
  enabled?: boolean;
}

export const useInventoryData = (options: UseInventoryDataOptions = {}) => {
  return useQuery({
    queryKey: ["inventory"],
    queryFn: async () => {
      console.log('Fetching inventory data...');
      const { data, error } = await supabase
        .from("piezas")
        .select("*")
        .order("created_at", { ascending: false });

      if (error) {
        console.error('Error fetching inventory:', error);
        toast.error("Error al cargar el inventario");
        throw error;
      }

      console.log('Inventory data fetched:', data?.length, 'items');
      return data as Equipment[];
    },
    staleTime: options.staleTime,
    enabled: options.enabled !== false,
    meta: {
      onError: (error: unknown) => {
        console.error('Query error:', error);
        toast.error("Error al cargar el inventario");
      }
    }
  });
};