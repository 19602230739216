import { useNavigate, useParams } from "react-router-dom";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { ArrowLeft, Loader2, Mail } from "lucide-react";
import { PersonalInfoFields } from "./form/PersonalInfoFields";
import { DateFields } from "./form/DateFields";
import { AddressFields } from "./form/AddressFields";
import { EmailNotificationFields } from "./form/EmailNotificationFields";
import { StatusUpdateSection } from "./form/StatusUpdateSection";
import { AlertDialog } from "./form/AlertDialog";
import { AssetCollectionItems } from "./form/AssetCollectionItems";
import { useAssetCollection } from "./hooks/useAssetCollection";
import { useState } from "react";

export const AssetCollectionForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showEmailDialog, setShowEmailDialog] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [otherItems, setOtherItems] = useState("");
  
  const {
    formData,
    setFormData,
    isSubmitting,
    sendEmail,
    setSendEmail,
    additionalEmails,
    setAdditionalEmails,
    newEmail,
    setNewEmail,
    handleStatusChange,
    handleManualEmailSend,
    handleSubmit
  } = useAssetCollection(id);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const success = await handleSubmit(e, selectedItems, otherItems);
    if (success) {
      navigate("/asset-collections");
    }
  };

  return (
    <Card className="p-6 max-w-2xl mx-auto bg-gray-900/40 backdrop-blur-lg border-gray-800/50">
      <div className="mb-6 flex justify-between items-center">
        <Button
          variant="ghost"
          onClick={() => navigate("/asset-collections")}
          className="text-gray-400 hover:text-white"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Volver a Recogidas
        </Button>

        {id && (
          <Button
            variant="outline"
            onClick={() => setShowEmailDialog(true)}
            className="bg-gray-800/50 border-gray-700 hover:bg-gray-700"
          >
            <Mail className="w-4 h-4 mr-2" />
            Enviar Alerta
          </Button>
        )}
      </div>

      <form onSubmit={onSubmit} className="space-y-6">
        {id && (
          <div className="space-y-4">
            <Label>Estado de la Recogida</Label>
            <StatusUpdateSection
              currentStatus={formData.estado}
              isSubmitting={isSubmitting}
              onStatusChange={handleStatusChange}
              onManualEmailSend={handleManualEmailSend}
            />
          </div>
        )}

        <PersonalInfoFields formData={formData} onChange={handleChange} />
        <DateFields formData={formData} onChange={handleChange} />
        <AddressFields formData={formData} onChange={handleChange} />

        <AssetCollectionItems
          selectedItems={selectedItems}
          onItemChange={setSelectedItems}
          otherItems={otherItems}
          onOtherItemsChange={setOtherItems}
        />

        <div className="space-y-2">
          <Label htmlFor="motivo_baja">Motivo de Baja</Label>
          <Textarea
            id="motivo_baja"
            name="motivo_baja"
            value={formData.motivo_baja}
            onChange={handleChange}
            className="bg-gray-800/50 border-gray-700"
            placeholder="Detalles sobre el motivo de la baja..."
          />
        </div>

        <EmailNotificationFields
          sendEmail={sendEmail}
          onSendEmailChange={setSendEmail}
          additionalEmails={additionalEmails}
          newEmail={newEmail}
          onNewEmailChange={(e) => setNewEmail(e.target.value)}
          onAddEmail={() => {
            if (newEmail && !additionalEmails.includes(newEmail)) {
              setAdditionalEmails([...additionalEmails, newEmail]);
              setNewEmail("");
            }
          }}
          onRemoveEmail={(email) => {
            setAdditionalEmails(additionalEmails.filter(e => e !== email));
          }}
        />

        <Button 
          type="submit" 
          className="w-full bg-blue-600 hover:bg-blue-700"
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              {id ? "Actualizando..." : "Registrando..."}
            </>
          ) : (
            id ? "Actualizar Recogida" : "Registrar Recogida"
          )}
        </Button>
      </form>

      <AlertDialog 
        open={showEmailDialog}
        onOpenChange={setShowEmailDialog}
        formData={formData}
      />
    </Card>
  );
};