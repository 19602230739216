import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

interface IssueFormFieldsProps {
  formData: {
    title: string;
    description: string;
    reporter: string;
    affected_users: string;
    market: string;
  };
  setFormData: (data: any) => void;
}

export const IssueFormFields = ({ formData, setFormData }: IssueFormFieldsProps) => {
  return (
    <>
      <div className="space-y-2">
        <Label>Título</Label>
        <Input
          value={formData.title}
          onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          className="bg-gray-700/50 border-gray-600"
          placeholder="Resumen breve del asunto"
          required
        />
      </div>

      <div className="space-y-2">
        <Label>Descripción</Label>
        <Textarea
          value={formData.description}
          onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          className="bg-gray-700/50 border-gray-600 min-h-[150px]"
          placeholder="Describe el problema en detalle..."
          required
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="space-y-2">
          <Label>Reportado por</Label>
          <Input
            value={formData.reporter}
            onChange={(e) => setFormData({ ...formData, reporter: e.target.value })}
            className="bg-gray-700/50 border-gray-600"
            placeholder="Tu nombre"
            required
          />
        </div>

        <div className="space-y-2">
          <Label>CDSIDs Afectados</Label>
          <Input
            value={formData.affected_users}
            onChange={(e) => setFormData({ ...formData, affected_users: e.target.value })}
            className="bg-gray-700/50 border-gray-600"
            placeholder="Lista de CDSIDs afectados"
            required
          />
        </div>
      </div>

      <div className="space-y-2">
        <Label>Mercado</Label>
        <Select
          value={formData.market}
          onValueChange={(value) => setFormData({ ...formData, market: value })}
        >
          <SelectTrigger className="bg-gray-700/50 border-gray-600">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="iberia">IBERIA</SelectItem>
            <SelectItem value="italia">Italia</SelectItem>
            <SelectItem value="portugal">Portugal</SelectItem>
            <SelectItem value="francia">Francia</SelectItem>
          </SelectContent>
        </Select>
      </div>
    </>
  );
};