import { AlertCircle, AlertTriangle, AlertOctagon } from "lucide-react";
import { Badge } from "@/components/ui/badge";

type AlertSeverity = "low" | "medium" | "high" | "critical";

interface ServiceAlertBadgeProps {
  severity: AlertSeverity;
}

const severityConfig = {
  low: {
    icon: AlertCircle,
    className: "bg-blue-500/20 text-blue-400 border-blue-500/30 hover:bg-blue-500/30",
  },
  medium: {
    icon: AlertCircle,
    className: "bg-yellow-500/20 text-yellow-400 border-yellow-500/30 hover:bg-yellow-500/30",
  },
  high: {
    icon: AlertTriangle,
    className: "bg-orange-500/20 text-orange-400 border-orange-500/30 hover:bg-orange-500/30",
  },
  critical: {
    icon: AlertOctagon,
    className: "bg-red-500/20 text-red-400 border-red-500/30 hover:bg-red-500/30",
  },
};

export const ServiceAlertBadge = ({ severity }: ServiceAlertBadgeProps) => {
  const config = severityConfig[severity];
  const Icon = config.icon;

  return (
    <Badge 
      variant="outline" 
      className={`${config.className} gap-1 backdrop-blur-sm transition-colors duration-300 font-medium`}
    >
      <Icon className="w-3 h-3" />
      {severity.charAt(0).toUpperCase() + severity.slice(1)}
    </Badge>
  );
};