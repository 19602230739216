import { AlertCircle, AlertTriangle, AlertOctagon } from "lucide-react";

type AlertSeverity = "low" | "medium" | "high" | "critical";

interface AlertSeverityIconProps {
  severity: AlertSeverity;
  className?: string;
}

export const AlertSeverityIcon = ({ severity, className = "w-4 h-4" }: AlertSeverityIconProps) => {
  switch (severity) {
    case "low":
      return <AlertCircle className={`text-blue-400 ${className}`} />;
    case "medium":
      return <AlertTriangle className={`text-yellow-400 ${className}`} />;
    case "high":
      return <AlertOctagon className={`text-orange-400 ${className}`} />;
    case "critical":
      return <AlertOctagon className={`text-red-400 ${className}`} />;
    default:
      return <AlertCircle className={`text-gray-400 ${className}`} />;
  }
};