import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

interface ServiceAlert {
  id: string;
  title: string;
  description: string;
  severity: "low" | "medium" | "high" | "critical";
  type: string;
  affected_services: string[];
}

interface AlertEditDialogProps {
  isOpen: boolean;
  onClose: () => void;
  alert: ServiceAlert;
}

export const AlertEditDialog = ({ isOpen, onClose, alert }: AlertEditDialogProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    title: alert.title,
    description: alert.description,
    severity: alert.severity,
    type: alert.type,
    affected_services: alert.affected_services,
  });

  const handleSave = async () => {
    setIsSubmitting(true);
    try {
      const { error } = await supabase
        .from('service_alerts')
        .update(formData)
        .eq('id', alert.id);

      if (error) throw error;

      toast.success("Alerta actualizada correctamente");
      onClose();
    } catch (error) {
      console.error('Error updating alert:', error);
      toast.error("Error al actualizar la alerta");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Editar Alerta de Servicio</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <div>
            <label className="text-sm font-medium text-gray-300">Título</label>
            <Input
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              className="bg-gray-800 border-gray-700 text-gray-200"
            />
          </div>
          <div>
            <label className="text-sm font-medium text-gray-300">Descripción</label>
            <Textarea
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              className="bg-gray-800 border-gray-700 text-gray-200"
            />
          </div>
          <div>
            <label className="text-sm font-medium text-gray-300">Severidad</label>
            <Select
              value={formData.severity}
              onValueChange={(value: "low" | "medium" | "high" | "critical") => 
                setFormData({ ...formData, severity: value })}
            >
              <SelectTrigger className="bg-gray-800 border-gray-700 text-gray-200">
                <SelectValue />
              </SelectTrigger>
              <SelectContent className="bg-gray-800 border-gray-700">
                <SelectItem value="low" className="text-gray-200">Baja</SelectItem>
                <SelectItem value="medium" className="text-gray-200">Media</SelectItem>
                <SelectItem value="high" className="text-gray-200">Alta</SelectItem>
                <SelectItem value="critical" className="text-gray-200">Crítica</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="flex justify-end gap-2">
            <Button 
              variant="outline" 
              onClick={onClose}
              className="bg-gray-800 hover:bg-gray-700 text-gray-300 border-gray-700"
            >
              Cancelar
            </Button>
            <Button 
              onClick={handleSave}
              disabled={isSubmitting}
              className="bg-blue-600 hover:bg-blue-700 text-white"
            >
              {isSubmitting ? "Guardando..." : "Guardar Cambios"}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};