export const getStatusColor = (status: string) => {
  switch (status) {
    case 'active':
    case 'disponible':
      return 'bg-green-500/10 text-green-500 dark:bg-green-500/20 dark:text-green-400';
    case 'inactive':
    case 'asignado':
      return 'bg-blue-500/10 text-blue-500 dark:bg-blue-500/20 dark:text-blue-400';
    case 'pending':
    case 'mantenimiento':
      return 'bg-yellow-500/10 text-yellow-500 dark:bg-yellow-500/20 dark:text-yellow-400';
    case 'error':
    case 'baja':
      return 'bg-red-500/10 text-red-500 dark:bg-red-500/20 dark:text-red-400';
    default:
      return 'bg-gray-500/10 text-gray-500 dark:bg-gray-400/20 dark:text-gray-400';
  }
};