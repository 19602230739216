import { motion } from "framer-motion";
import { Card } from "@/components/ui/card";
import { useState } from "react";
import { AlertHeader } from "./card/AlertHeader";
import { AlertActions } from "./card/AlertActions";
import { AlertReportSection } from "./card/AlertReportSection";
import { AlertEditDialog } from "./card/AlertEditDialog";
import { AlertUsersDialog } from "./card/AlertUsersDialog";
import { AlertSeverityIcon } from "./card/AlertSeverityIcon";
import { useAlertAffectedUsers } from "@/hooks/useAlertAffectedUsers";

interface ServiceAlert {
  id: string;
  title: string;
  description: string;
  severity: "low" | "medium" | "high" | "critical";
  type: string;
  affected_services: string[];
}

interface ServiceAlertCardProps {
  alert: ServiceAlert;
  isLoginPage?: boolean;
  currentUserCDSID?: string;
  onDelete?: () => void;
}

export const ServiceAlertCard = ({ 
  alert, 
  isLoginPage = false, 
  currentUserCDSID, 
  onDelete 
}: ServiceAlertCardProps) => {
  const [cdsid, setCdsid] = useState(currentUserCDSID || '');
  const [isEditing, setIsEditing] = useState(false);
  const [showAffectedUsers, setShowAffectedUsers] = useState(false);

  const {
    isReporting,
    hasReported,
    affectedUsersCount,
    affectedUsers,
    reportAsAffected,
    fetchAffectedUsers
  } = useAlertAffectedUsers(alert.id, currentUserCDSID);

  const handleShowUsers = async () => {
    const users = await fetchAffectedUsers();
    if (users.length > 0) {
      setShowAffectedUsers(true);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className={isLoginPage ? 'relative z-10' : ''}
    >
      <Card className={`
        relative overflow-hidden backdrop-blur-lg border border-white/10
        ${isLoginPage ? 'bg-gray-900/40 hover:bg-gray-900/50' : ''}
        transform hover:scale-[1.02] transition-all duration-300
      `}>
        <div className="absolute inset-0 bg-gradient-to-br from-white/5 to-white/0 pointer-events-none" />
        <div className="relative p-6">
          <div className="flex items-start justify-between gap-4">
            <div className="flex items-start gap-3">
              <div className="mt-1">
                <AlertSeverityIcon severity={alert.severity} className="w-5 h-5" />
              </div>
              <AlertHeader
                title={alert.title}
                description={alert.description}
                severity={alert.severity}
                affectedServices={alert.affected_services}
              />
            </div>

            <div className="flex flex-col items-end gap-2">
              <AlertActions
                isLoginPage={isLoginPage}
                affectedUsersCount={affectedUsersCount}
                onEdit={() => setIsEditing(true)}
                onDelete={onDelete}
                onShowUsers={handleShowUsers}
              />
              
              <AlertReportSection
                isLoginPage={isLoginPage}
                hasReported={hasReported}
                isReporting={isReporting}
                cdsid={cdsid}
                onCdsidChange={setCdsid}
                onReport={() => reportAsAffected(cdsid)}
              />
            </div>
          </div>
        </div>
      </Card>

      <AlertEditDialog
        isOpen={isEditing}
        onClose={() => setIsEditing(false)}
        alert={alert}
      />

      <AlertUsersDialog
        isOpen={showAffectedUsers}
        onClose={() => setShowAffectedUsers(false)}
        affectedUsers={affectedUsers}
      />
    </motion.div>
  );
};