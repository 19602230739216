import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { AdminLogs } from "@/components/admin/AdminLogs";
import { AdminUsers } from "@/components/admin/AdminUsers";
import { CompanySettings } from "@/components/admin/CompanySettings";
import { PortalConfig } from "@/components/admin/PortalConfig";
import { NewHiresSection } from "@/components/admin/new-hires/NewHiresSection";
import { supabase } from "@/integrations/supabase/client";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Settings2, Users, ScrollText, Building2, Paintbrush, UserPlus } from "lucide-react";
import { toast } from "sonner";

const AdminPanel = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [credentials, setCredentials] = useState({ username: "", password: "" });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        console.log('No session found, redirecting to login');
        navigate("/login");
      }
    };

    checkAuth();
  }, [navigate]);

  const handleAdminLogin = (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    if (credentials.username === 'ivan' && credentials.password === 'jajaja') {
      setIsAuthenticated(true);
      toast.success("Acceso concedido al panel de administración");
    } else {
      toast.error("Credenciales incorrectas");
    }
    
    setIsLoading(false);
  };

  if (!isAuthenticated) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-gray-100 p-8 flex items-center justify-center">
        <Card className="w-full max-w-md bg-gray-800/50 border-gray-700">
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <Settings2 className="h-5 w-5" />
              Acceso al Panel de Administración
            </CardTitle>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleAdminLogin} className="space-y-4">
              <div className="space-y-2">
                <label className="text-sm font-medium">Usuario</label>
                <Input
                  type="text"
                  value={credentials.username}
                  onChange={(e) => setCredentials({ ...credentials, username: e.target.value })}
                  className="bg-gray-700/50 border-gray-600"
                  required
                />
              </div>
              <div className="space-y-2">
                <label className="text-sm font-medium">Contraseña</label>
                <Input
                  type="password"
                  value={credentials.password}
                  onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
                  className="bg-gray-700/50 border-gray-600"
                  required
                />
              </div>
              <Button type="submit" className="w-full" disabled={isLoading}>
                {isLoading ? "Verificando..." : "Acceder"}
              </Button>
            </form>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-gray-100 p-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold mb-8 flex items-center gap-2">
          <Settings2 className="h-7 w-7" />
          Panel de Administración
        </h1>

        <Tabs defaultValue="users" className="space-y-6">
          <TabsList className="bg-gray-800/50 border border-gray-700">
            <TabsTrigger value="users" className="flex items-center gap-2">
              <Users className="h-4 w-4" />
              Gestión de Usuarios
            </TabsTrigger>
            <TabsTrigger value="new-hires" className="flex items-center gap-2">
              <UserPlus className="h-4 w-4" />
              Nuevas Incorporaciones
            </TabsTrigger>
            <TabsTrigger value="logs" className="flex items-center gap-2">
              <ScrollText className="h-4 w-4" />
              Logs del Sistema
            </TabsTrigger>
            <TabsTrigger value="companies" className="flex items-center gap-2">
              <Building2 className="h-4 w-4" />
              Configuración Multi-empresa
            </TabsTrigger>
            <TabsTrigger value="portal" className="flex items-center gap-2">
              <Paintbrush className="h-4 w-4" />
              Configuración del Portal
            </TabsTrigger>
          </TabsList>

          <TabsContent value="users" className="space-y-4">
            <AdminUsers />
          </TabsContent>

          <TabsContent value="new-hires" className="space-y-4">
            <NewHiresSection />
          </TabsContent>

          <TabsContent value="logs" className="space-y-4">
            <AdminLogs />
          </TabsContent>

          <TabsContent value="companies" className="space-y-4">
            <CompanySettings />
          </TabsContent>

          <TabsContent value="portal" className="space-y-4">
            <PortalConfig />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export default AdminPanel;
