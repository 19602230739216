import { Check, Clock, AlertCircle, AlertTriangle } from "lucide-react";
import { Badge } from "@/components/ui/badge";

interface PlatformStatusBadgeProps {
  status: string;
}

export const PlatformStatusBadge = ({ status }: PlatformStatusBadgeProps) => {
  const getStatusConfig = (status: string) => {
    switch (status) {
      case 'activo':
        return {
          icon: <Check className="h-3 w-3" />,
          className: "bg-green-500/20 text-green-400 border-green-500/30"
        };
      case 'en_proceso':
        return {
          icon: <Clock className="h-3 w-3" />,
          className: "bg-blue-500/20 text-blue-400 border-blue-500/30"
        };
      case 'reclamada':
        return {
          icon: <AlertTriangle className="h-3 w-3" />,
          className: "bg-orange-500/20 text-orange-400 border-orange-500/30"
        };
      case 'inactivo':
        return {
          icon: <AlertCircle className="h-3 w-3" />,
          className: "bg-red-500/20 text-red-400 border-red-500/30"
        };
      default:
        return {
          icon: <Clock className="h-3 w-3" />,
          className: "bg-yellow-500/20 text-yellow-400 border-yellow-500/30"
        };
    }
  };

  const config = getStatusConfig(status);

  return (
    <Badge 
      variant="outline" 
      className={`flex items-center gap-1 px-2 py-0.5 text-xs font-medium ${config.className}`}
    >
      {config.icon}
      <span className="capitalize">
        {status.replace('_', ' ')}
      </span>
    </Badge>
  );
};