
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Paintbrush } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

export const ThemeCustomizer = () => {
  const [theme, setTheme] = useState({
    name: "",
    description: "",
    colors: {
      primary: "#0052CC",
      secondary: "#F4F5F7",
      accent: "#36B37E",
      background: "#FFFFFF",
      text: "#172B4D",
      error: "#FF5630",
      warning: "#FFAB00",
      success: "#36B37E"
    },
    typography: {
      fontFamily: "Inter",
      headings: {
        fontFamily: "Inter",
        weight: "600"
      },
      body: {
        fontFamily: "Inter",
        weight: "400"
      }
    }
  });

  const handleSave = async () => {
    try {
      const { error } = await supabase
        .from('portal_themes')
        .insert({
          ...theme,
          company_id: null // TODO: Add company context
        });

      if (error) throw error;
      toast.success("Tema guardado correctamente");
    } catch (error) {
      console.error('Error saving theme:', error);
      toast.error("Error al guardar el tema");
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <Paintbrush className="h-5 w-5" />
          Personalización de Tema
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className="grid gap-4 md:grid-cols-2">
          <div className="space-y-2">
            <Label>Nombre del Tema</Label>
            <Input
              value={theme.name}
              onChange={(e) => setTheme({ ...theme, name: e.target.value })}
              placeholder="Mi Tema"
            />
          </div>

          <div className="space-y-2">
            <Label>Descripción</Label>
            <Input
              value={theme.description}
              onChange={(e) => setTheme({ ...theme, description: e.target.value })}
              placeholder="Descripción del tema..."
            />
          </div>
        </div>

        <div className="space-y-4">
          <h3 className="text-lg font-medium">Colores</h3>
          <div className="grid gap-4 md:grid-cols-2">
            {Object.entries(theme.colors).map(([key, value]) => (
              <div key={key} className="space-y-2">
                <Label className="capitalize">{key}</Label>
                <div className="flex gap-2">
                  <Input
                    type="color"
                    value={value}
                    onChange={(e) => 
                      setTheme({
                        ...theme,
                        colors: { ...theme.colors, [key]: e.target.value }
                      })
                    }
                    className="w-20"
                  />
                  <Input
                    value={value}
                    onChange={(e) =>
                      setTheme({
                        ...theme,
                        colors: { ...theme.colors, [key]: e.target.value }
                      })
                    }
                    placeholder="#000000"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="space-y-4">
          <h3 className="text-lg font-medium">Tipografía</h3>
          <div className="grid gap-4 md:grid-cols-2">
            <div className="space-y-2">
              <Label>Fuente Principal</Label>
              <Input
                value={theme.typography.fontFamily}
                onChange={(e) =>
                  setTheme({
                    ...theme,
                    typography: { ...theme.typography, fontFamily: e.target.value }
                  })
                }
                placeholder="Inter"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <Button onClick={handleSave}>
            Guardar Tema
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};
