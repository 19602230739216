import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Mail, Loader2 } from "lucide-react";

interface StatusUpdateSectionProps {
  currentStatus: string;
  isSubmitting: boolean;
  onStatusChange: (status: string) => Promise<void>;
  onManualEmailSend: () => Promise<void>;
}

export const StatusUpdateSection = ({
  currentStatus,
  isSubmitting,
  onStatusChange,
  onManualEmailSend
}: StatusUpdateSectionProps) => {
  return (
    <div className="space-y-4">
      <Select value={currentStatus} onValueChange={(value) => onStatusChange(value)}>
        <SelectTrigger className="bg-gray-800/50 border-gray-700">
          <SelectValue placeholder="Seleccionar estado" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="pendiente">Pendiente</SelectItem>
          <SelectItem value="en_proceso">En Proceso</SelectItem>
          <SelectItem value="completada">Completada</SelectItem>
          <SelectItem value="cancelada">Cancelada</SelectItem>
        </SelectContent>
      </Select>
      
      <Button 
        type="button"
        variant="outline"
        onClick={onManualEmailSend}
        disabled={isSubmitting}
        className="w-full bg-gray-800/50 border-gray-700 hover:bg-gray-700"
      >
        {isSubmitting ? (
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
        ) : (
          <Mail className="w-4 h-4 mr-2" />
        )}
        Enviar Notificación
      </Button>
    </div>
  );
};