import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { NewHire } from "./types";

export const useNewHires = () => {
  const queryClient = useQueryClient();

  const { data: newHires, isLoading } = useQuery({
    queryKey: ["new-hires"],
    queryFn: async () => {
      console.log('Fetching new hires...');
      const { data, error } = await supabase
        .from("new_hires")
        .select(`
          *,
          new_hire_notifications (
            email
          )
        `)
        .order("start_date", { ascending: false });

      if (error) {
        console.error('Error fetching new hires:', error);
        throw error;
      }

      console.log('New hires fetched:', data);
      return data as NewHire[];
    },
  });

  const updateNewHireMutation = useMutation({
    mutationFn: async (updatedHire: NewHire) => {
      console.log('Updating new hire:', updatedHire);
      const { error } = await supabase
        .from('new_hires')
        .update({
          name: updatedHire.name,
          cdsid: updatedHire.cdsid,
          market: updatedHire.market,
          start_date: updatedHire.start_date,
          status: updatedHire.status,
          notes: updatedHire.notes
        })
        .eq('id', updatedHire.id);

      if (error) throw error;
      return updatedHire;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['new-hires'] });
      toast.success('Incorporación actualizada correctamente');
    },
    onError: (error: any) => {
      console.error('Error in update mutation:', error);
      toast.error('Error al actualizar la incorporación: ' + error.message);
    },
  });

  const deleteNewHireMutation = useMutation({
    mutationFn: async (id: string) => {
      console.log('Deleting new hire with ID:', id);
      const { error } = await supabase
        .from('new_hires')
        .delete()
        .eq('id', id);

      if (error) throw error;
      return id;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['new-hires'] });
      toast.success('Incorporación eliminada correctamente');
    },
    onError: (error: any) => {
      console.error('Error in delete mutation:', error);
      toast.error('Error al eliminar la incorporación: ' + error.message);
    },
  });

  return {
    newHires,
    isLoading,
    updateNewHireMutation,
    deleteNewHireMutation
  };
};