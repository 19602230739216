
import { Loader2 } from "lucide-react";
import { motion } from "framer-motion";

export const DashboardSkeleton = () => {
  return (
    <div className="container mx-auto p-8 space-y-8">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <h1 className="text-2xl font-bold text-gray-100 mb-8">Sistema Avanzado de Gestión IT V.1</h1>
        
        <div className="grid gap-6">
          {/* Skeleton para las alertas de servicio */}
          <div className="h-20 bg-gray-800/50 animate-pulse rounded-lg" />
          
          {/* Skeleton para el quick access */}
          <div className="h-48 bg-gray-800/50 animate-pulse rounded-lg" />
          
          {/* Skeleton para las tarjetas de estadísticas */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {[...Array(4)].map((_, i) => (
              <motion.div 
                key={i} 
                className="h-32 bg-gray-800/50 animate-pulse rounded-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: i * 0.1 }}
              />
            ))}
          </div>
          
          {/* Skeleton para los gráficos */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="h-64 bg-gray-800/50 animate-pulse rounded-lg" />
            <div className="h-64 bg-gray-800/50 animate-pulse rounded-lg" />
          </div>
        </div>
        
        <div className="flex items-center justify-center mt-8">
          <Loader2 className="h-10 w-10 animate-spin text-blue-500" />
        </div>
      </motion.div>
    </div>
  );
};
