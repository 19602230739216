import { Edit2, Trash2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { DialogHeader, DialogTitle } from "@/components/ui/dialog";

interface TicketHeaderProps {
  ticketNumber: number;
  onEdit: () => void;
  onDelete: () => void;
  isSubmitting: boolean;
}

export const TicketHeader = ({ 
  ticketNumber, 
  onEdit, 
  onDelete, 
  isSubmitting 
}: TicketHeaderProps) => {
  return (
    <DialogHeader>
      <DialogTitle className="flex justify-between items-center">
        <span>Ticket #{ticketNumber}</span>
        <div className="flex gap-2">
          <Button
            variant="ghost"
            size="icon"
            onClick={onEdit}
            className="hover:bg-gray-700"
            disabled={isSubmitting}
          >
            <Edit2 className="h-4 w-4" />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            onClick={onDelete}
            className="hover:bg-red-600"
            disabled={isSubmitting}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
      </DialogTitle>
    </DialogHeader>
  );
};