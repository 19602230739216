import { Badge } from "@/components/ui/badge";
import { getStatusColor, getPriorityColor, getStatusText, getPriorityText } from "@/utils/ticketUtils";

interface TicketBadgesProps {
  status: string;
  priority: string;
}

export const TicketBadges = ({ status, priority }: TicketBadgesProps) => {
  return (
    <div className="flex gap-2">
      <Badge variant="secondary" className={`${getStatusColor(status)} text-white`}>
        {getStatusText(status)}
      </Badge>
      <Badge variant="secondary" className={`${getPriorityColor(priority)} text-white`}>
        {getPriorityText(priority)}
      </Badge>
    </div>
  );
};