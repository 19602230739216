import { useNavigate } from "react-router-dom";
import { useSession } from "@supabase/auth-helpers-react";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import { Card } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { useAssetCollections } from "@/hooks/useAssetCollections";
import { ProtectedRoute } from "@/components/auth/ProtectedRoute";

const AssetCollectionsContent = () => {
  const navigate = useNavigate();
  const { data: collections = [], isLoading } = useAssetCollections();

  const handleNewCollection = () => {
    navigate("/asset-collections/new");
  };

  if (isLoading) {
    return (
      <div className="container mx-auto p-8 pt-24">
        <div className="animate-pulse">
          <div className="h-8 bg-gray-700 rounded w-1/4 mb-8"></div>
          <div className="space-y-4">
            {[1, 2, 3].map((i) => (
              <div key={i} className="h-32 bg-gray-800 rounded"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-8 pt-24">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold">Recogida de Activos</h1>
        <Button 
          onClick={handleNewCollection}
          className="bg-blue-500 hover:bg-blue-600"
        >
          <Plus className="w-4 h-4 mr-2" />
          Nueva Recogida
        </Button>
      </div>

      <div className="grid gap-6">
        {collections.map((collection) => (
          <Card 
            key={collection.id} 
            className="p-6 bg-gray-800/50 border-gray-700 hover:bg-gray-700/50 cursor-pointer"
            onClick={() => navigate(`/asset-collections/${collection.id}`)}
          >
            <div className="flex justify-between items-start">
              <div>
                <h3 className="text-lg font-semibold mb-2">{collection.nombre_completo}</h3>
                <p className="text-sm text-gray-400">CDSID: {collection.cdsid}</p>
                <p className="text-sm text-gray-400">
                  Fecha de inicio: {new Date(collection.fecha_inicio).toLocaleDateString()}
                </p>
                {collection.fecha_recogida && (
                  <p className="text-sm text-gray-400">
                    Fecha de recogida: {new Date(collection.fecha_recogida).toLocaleDateString()}
                  </p>
                )}
              </div>
              <Badge className={
                collection.estado === 'pendiente' ? 'bg-yellow-500/20 text-yellow-400' :
                collection.estado === 'en_proceso' ? 'bg-blue-500/20 text-blue-400' :
                'bg-green-500/20 text-green-400'
              }>
                {collection.estado}
              </Badge>
            </div>
            
            <div className="mt-4 pt-4 border-t border-gray-700">
              <h4 className="text-sm font-medium mb-2">Items a recoger:</h4>
              <div className="flex flex-wrap gap-2">
                {collection.items?.map((item) => (
                  <Badge key={item.id} variant="outline">
                    {item.tipo}
                  </Badge>
                ))}
              </div>
            </div>
          </Card>
        ))}

        {collections.length === 0 && (
          <Card className="p-8 text-center bg-gray-800/50 border-gray-700">
            <p className="text-gray-400">No hay recogidas de activos programadas</p>
          </Card>
        )}
      </div>
    </div>
  );
};

const AssetCollections = () => {
  return (
    <ProtectedRoute>
      <AssetCollectionsContent />
    </ProtectedRoute>
  );
};

export default AssetCollections;