import { TicketDetailsView } from "./TicketDetailsView";
import type { Ticket } from "@/hooks/useTickets";

interface TicketDetailsProps {
  ticket: Ticket;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const TicketDetails = (props: TicketDetailsProps) => {
  return <TicketDetailsView {...props} />;
};