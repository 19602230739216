import { jsPDF } from "jspdf";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { supabase } from "@/integrations/supabase/client";
import { applyHeaderStyle, applyBodyStyle, applyTitleStyle } from "./pdf/styles";
import { addStatsSection } from "./pdf/sections/statsSection";
import { addPrioritySection } from "./pdf/sections/prioritySection";
import { addTicketsSection } from "./pdf/sections/ticketsSection";
import { addFooter } from "./pdf/footer";
import { Ticket, TicketStats } from "./pdf/types";

export const generateTicketPDF = async (tickets: Ticket[], stats: TicketStats) => {
  const doc = new jsPDF();
  
  try {
    console.log('Solicitando análisis de IA...');
    const { data, error } = await supabase.functions.invoke('ticket-ai', {
      body: { 
        type: 'analyze_tickets_report',
        data: { tickets, stats }
      }
    });

    if (error) {
      console.error('Error al obtener análisis:', error);
      throw error;
    }

    const analysis = data.analysis;
    
    // Header
    applyHeaderStyle(doc);
    doc.text("Informe de Tickets", 105, 25, { align: "center" });
    
    doc.setFontSize(11);
    doc.setTextColor(200, 200, 200);
    doc.text(
      `Generado el ${format(new Date(), "PPP 'a las' HH:mm", { locale: es })}`,
      105,
      35,
      { align: "center" }
    );
    
    // AI Analysis
    applyTitleStyle(doc);
    doc.text("Análisis Ejecutivo (IA)", 14, 55);
    
    applyBodyStyle(doc);
    const lines = doc.splitTextToSize(analysis, doc.internal.pageSize.width - 28);
    doc.text(lines, 14, 65);
    
    const analysisHeight = lines.length * 5;
    
    // Stats
    applyTitleStyle(doc);
    doc.text("Resumen General", 14, 75 + analysisHeight);
    
    const statsEndY = addStatsSection(doc, stats, 80 + analysisHeight);
    const priorityEndY = addPrioritySection(doc, stats, statsEndY);
    
    // Tickets Detail
    addTicketsSection(doc, tickets);
    
    // Footer
    addFooter(doc);

  } catch (error) {
    console.error('Error generando PDF con análisis:', error);
    return generateBasicPDF(tickets, stats);
  }
  
  return doc;
};

const generateBasicPDF = (tickets: Ticket[], stats: TicketStats) => {
  const doc = new jsPDF();

  // Header
  applyHeaderStyle(doc);
  doc.text("Informe de Tickets", 105, 25, { align: "center" });
  
  doc.setFontSize(11);
  doc.setTextColor(200, 200, 200);
  doc.text(
    `Generado el ${format(new Date(), "PPP 'a las' HH:mm", { locale: es })}`,
    105,
    35,
    { align: "center" }
  );
  
  // Stats
  applyTitleStyle(doc);
  doc.text("Resumen General", 14, 55);
  
  const statsEndY = addStatsSection(doc, stats, 60);
  const priorityEndY = addPrioritySection(doc, stats, statsEndY);
  
  // Tickets Detail
  addTicketsSection(doc, tickets);
  
  // Footer
  addFooter(doc);
  
  return doc;
};