
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { Empleado } from "@/integrations/supabase/types/tables";

export const useEmpleados = () => {
  return useQuery<Empleado[], Error>({
    queryKey: ["empleados"],
    queryFn: async () => {
      try {
        console.log("Fetching empleados data...");
        const { data, error, status } = await supabase
          .from("empleados")
          .select("*")
          .order("created_at", { ascending: false });

        if (error) {
          console.error("Error fetching empleados:", error, "Status:", status);
          throw error;
        }

        console.log("Empleados fetched successfully:", data?.length || 0, "records");
        return data as Empleado[];
      } catch (error) {
        console.error("Unexpected error in useEmpleados:", error);
        throw error;
      }
    },
    staleTime: 5 * 60 * 1000, // 5 minutes cache
    gcTime: 10 * 60 * 1000, // Keep in garbage collection for 10 minutes
    refetchOnWindowFocus: false,
  });
};
