import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { User, Building2, Phone, Mail } from "lucide-react";

interface PersonalInfoFieldsProps {
  formData: {
    nombre_completo: string;
    cdsid: string;
    departamento: string;
    telefono: string;
    email: string;
  };
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const PersonalInfoFields = ({ formData, onChange }: PersonalInfoFieldsProps) => {
  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <Label htmlFor="nombre_completo" className="flex items-center gap-2">
            <User className="h-4 w-4 text-blue-400" />
            Nombre Completo
          </Label>
          <Input
            id="nombre_completo"
            value={formData.nombre_completo}
            onChange={onChange}
            name="nombre_completo"
            className="bg-gray-800/50 border-gray-700"
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="cdsid" className="flex items-center gap-2">
            <User className="h-4 w-4 text-blue-400" />
            CDSID
          </Label>
          <Input
            id="cdsid"
            value={formData.cdsid}
            onChange={onChange}
            name="cdsid"
            className="bg-gray-800/50 border-gray-700"
            required
          />
        </div>
      </div>

      <div className="space-y-2">
        <Label htmlFor="departamento" className="flex items-center gap-2">
          <Building2 className="h-4 w-4 text-blue-400" />
          Departamento
        </Label>
        <Input
          id="departamento"
          value={formData.departamento}
          onChange={onChange}
          name="departamento"
          className="bg-gray-800/50 border-gray-700"
          required
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <Label htmlFor="telefono" className="flex items-center gap-2">
            <Phone className="h-4 w-4 text-blue-400" />
            Teléfono de Contacto
          </Label>
          <Input
            id="telefono"
            value={formData.telefono}
            onChange={onChange}
            name="telefono"
            className="bg-gray-800/50 border-gray-700"
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="email" className="flex items-center gap-2">
            <Mail className="h-4 w-4 text-blue-400" />
            Email
          </Label>
          <Input
            id="email"
            type="email"
            value={formData.email}
            onChange={onChange}
            name="email"
            className="bg-gray-800/50 border-gray-700"
            required
          />
        </div>
      </div>
    </>
  );
};