
import { motion } from "framer-motion";

export const EmpleadosSkeleton = () => {
  return (
    <div className="space-y-8">
      <div className="flex items-center gap-4">
        <div className="h-8 w-48 bg-gray-800 animate-pulse rounded-md"></div>
        <div className="h-8 w-24 bg-gray-800 animate-pulse rounded-md"></div>
      </div>
      
      <div className="h-16 bg-gray-800 animate-pulse rounded-lg w-full"></div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {[1, 2, 3, 4, 5, 6].map((i) => (
          <motion.div
            key={i}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, delay: i * 0.05 }}
            className="bg-gray-800 rounded-lg h-[240px] animate-pulse"
          />
        ))}
      </div>
    </div>
  );
};
