import { AccessRequestForm } from "@/components/access/AccessRequestForm";
import { AccessRequestList } from "@/components/access/AccessRequestList";
import { Card } from "@/components/ui/card";

const AccessRequests = () => {
  return (
    <div className="container mx-auto p-4 lg:p-8 space-y-8">
      <h1 className="text-3xl font-bold mb-8">Solicitudes de Acceso</h1>
      
      <div className="grid lg:grid-cols-3 gap-8">
        <div className="lg:col-span-1">
          <AccessRequestForm />
        </div>
        
        <div className="lg:col-span-2 space-y-4">
          <h2 className="text-xl font-semibold mb-4">Mis Solicitudes</h2>
          <Card className="bg-gray-800/50 border-gray-700 p-4">
            <AccessRequestList />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default AccessRequests;