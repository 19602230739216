import { useEffect } from "react";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { useAssetCollectionData } from "./useAssetCollectionData";
import { useAssetCollectionActions } from "./useAssetCollectionActions";
import { AssetCollectionItem } from "../types/assetCollection";

export const useAssetCollection = (id?: string) => {
  const {
    formData,
    setFormData,
    isSubmitting,
    setIsSubmitting,
    sendEmail,
    setSendEmail,
    additionalEmails,
    setAdditionalEmails,
    newEmail,
    setNewEmail
  } = useAssetCollectionData(id);

  const loadCollection = async (collectionId: string) => {
    try {
      const { data, error } = await supabase
        .from('asset_collections')
        .select('*')
        .eq('id', collectionId)
        .single();

      if (error) throw error;
      if (data) {
        setFormData({
          ...data,
          fecha_baja: data.fecha_baja ? new Date(data.fecha_baja).toISOString().split('T')[0] : '',
          fecha_recogida: data.fecha_recogida ? new Date(data.fecha_recogida).toISOString().split('T')[0] : ''
        });
      }
    } catch (error) {
      console.error('Error loading collection:', error);
      toast.error("Error al cargar la recogida");
    }
  };

  const { handleStatusChange, handleManualEmailSend } = useAssetCollectionActions(
    id,
    formData,
    additionalEmails,
    setIsSubmitting,
    loadCollection
  );

  const handleSubmit = async (
    e: React.FormEvent,
    selectedItems: string[],
    otherItems: string
  ) => {
    e.preventDefault();
    setIsSubmitting(true);
    console.log('Submitting form with items:', { selectedItems, otherItems });

    try {
      const { data, error } = await supabase
        .from('asset_collections')
        .upsert({
          ...formData,
          id: id || undefined,
          estado: id ? formData.estado : 'pendiente'
        })
        .select()
        .single();

      if (error) throw error;

      if (data) {
        console.log('Collection created/updated:', data);
        
        // Prepare items array with proper estado value
        const items: AssetCollectionItem[] = selectedItems.map(item => ({
          collection_id: data.id,
          tipo: item,
          estado: 'pendiente',
          notas: null
        }));

        if (otherItems) {
          items.push({
            collection_id: data.id,
            tipo: 'otros',
            estado: 'pendiente',
            notas: otherItems
          });
        }

        if (items.length > 0) {
          console.log('Inserting items:', items);
          
          // First, delete existing items
          if (id) {
            const { error: deleteError } = await supabase
              .from('asset_collection_items')
              .delete()
              .eq('collection_id', data.id);

            if (deleteError) {
              console.error('Error deleting existing items:', deleteError);
              throw deleteError;
            }
          }

          // Then insert new items
          const { error: itemsError } = await supabase
            .from('asset_collection_items')
            .insert(items);

          if (itemsError) {
            console.error('Error inserting items:', itemsError);
            throw itemsError;
          }
        }
      }

      if (sendEmail && additionalEmails.length > 0) {
        console.log('Sending notification email to:', additionalEmails);
        const { error: emailError } = await supabase.functions.invoke('notify-asset-collection', {
          body: {
            collection: {
              ...formData,
              fecha_baja: new Date(formData.fecha_baja).toISOString(),
              fecha_recogida: new Date(formData.fecha_recogida).toISOString()
            },
            additionalEmails
          }
        });

        if (emailError) {
          console.error('Error sending notification:', emailError);
          toast.error("La recogida se registró pero hubo un error al enviar las notificaciones");
        }
      }

      toast.success(id ? "Recogida actualizada correctamente" : "Recogida registrada correctamente");
      return true;
    } catch (error) {
      console.error('Error submitting collection:', error);
      toast.error(id ? "Error al actualizar la recogida" : "Error al registrar la recogida");
      return false;
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (id) {
      loadCollection(id);
    }
  }, [id]);

  return {
    formData,
    setFormData,
    isSubmitting,
    sendEmail,
    setSendEmail,
    additionalEmails,
    setAdditionalEmails,
    newEmail,
    setNewEmail,
    handleStatusChange,
    handleManualEmailSend,
    handleSubmit
  };
};