import { Card } from "@/components/ui/card";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Box, Plus, ArrowRight, AlertTriangle } from "lucide-react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { Alert, AlertDescription } from "@/components/ui/alert";

export const AssetCollectionsSection = () => {
  const navigate = useNavigate();
  const { data: collections = [] } = useQuery({
    queryKey: ["asset-collections-dashboard"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("asset_collections")
        .select(`
          *,
          items:asset_collection_items(*)
        `)
        .order('created_at', { ascending: false })
        .limit(5);

      if (error) {
        toast.error("Error al cargar las recogidas de activos");
        throw error;
      }
      return data;
    },
  });

  const handleCreateCollection = () => {
    navigate("/asset-collections/new");
  };

  const handleViewCollection = (id: string) => {
    console.log('Navigating to collection:', id);
    navigate(`/asset-collections/${id}`);
  };

  const handleViewAll = () => {
    navigate("/asset-collections");
  };

  return (
    <Card className="p-6 bg-gray-800/50 backdrop-blur-lg border-gray-700/50">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-2">
          <div className="p-2 rounded-lg bg-blue-500/10">
            <Box className="w-5 h-5 text-blue-400" />
          </div>
          <h2 className="text-xl font-bold text-white">Recogida de Activos</h2>
        </div>
        <div className="flex gap-2">
          <Button
            variant="outline"
            size="sm"
            onClick={handleCreateCollection}
            className="text-blue-400 border-blue-400/30 hover:bg-blue-400/10"
          >
            <Plus className="w-4 h-4 mr-1" />
            Nueva Recogida
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={handleViewAll}
            className="text-gray-400 border-gray-400/30 hover:bg-gray-400/10"
          >
            Ver Todas
            <ArrowRight className="w-4 h-4 ml-1" />
          </Button>
        </div>
      </div>

      <Alert className="mb-4 border-yellow-500/50 bg-yellow-500/10">
        <AlertTriangle className="h-4 w-4 text-yellow-400" />
        <AlertDescription className="text-yellow-400">
          Importante: Para bajas temporales, la recogida de activos debe programarse dos meses después de la fecha de baja.
        </AlertDescription>
      </Alert>

      <div className="space-y-4">
        {collections.map((collection) => (
          <motion.div
            key={collection.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="p-4 rounded-lg bg-gray-800/50 border border-gray-700/50 hover:bg-gray-700/50 transition-all duration-200 cursor-pointer"
            onClick={() => handleViewCollection(collection.id)}
          >
            <div className="flex justify-between items-start">
              <div>
                <h3 className="font-medium text-gray-200">{collection.nombre_completo}</h3>
                <p className="text-sm text-gray-400">CDSID: {collection.cdsid}</p>
                <p className="text-sm text-gray-400">
                  Fecha de baja: {new Date(collection.fecha_baja).toLocaleDateString()}
                </p>
                {collection.fecha_recogida && (
                  <p className="text-sm text-gray-400">
                    Fecha de recogida: {new Date(collection.fecha_recogida).toLocaleDateString()}
                  </p>
                )}
                {collection.empresa_mensajeria && (
                  <p className="text-sm text-gray-400">
                    Empresa de mensajería: {collection.empresa_mensajeria}
                  </p>
                )}
              </div>
              <Badge className={
                collection.estado === 'pendiente' ? 'bg-yellow-500/20 text-yellow-400' :
                collection.estado === 'en_proceso' ? 'bg-blue-500/20 text-blue-400' :
                'bg-green-500/20 text-green-400'
              }>
                {collection.estado}
              </Badge>
            </div>
            <div className="mt-2 text-sm text-gray-400">
              <p>Departamento: {collection.departamento}</p>
              <p>Teléfono: {collection.telefono}</p>
              <p>Dirección: {collection.direccion_recogida}</p>
              {collection.motivo_baja && (
                <p className="mt-1">Motivo: {collection.motivo_baja}</p>
              )}
            </div>
            {collection.items && collection.items.length > 0 && (
              <div className="mt-2 flex flex-wrap gap-2">
                {collection.items.map((item) => (
                  <Badge key={item.id} variant="outline" className="text-xs">
                    {item.tipo}
                  </Badge>
                ))}
              </div>
            )}
          </motion.div>
        ))}

        {collections.length === 0 && (
          <div className="text-center py-8 text-gray-400">
            <Box className="w-12 h-12 mx-auto mb-3 text-gray-500 opacity-50" />
            <p className="text-lg font-medium">No hay recogidas pendientes</p>
            <p className="text-sm mt-1">Crea una nueva recogida para comenzar</p>
            <Button
              variant="outline"
              size="sm"
              onClick={handleCreateCollection}
              className="mt-4 text-blue-400 border-blue-400/30 hover:bg-blue-400/10"
            >
              <Plus className="w-4 h-4 mr-1" />
              Nueva Recogida
            </Button>
          </div>
        )}
      </div>
    </Card>
  );
};