import { useState } from "react";
import { BasicFields } from "./BasicFields";
import { CategoryFields } from "./CategoryFields";
import { NotificationPreferences } from "../NotificationPreferences";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { DescriptionField } from "./fields/DescriptionField";

interface InternalTicketFormProps {
  onSubmit: (data: {
    title: string;
    description: string;
    priority: string;
    category: string;
    affected_cdsid: string;
    sendNotification: boolean;
    additionalEmails: string[];
  }) => Promise<void>;
  isSubmitting: boolean;
  onCancel: () => void;
  initialData?: {
    title?: string;
    description?: string;
    priority?: string;
    category?: string;
    affected_cdsid?: string;
  };
}

export const InternalTicketForm = ({ 
  onSubmit, 
  isSubmitting, 
  onCancel,
  initialData 
}: InternalTicketFormProps) => {
  const [title, setTitle] = useState(initialData?.title || "");
  const [description, setDescription] = useState(initialData?.description || "");
  const [priority, setPriority] = useState(initialData?.priority || "medium");
  const [category, setCategory] = useState(initialData?.category || "other");
  const [affected_cdsid, setAffectedCdsid] = useState(initialData?.affected_cdsid || "");
  const [sendNotification, setSendNotification] = useState(true);
  const [newEmail, setNewEmail] = useState("");
  const [additionalEmails, setAdditionalEmails] = useState<string[]>([]);

  const handleAddEmail = () => {
    if (newEmail && !additionalEmails.includes(newEmail)) {
      setAdditionalEmails([...additionalEmails, newEmail]);
      setNewEmail("");
    }
  };

  const handleRemoveEmail = (emailToRemove: string) => {
    setAdditionalEmails(additionalEmails.filter(email => email !== emailToRemove));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await onSubmit({
      title,
      description,
      priority,
      category,
      affected_cdsid,
      sendNotification,
      additionalEmails
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <Input
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Título del ticket"
        className="w-full"
        required
      />

      <DescriptionField
        title={title}
        description={description}
        setDescription={setDescription}
      />
      
      <CategoryFields
        priority={priority}
        setPriority={setPriority}
        category={category}
        setCategory={setCategory}
      />

      <div className="space-y-4">
        <Input
          value={affected_cdsid}
          onChange={(e) => setAffectedCdsid(e.target.value)}
          placeholder="CDSID del usuario afectado"
        />

        <div className="flex items-center space-x-2">
          <Switch
            id="send-notification"
            checked={sendNotification}
            onCheckedChange={setSendNotification}
          />
          <Label htmlFor="send-notification">
            Enviar notificación por email
          </Label>
        </div>

        {sendNotification && (
          <div className="space-y-2">
            <div className="flex gap-2">
              <Input
                type="email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                placeholder="Añadir email adicional"
                className="flex-1"
              />
              <Button 
                type="button" 
                onClick={handleAddEmail}
                variant="outline"
              >
                Añadir
              </Button>
            </div>

            {additionalEmails.length > 0 && (
              <div className="space-y-2">
                {additionalEmails.map((email) => (
                  <div key={email} className="flex items-center gap-2 bg-gray-100 dark:bg-gray-800 p-2 rounded">
                    <span className="flex-1">{email}</span>
                    <Button
                      type="button"
                      variant="ghost"
                      size="sm"
                      onClick={() => handleRemoveEmail(email)}
                    >
                      ×
                    </Button>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      <div className="flex justify-end gap-3 pt-4">
        <Button 
          type="button" 
          variant="outline" 
          onClick={onCancel}
        >
          Cancelar
        </Button>
        <Button 
          type="submit" 
          disabled={isSubmitting}
          className="bg-blue-500 hover:bg-blue-600"
        >
          {isSubmitting ? (
            <>
              <Loader2 className="w-4 h-4 mr-2 animate-spin" />
              {initialData ? "Actualizando..." : "Creando..."}
            </>
          ) : (
            initialData ? "Actualizar Ticket" : "Crear Ticket"
          )}
        </Button>
      </div>
    </form>
  );
};