import { supabase } from "@/integrations/supabase/client";
import { Database } from "@/integrations/supabase/types";

// Valid status values based on the database constraint
export const VALID_TICKET_STATUSES = ['pending', 'open', 'in_progress', 'closed'] as const;
export type TicketStatus = typeof VALID_TICKET_STATUSES[number];

export interface CreateTicketData {
  title: string;
  description: string;
  category: string;
  cdsid: string;
  name?: string;
  market?: string;
  email: string;
  empleado?: any;
  status: TicketStatus; // Now properly typed
}

export const findOrCreateUser = async (email: string) => {
  console.log("Finding or creating user with email:", email);
  
  // Extract CDSID from email
  const cdsid = email.split('@')[0].toUpperCase();
  
  try {
    // First try to find the empleado
    const { data: empleado, error: empleadoError } = await supabase
      .from('empleados')
      .select('*')
      .eq('cdsid', cdsid)
      .maybeSingle();

    if (empleadoError) {
      console.error("Error searching for empleado:", empleadoError);
      throw new Error("Error buscando empleado");
    }

    if (empleado) {
      console.log("Found empleado:", empleado);
      return empleado;
    }

    // If no empleado found, create one
    const { data: newEmpleado, error: createError } = await supabase
      .from('empleados')
      .insert([
        {
          cdsid: cdsid,
          nombre: email.split('@')[0],
          apellidos: '',
          email: email,
          mercado: 'IBERIA',
          estado: 'activo'
        }
      ])
      .select()
      .single();

    if (createError) {
      console.error("Error creating empleado:", createError);
      throw new Error("Error creando empleado");
    }

    console.log("Created new empleado:", newEmpleado);
    return newEmpleado;
  } catch (error) {
    console.error("Error searching for user:", error);
    throw error;
  }
};

export const createTicket = async (data: CreateTicketData) => {
  console.log("Creating ticket with data:", data);
  
  try {
    // Validate status
    if (!VALID_TICKET_STATUSES.includes(data.status)) {
      console.error("Invalid status value:", data.status);
      throw new Error(`Estado de ticket inválido. Debe ser uno de: ${VALID_TICKET_STATUSES.join(', ')}`);
    }

    // Find or create user first
    const user = await findOrCreateUser(data.email);
    console.log("Using user:", user);

    const ticketData = {
      title: data.title,
      description: data.description,
      category: data.category,
      status: data.status,
      priority: 'medium',
      submitter_cdsid: data.cdsid,
      submitter_name: data.empleado?.nombre || user.nombre,
      submitter_market: data.market || user.mercado,
      submitter_email: data.email,
      creator_id: user.id,
      creator_email: data.email
    };

    console.log("Submitting ticket data:", ticketData);

    const { data: ticket, error } = await supabase
      .from('tickets')
      .insert([ticketData])
      .select()
      .single();

    if (error) {
      console.error("Error creating ticket:", error);
      throw error;
    }

    console.log("Ticket created successfully:", ticket);
    return ticket;
  } catch (error) {
    console.error("Error in createTicket:", error);
    throw error;
  }
};

export const getStatusColor = (status: string): string => {
  switch (status) {
    case 'open':
      return 'bg-green-500/10 text-green-500 border-green-500/20';
    case 'in_progress':
      return 'bg-blue-500/10 text-blue-500 border-blue-500/20';
    case 'closed':
      return 'bg-gray-500/10 text-gray-500 border-gray-500/20';
    case 'pending':
      return 'bg-yellow-500/10 text-yellow-500 border-yellow-500/20';
    default:
      return 'bg-gray-500/10 text-gray-500 border-gray-500/20';
  }
};

export const getPriorityColor = (priority: string): string => {
  switch (priority) {
    case 'urgent':
      return 'bg-red-500/10 text-red-500 border-red-500/20';
    case 'high':
      return 'bg-orange-500/10 text-orange-500 border-orange-500/20';
    case 'medium':
      return 'bg-yellow-500/10 text-yellow-500 border-yellow-500/20';
    case 'low':
      return 'bg-green-500/10 text-green-500 border-green-500/20';
    default:
      return 'bg-gray-500/10 text-gray-500 border-gray-500/20';
  }
};

export const getStatusText = (status: string): string => {
  switch (status) {
    case 'open':
      return 'Abierto';
    case 'in_progress':
      return 'En Progreso';
    case 'closed':
      return 'Cerrado';
    case 'pending':
      return 'Pendiente';
    default:
      return status;
  }
};

export const getPriorityText = (priority: string): string => {
  switch (priority) {
    case 'urgent':
      return 'Urgente';
    case 'high':
      return 'Alta';
    case 'medium':
      return 'Media';
    case 'low':
      return 'Baja';
    default:
      return priority;
  }
};