import { useState } from "react";
import { AssetCollectionFormData } from "../types/assetCollection";

export const useAssetCollectionData = (id?: string) => {
  const [formData, setFormData] = useState<AssetCollectionFormData>({
    nombre_completo: "",
    cdsid: "",
    departamento: "",
    fecha_baja: "",
    fecha_recogida: "",
    direccion_recogida: "",
    telefono: "",
    email: "",
    motivo_baja: "",
    empresa_mensajeria: "",
    estado: "pendiente"
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);
  const [additionalEmails, setAdditionalEmails] = useState<string[]>([]);
  const [newEmail, setNewEmail] = useState("");

  return {
    formData,
    setFormData,
    isSubmitting,
    setIsSubmitting,
    sendEmail,
    setSendEmail,
    additionalEmails,
    setAdditionalEmails,
    newEmail,
    setNewEmail
  };
};