import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { Upload, Loader2, ArrowLeft } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";

export const AssetReturnForm = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [formData, setFormData] = useState({
    employee_cdsid: "",
    notes: "",
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      let documentUrl = null;
      let documentName = null;
      let documentType = null;

      if (file) {
        const fileExt = file.name.split('.').pop();
        const fileName = `${crypto.randomUUID()}.${fileExt}`;
        
        const { error: uploadError, data } = await supabase.storage
          .from('asset-returns')
          .upload(fileName, file);

        if (uploadError) throw uploadError;

        documentUrl = data.path;
        documentName = file.name;
        documentType = file.type;
      }

      const { error } = await supabase
        .from('asset_returns')
        .insert({
          employee_cdsid: formData.employee_cdsid,
          notes: formData.notes,
          document_url: documentUrl,
          document_name: documentName,
          document_type: documentType,
        });

      if (error) throw error;

      toast.success("Devolución registrada correctamente");
      navigate("/inventory");
    } catch (error) {
      console.error('Error submitting return:', error);
      toast.error("Error al registrar la devolución");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Card className="p-6 max-w-2xl mx-auto bg-gray-900/40 backdrop-blur-lg border-gray-800/50">
      <div className="mb-6">
        <Button
          variant="ghost"
          onClick={() => navigate("/inventory")}
          className="text-gray-400 hover:text-white"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Volver a Inventario
        </Button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-2">
          <Label htmlFor="employee_cdsid" className="text-gray-200">CDSID del Empleado</Label>
          <Input
            id="employee_cdsid"
            value={formData.employee_cdsid}
            onChange={(e) => setFormData(prev => ({ ...prev, employee_cdsid: e.target.value }))}
            required
            className="bg-gray-800/50 border-gray-700"
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="notes" className="text-gray-200">Notas</Label>
          <Textarea
            id="notes"
            value={formData.notes}
            onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
            placeholder="Detalles adicionales sobre la devolución..."
            className="bg-gray-800/50 border-gray-700 min-h-[100px]"
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="document" className="text-gray-200">Documento de Devolución</Label>
          <div className="flex items-center gap-4">
            <Input
              id="document"
              type="file"
              onChange={handleFileChange}
              className="bg-gray-800/50 border-gray-700 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 
                       file:text-sm file:font-semibold file:bg-blue-600/10 file:text-blue-400 
                       hover:file:bg-blue-600/20"
            />
            {file && <p className="text-sm text-gray-400">{file.name}</p>}
          </div>
        </div>

        <Button 
          type="submit" 
          className="w-full bg-blue-600 hover:bg-blue-700"
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Registrando...
            </>
          ) : (
            <>
              <Upload className="mr-2 h-4 w-4" />
              Registrar Devolución
            </>
          )}
        </Button>
      </form>
    </Card>
  );
};