import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { NotificationDialog } from "./status/NotificationDialog";
import { PlatformStatusBadge } from "./status/PlatformStatusBadge";
import { PlatformStatusSelect } from "./status/PlatformStatusSelect";
import { Mail, Sparkles, AlertTriangle } from "lucide-react";

const PLATFORMS = [
  "TEAMS", "Portal del Empleado", "PTS", "FINESSE", "Cisco Jabber", "DEALIS",
  "VERINT", "GCCT", "LITMO S", "Moodle", "WEBEX", "SUPER DUPER", "SMART IT",
  "Contactos EU", "VMACS", "Stars", "OWS", "CVBOP TRIAGE", "CVBOP PAAK",
  "Wallbox Dashboard", "GTAC", "Data Explorer", "APP STORE", "PLAY STORE",
  "GLOBALUPTIME", "MOBILITY", "Café X", "Askford", "OWA", "Microcat", "MLP",
  "Medialia"
];

interface AccessStatusTrackerProps {
  accessStatus: Array<{
    platform: string;
    status: string;
    notes?: string;
  }>;
  newHireId: string;
  onStatusUpdate?: () => void;
}

export const AccessStatusTracker = ({ 
  accessStatus = [], 
  newHireId,
  onStatusUpdate 
}: AccessStatusTrackerProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const platformStatusMap = new Map(
    PLATFORMS.map(platform => [
      platform,
      accessStatus.find(item => item.platform === platform)?.status || 'pendiente'
    ])
  );

  const updatePlatformStatus = async (platformName: string, newStatus: string) => {
    try {
      console.log('Updating platform status:', { platformName, newStatus, newHireId });
      
      const updatedAccessStatus = Array.from(platformStatusMap.entries()).map(([platform, status]) => ({
        platform,
        status: platform === platformName ? newStatus : status,
      }));

      const { error } = await supabase
        .from('new_hires')
        .update({ 
          access_status: updatedAccessStatus,
          updated_at: new Date().toISOString()
        })
        .eq('id', newHireId);

      if (error) throw error;

      toast.success(`Estado actualizado para ${platformName}`);
      if (onStatusUpdate) onStatusUpdate();
    } catch (error) {
      console.error('Error updating platform status:', error);
      toast.error('Error al actualizar el estado');
    }
  };

  const sendNotification = async (emails: string[]) => {
    try {
      console.log('Sending notification to:', emails);
      
      const { data: newHire, error: newHireError } = await supabase
        .from('new_hires')
        .select('*')
        .eq('id', newHireId)
        .single();

      if (newHireError) throw newHireError;

      const { error: notificationError } = await supabase.functions.invoke('notify-access-status', {
        body: {
          notification: {
            to: emails,
            newHire: {
              cdsid: newHire.cdsid,
              name: newHire.name,
              accessStatus: Array.from(platformStatusMap.entries()).map(([platform, status]) => ({
                platform,
                status
              }))
            }
          }
        }
      });

      if (notificationError) throw notificationError;
      toast.success('Notificación enviada correctamente');
    } catch (error) {
      console.error('Error sending notification:', error);
      toast.error('Error al enviar la notificación');
    }
  };

  const handleClaimIssue = async (platform: string) => {
    try {
      const { error } = await supabase.functions.invoke('notify-access-status', {
        body: {
          notification: {
            to: ['istojanovic@msxi.com'],
            newHire: {
              cdsid: 'N/A',
              name: 'N/A',
              accessStatus: [{
                platform,
                status: platformStatusMap.get(platform)
              }]
            },
            type: 'claim'
          }
        }
      });

      if (error) throw error;
      toast.success('Reclamación enviada correctamente');
    } catch (error) {
      console.error('Error sending claim:', error);
      toast.error('Error al enviar la reclamación');
    }
  };

  return (
    <Card className="p-6 bg-gray-800/50 space-y-6">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center gap-2">
          <h3 className="text-xl font-medium">Estado de Accesos</h3>
          <Sparkles className="h-5 w-5 text-blue-400 animate-pulse" />
        </div>
        <Button 
          onClick={() => setIsDialogOpen(true)}
          variant="secondary"
          className="flex items-center gap-2 hover:scale-105 transition-transform duration-200"
        >
          <Mail className="h-4 w-4" />
          Enviar Notificación
        </Button>
      </div>
      
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4">
        {Array.from(platformStatusMap.entries()).map(([platform, status]) => (
          <div 
            key={platform}
            className="group relative"
          >
            <div className="absolute inset-0 bg-gradient-to-br from-blue-500/10 to-purple-500/10 rounded-lg transform group-hover:scale-105 transition-transform duration-300 -z-10" />
            <div className="relative flex flex-col bg-gray-900/90 backdrop-blur-sm rounded-lg border border-gray-700/50 shadow-lg overflow-hidden group-hover:border-gray-500 transition-all duration-300 p-4 animate-fade-in">
              <div className="space-y-3">
                <div className="flex flex-col gap-2">
                  <h4 className="font-medium text-sm text-gray-200 truncate">
                    {platform}
                  </h4>
                  <PlatformStatusBadge status={status} />
                </div>
                
                <div className="w-full opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                  <PlatformStatusSelect
                    status={status}
                    onStatusChange={(newStatus) => updatePlatformStatus(platform, newStatus)}
                  />
                </div>

                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => handleClaimIssue(platform)}
                  className="w-full mt-2 bg-red-500/10 hover:bg-red-500/20 border-red-500/20 text-red-400"
                >
                  <AlertTriangle className="h-4 w-4 mr-2" />
                  Reclamar
                </Button>
              </div>
              
              <div className="h-1 w-full bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-blue-500/20 mt-3 opacity-0 group-hover:opacity-100 transition-opacity duration-200" />
            </div>
          </div>
        ))}
      </div>

      <NotificationDialog
        isOpen={isDialogOpen}
        onOpenChange={setIsDialogOpen}
        onSend={sendNotification}
      />
    </Card>
  );
};