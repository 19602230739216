import { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useSession } from "@supabase/auth-helpers-react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { AlertCircle } from "lucide-react";
import { InternalTicketForm } from "./form/InternalTicketForm";
import { useLocation } from "react-router-dom";

interface CreateTicketDialogProps {
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const CreateTicketDialog = ({ isOpen, onOpenChange }: CreateTicketDialogProps) => {
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const session = useSession();
  const queryClient = useQueryClient();
  const location = useLocation();
  const prefilledData = location.state?.prefilledData;

  useEffect(() => {
    if (prefilledData) {
      console.log('Opening ticket dialog with prefilled data:', prefilledData);
      if (onOpenChange) {
        onOpenChange(true);
      } else {
        setOpen(true);
      }
    }
  }, [prefilledData, onOpenChange]);

  const handleSubmit = async (formData: {
    title: string;
    description: string;
    priority: string;
    category: string;
    affected_cdsid: string;
    sendNotification: boolean;
    additionalEmails: string[];
  }) => {
    setIsSubmitting(true);
    console.log('Starting ticket creation with session user:', session?.user);

    try {
      if (!session?.user) {
        throw new Error("No hay usuario autenticado");
      }

      // First check if user exists in users table
      let { data: userData, error: userError } = await supabase
        .from('users')
        .select('cdsid, name')
        .eq('id', session.user.id)
        .maybeSingle();

      console.log('Initial user check:', userData, userError);

      // If user doesn't exist, create them
      if (!userData) {
        const username = session.user.email?.split('@')[0] || 'user';
        const newUser = {
          id: session.user.id,
          cdsid: username.toUpperCase(),
          name: username,
          email: session.user.email,
          employee_status: 'Active',
          employee_type: 'M'
        };

        console.log('Creating new user:', newUser);

        const { data: insertedUser, error: insertError } = await supabase
          .from('users')
          .insert([newUser])
          .select('cdsid, name')
          .single();

        if (insertError) {
          console.error('Error creating user:', insertError);
          throw new Error("Error al crear usuario");
        }

        userData = insertedUser;
      }

      console.log('Final user data:', userData);

      const ticketData = {
        title: formData.title,
        description: formData.description,
        priority: formData.priority,
        category: formData.category,
        creator_id: session.user.id,
        creator_email: session.user.email,
        status: "pending",
        affected_cdsid: formData.affected_cdsid || null,
        submitter_cdsid: userData.cdsid,
        submitter_name: userData.name,
        submitter_email: session.user.email
      };

      console.log('Submitting ticket data:', ticketData);

      const { data: newTicket, error: insertError } = await supabase
        .from("tickets")
        .insert([ticketData])
        .select()
        .single();

      if (insertError) {
        console.error('Error inserting ticket:', insertError);
        throw insertError;
      }

      await supabase.from("activity_log").insert({
        action_type: "create_ticket",
        description: `Nuevo ticket creado: ${formData.title}`,
        user_id: session.user.id,
        metadata: { ticket_data: ticketData }
      });

      // Si se solicita enviar notificación
      if (formData.sendNotification) {
        const emailRecipients = [session.user.email];
        if (formData.additionalEmails && formData.additionalEmails.length > 0) {
          emailRecipients.push(...formData.additionalEmails);
        }

        console.log('Sending notification to:', emailRecipients);

        await supabase.functions.invoke("notify-ticket-updates", {
          body: {
            notification: {
              to: emailRecipients,
              subject: `Ticket #${newTicket.ticket_number} creado correctamente`,
              ticketNumber: newTicket.ticket_number,
              status: "pending",
              title: formData.title,
              description: formData.description,
              updateType: "created"
            }
          }
        });
      }

      toast.success("¡Ticket creado correctamente!");
      queryClient.invalidateQueries({ queryKey: ["tickets"] });
      
      if (onOpenChange) {
        onOpenChange(false);
      } else {
        setOpen(false);
      }

    } catch (error: any) {
      console.error("Error al crear ticket:", error);
      toast.error(error.message || "No se pudo crear el ticket. Por favor, inténtalo de nuevo.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const dialogOpen = isOpen !== undefined ? isOpen : open;
  const handleOpenChange = onOpenChange || setOpen;

  return (
    <Dialog open={dialogOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        <Button variant="outline" className="gap-2">
          <AlertCircle className="w-4 h-4" />
          Nuevo Ticket
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Crear Nuevo Ticket</DialogTitle>
        </DialogHeader>
        <InternalTicketForm 
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          onCancel={() => handleOpenChange(false)}
          initialData={prefilledData ? {
            affected_cdsid: prefilledData.affected_cdsid
          } : undefined}
        />
      </DialogContent>
    </Dialog>
  );
};