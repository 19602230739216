import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Loader2, Mail } from "lucide-react";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { useState } from "react";

interface AlertDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  formData: any;
}

export const AlertDialog = ({ open, onOpenChange, formData }: AlertDialogProps) => {
  const [emailInput, setEmailInput] = useState("");
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [isSendingAlert, setIsSendingAlert] = useState(false);

  const handleAddEmail = () => {
    if (emailInput && !selectedEmails.includes(emailInput)) {
      setSelectedEmails([...selectedEmails, emailInput]);
      setEmailInput("");
    }
  };

  const handleRemoveEmail = (email: string) => {
    setSelectedEmails(selectedEmails.filter(e => e !== email));
  };

  const handleSendAlert = async () => {
    if (selectedEmails.length === 0) {
      toast.error("Por favor, seleccione al menos un destinatario");
      return;
    }

    setIsSendingAlert(true);
    try {
      console.log("Sending alert to:", selectedEmails);
      console.log("Collection data:", formData);
      
      const { error } = await supabase.functions.invoke('notify-asset-collection', {
        body: {
          collection: formData,
          additionalEmails: selectedEmails,
          isManualSend: true
        }
      });

      if (error) throw error;
      
      toast.success("Alerta enviada correctamente");
      onOpenChange(false);
      setSelectedEmails([]);
    } catch (error) {
      console.error('Error sending alert:', error);
      toast.error("Error al enviar la alerta");
    } finally {
      setIsSendingAlert(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="bg-gray-800 text-white">
        <DialogHeader>
          <DialogTitle>Enviar Alerta de Recogida</DialogTitle>
        </DialogHeader>

        <div className="space-y-4">
          <div className="flex gap-2">
            <Input
              type="email"
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
              placeholder="Añadir email"
              className="flex-1 bg-gray-700"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleAddEmail();
                }
              }}
            />
            <Button 
              type="button" 
              variant="outline"
              onClick={handleAddEmail}
              className="bg-gray-700"
            >
              <Mail className="w-4 h-4 mr-2" />
              Añadir
            </Button>
          </div>

          {selectedEmails.length > 0 && (
            <div className="space-y-2">
              {selectedEmails.map((email) => (
                <div key={email} className="flex items-center justify-between bg-gray-700 p-2 rounded">
                  <span>{email}</span>
                  <Button
                    type="button"
                    variant="ghost"
                    size="sm"
                    onClick={() => handleRemoveEmail(email)}
                    className="hover:bg-gray-600"
                  >
                    ×
                  </Button>
                </div>
              ))}
            </div>
          )}

          <div className="flex justify-end gap-2 mt-4">
            <Button
              variant="outline"
              onClick={() => onOpenChange(false)}
              className="bg-gray-700"
            >
              Cancelar
            </Button>
            <Button
              onClick={handleSendAlert}
              disabled={isSendingAlert || selectedEmails.length === 0}
            >
              {isSendingAlert ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Enviando...
                </>
              ) : (
                'Enviar Alerta'
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};