import { format } from "date-fns";
import { es } from "date-fns/locale";
import { CalendarClock, CalendarRange, CalendarX } from "lucide-react";
import { motion } from "framer-motion";
import { Card } from "@/components/ui/card";
import type { CalendarEvent } from "@/types/calendar";

interface EventsListProps {
  date: Date | undefined;
  events: CalendarEvent[];
}

export const EventsList = ({ date, events }: EventsListProps) => {
  const getEventsByDate = (date: Date) => {
    return events.filter(event => {
      const eventDate = new Date(event.start_date);
      return (
        eventDate.getDate() === date.getDate() &&
        eventDate.getMonth() === date.getMonth() &&
        eventDate.getFullYear() === date.getFullYear()
      );
    });
  };

  const currentDayEvents = date ? getEventsByDate(date) : [];

  return (
    <Card className="p-6 bg-gray-800/50 backdrop-blur-xl border-gray-700/50">
      <h3 className="text-xl font-semibold text-white mb-4 flex items-center">
        <CalendarClock className="w-5 h-5 mr-2 text-blue-400" />
        Eventos del Día
      </h3>
      
      {date && (
        <p className="text-gray-400 mb-4">
          {format(date, "EEEE, d 'de' MMMM 'de' yyyy", { locale: es })}
        </p>
      )}

      <div className="space-y-4">
        {currentDayEvents.length > 0 ? (
          currentDayEvents.map((event) => (
            <motion.div
              key={event.id}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              className="p-4 rounded-lg bg-gray-700/50 border border-gray-600"
            >
              <h4 className="font-medium text-white">{event.title}</h4>
              <p className="text-sm text-gray-400 mt-1">{event.description}</p>
              <div className="flex items-center mt-2 text-sm text-gray-500">
                <CalendarRange className="w-4 h-4 mr-1" />
                {format(new Date(event.start_date), "HH:mm")} - 
                {format(new Date(event.end_date), "HH:mm")}
              </div>
            </motion.div>
          ))
        ) : (
          <div className="text-center py-8">
            <CalendarX className="w-12 h-12 text-gray-500 mx-auto mb-2" />
            <p className="text-gray-500">No hay eventos programados para este día</p>
          </div>
        )}
      </div>
    </Card>
  );
};