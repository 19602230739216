import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { Button } from "@/components/ui/button";
import { Mail } from "lucide-react";

interface EmailNotificationFieldsProps {
  sendEmail: boolean;
  onSendEmailChange: (checked: boolean) => void;
  additionalEmails: string[];
  newEmail: string;
  onNewEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onAddEmail: () => void;
  onRemoveEmail: (email: string) => void;
}

export const EmailNotificationFields = ({
  sendEmail,
  onSendEmailChange,
  additionalEmails,
  newEmail,
  onNewEmailChange,
  onAddEmail,
  onRemoveEmail,
}: EmailNotificationFieldsProps) => {
  return (
    <div className="space-y-4 border rounded-lg p-4 bg-gray-800/50">
      <div className="flex items-center space-x-2">
        <Switch
          id="send-email"
          checked={sendEmail}
          onCheckedChange={onSendEmailChange}
        />
        <Label htmlFor="send-email">Enviar notificaciones por email</Label>
      </div>

      {sendEmail && (
        <div className="space-y-4">
          <div className="flex gap-2">
            <Input
              type="email"
              value={newEmail}
              onChange={onNewEmailChange}
              placeholder="Añadir email"
              className="flex-1 bg-gray-800/50 border-gray-700"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  onAddEmail();
                }
              }}
            />
            <Button 
              type="button" 
              variant="outline"
              onClick={onAddEmail}
              className="bg-gray-800/50 border-gray-700"
            >
              <Mail className="w-4 h-4 mr-2" />
              Añadir
            </Button>
          </div>

          {additionalEmails.length > 0 && (
            <div className="space-y-2">
              {additionalEmails.map((email) => (
                <div key={email} className="flex items-center gap-2 bg-gray-800/50 p-2 rounded border border-gray-700">
                  <span className="flex-1 text-sm">{email}</span>
                  <Button
                    type="button"
                    variant="ghost"
                    size="sm"
                    onClick={() => onRemoveEmail(email)}
                    className="hover:bg-gray-700"
                  >
                    ×
                  </Button>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};