import { useState, useEffect } from "react";
import { Card } from "@/components/ui/card";
import { Shield } from "lucide-react";
import { motion } from "framer-motion";
import { supabase } from "@/integrations/supabase/client";
import { IssueFilters } from "./components/IssueFilters";
import { IssueList } from "./components/IssueList";
import { IssueForm } from "./components/IssueForm";

interface Issue {
  id: string;
  title: string;
  description: string;
  status: string;
  reporter: string;
  affected_users: string;
  market: string;
  created_at: string;
}

interface IssuesByMarket {
  [key: string]: Issue[];
}

export const PendingIssuesForm = () => {
  const [issues, setIssues] = useState<Issue[]>([]);
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    status: "",
    reporter: "",
    market: "",
  });

  useEffect(() => {
    fetchIssues();

    const channel = supabase
      .channel('pending-issues-changes')
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'pending_issues' },
        (payload) => {
          console.log('Real-time update:', payload);
          fetchIssues();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [filters]);

  const fetchIssues = async () => {
    console.log('Fetching issues with filters:', filters);
    let query = supabase
      .from('pending_issues')
      .select('*')
      .order('created_at', { ascending: false });

    if (filters.startDate) {
      query = query.gte('created_at', filters.startDate);
    }
    if (filters.endDate) {
      query = query.lte('created_at', filters.endDate);
    }
    if (filters.status) {
      query = query.eq('status', filters.status);
    }
    if (filters.reporter) {
      query = query.ilike('reporter', `%${filters.reporter}%`);
    }
    if (filters.market) {
      query = query.eq('market', filters.market);
    }

    const { data, error } = await query;
    
    if (error) {
      console.error('Error fetching issues:', error);
    } else {
      console.log('Fetched issues:', data);
      setIssues(data || []);
    }
  };

  const groupIssuesByMarket = (issues: Issue[]): IssuesByMarket => {
    return issues.reduce((acc, issue) => {
      const market = issue.market?.toLowerCase() === 'españa' ? 'iberia' : (issue.market?.toLowerCase() || 'sin mercado');
      if (!acc[market]) {
        acc[market] = [];
      }
      acc[market].push(issue);
      return acc;
    }, {} as IssuesByMarket);
  };

  const issuesByMarket = groupIssuesByMarket(issues);

  return (
    <div className="container max-w-4xl mx-auto space-y-8 py-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Card className="backdrop-blur-lg bg-white/5 border-white/20 shadow-2xl p-6">
          <h2 className="text-2xl font-semibold text-white mb-6 flex items-center gap-2">
            <Shield className="w-6 h-6 text-blue-400" />
            Reportar Nuevo Asunto
          </h2>
          <IssueForm />
        </Card>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <IssueFilters filters={filters} setFilters={setFilters} />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.3 }}
        className="space-y-8"
      >
        <IssueList issues={issues} onUpdate={fetchIssues} />
      </motion.div>
    </div>
  );
};