import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";

export const useEmpleadoAccesos = (empleadoId: string | undefined) => {
  return useQuery({
    queryKey: ["empleadoAccesos", empleadoId],
    queryFn: async () => {
      if (!empleadoId) return null;

      console.log("Fetching missing accesos for empleado:", empleadoId);
      const { data, error } = await supabase
        .rpc('get_missing_accesos', { p_empleado_id: empleadoId });

      if (error) {
        console.error("Error fetching missing accesos:", error);
        throw error;
      }

      console.log("Missing accesos:", data);
      return data;
    },
    enabled: !!empleadoId,
  });
};