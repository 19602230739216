import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";

const PREDEFINED_ITEMS = [
  { id: 'bolsa', label: 'Bolsa/Mochila' },
  { id: 'cascos', label: 'Cascos Jabra' },
  { id: 'docking', label: 'Docking Station' },
  { id: 'pantalla', label: 'Pantalla' },
  { id: 'laptop', label: 'Portátil' },
  { id: 'telefono', label: 'Teléfono' },
  { id: 'modem_4g', label: 'Modem 4G' },
];

interface AssetCollectionItemsProps {
  selectedItems: string[];
  onItemChange: (items: string[]) => void;
  otherItems: string;
  onOtherItemsChange: (value: string) => void;
}

export const AssetCollectionItems = ({
  selectedItems,
  onItemChange,
  otherItems,
  onOtherItemsChange
}: AssetCollectionItemsProps) => {
  const handleItemToggle = (itemId: string) => {
    if (selectedItems.includes(itemId)) {
      onItemChange(selectedItems.filter(id => id !== itemId));
    } else {
      onItemChange([...selectedItems, itemId]);
    }
  };

  return (
    <div className="space-y-4">
      <Label>Items a Recoger</Label>
      <div className="grid grid-cols-2 gap-4">
        {PREDEFINED_ITEMS.map((item) => (
          <div key={item.id} className="flex items-center space-x-2">
            <Checkbox
              id={item.id}
              checked={selectedItems.includes(item.id)}
              onCheckedChange={() => handleItemToggle(item.id)}
            />
            <Label htmlFor={item.id} className="cursor-pointer">
              {item.label}
            </Label>
          </div>
        ))}
      </div>

      <div className="space-y-2">
        <Label htmlFor="otros">Otro Material</Label>
        <Input
          id="otros"
          value={otherItems}
          onChange={(e) => onOtherItemsChange(e.target.value)}
          placeholder="Especificar otro material..."
          className="bg-gray-800/50 border-gray-700"
        />
      </div>
    </div>
  );
};