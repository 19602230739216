import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Label } from "@/components/ui/label";
import { Filter } from "lucide-react";

interface IssueFiltersProps {
  filters: {
    startDate: string;
    endDate: string;
    status: string;
    reporter: string;
    market: string;
  };
  setFilters: (filters: any) => void;
}

export const IssueFilters = ({ filters, setFilters }: IssueFiltersProps) => {
  return (
    <div className="bg-gray-800/50 rounded-lg p-6 space-y-6">
      <div className="flex items-center gap-2 mb-4">
        <Filter className="w-5 h-5 text-blue-400" />
        <h2 className="text-xl font-semibold text-white">Filtros</h2>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="space-y-2">
          <Label>Fecha inicio</Label>
          <Input
            type="date"
            value={filters.startDate}
            onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
            className="bg-gray-700/50 border-gray-600"
          />
        </div>
        
        <div className="space-y-2">
          <Label>Fecha fin</Label>
          <Input
            type="date"
            value={filters.endDate}
            onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
            className="bg-gray-700/50 border-gray-600"
          />
        </div>

        <div className="space-y-2">
          <Label>Mercado</Label>
          <Select
            value={filters.market}
            onValueChange={(value) => setFilters({ ...filters, market: value })}
          >
            <SelectTrigger className="bg-gray-700/50 border-gray-600">
              <SelectValue placeholder="Seleccionar mercado" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">Todos los mercados</SelectItem>
              <SelectItem value="españa">España</SelectItem>
              <SelectItem value="italia">Italia</SelectItem>
              <SelectItem value="portugal">Portugal</SelectItem>
              <SelectItem value="francia">Francia</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  );
};