
import { useNavigate } from "react-router-dom";
import { LoginForm } from "@/components/auth/LoginForm";
import { AccessRequestForm } from "@/components/access/AccessRequestForm";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ChatBot } from "@/components/help/ChatBot";
import { Card } from "@/components/ui/card";
import { motion } from "framer-motion";
import { 
  Shield, LogIn, Key, AlertCircle, Bell, 
  CircuitBoard, Ticket, Sparkles,
  Laptop, Database, Activity
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { PublicTicketForm } from "@/components/tickets/PublicTicketForm";

const Login = () => {
  const navigate = useNavigate();

  const handlePendingIssuesAccess = () => {
    const username = prompt("Usuario:");
    const password = prompt("Contraseña:");
    
    if (username === "msxi" && password === "msxi") {
      navigate("/pending-issues");
    } else {
      alert("Credenciales incorrectas");
    }
  };

  // Features section data
  const features = [
    {
      icon: <Laptop className="h-6 w-6 text-blue-400" />,
      title: "Gestión de Inventario",
      description: "Control completo de equipos y activos IT"
    },
    {
      icon: <Database className="h-6 w-6 text-purple-400" />,
      title: "Acceso a Plataformas",
      description: "Administración centralizada de credenciales"
    },
    {
      icon: <Activity className="h-6 w-6 text-green-400" />,
      title: "Monitorización",
      description: "Seguimiento en tiempo real de incidencias"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 relative overflow-hidden">
      {/* Enhanced animated background elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -inset-[10px] opacity-30">
          <div className="absolute top-0 -left-4 w-96 h-96 bg-blue-500/30 rounded-full mix-blend-multiply filter blur-3xl animate-blob"></div>
          <div className="absolute top-0 -right-4 w-96 h-96 bg-purple-500/30 rounded-full mix-blend-multiply filter blur-3xl animate-blob animation-delay-2000"></div>
          <div className="absolute -bottom-8 left-20 w-96 h-96 bg-indigo-500/30 rounded-full mix-blend-multiply filter blur-3xl animate-blob animation-delay-4000"></div>
          <div className="absolute bottom-20 right-20 w-64 h-64 bg-cyan-500/20 rounded-full mix-blend-multiply filter blur-3xl animate-blob animation-delay-3000"></div>
        </div>
      </div>

      <div className="container max-w-7xl mx-auto relative z-10 px-4 py-12 md:py-16">
        <div className="flex flex-col lg:flex-row items-center gap-8 lg:gap-16">
          {/* Left side: Hero section and features */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="flex-1 space-y-8"
          >
            {/* Header Section */}
            <div className="text-left space-y-4">
              <div className="inline-flex items-center space-x-3 mb-6 glass-panel p-3 rounded-lg">
                <div className="relative">
                  <CircuitBoard className="w-10 h-10 text-blue-400 animate-pulse" />
                  <Sparkles className="w-4 h-4 text-yellow-400 absolute -top-1 -right-1 animate-spin-slow" />
                </div>
                <span className="text-sm font-medium text-gray-400">Portal IT V1</span>
              </div>
              
              <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold tracking-tight bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-purple-400 to-indigo-400 leading-tight">
                Portal IT <br />
                <span className="text-white">MSX Valencia</span>
              </h1>
              
              <p className="text-gray-400 text-lg max-w-[600px] leading-relaxed mt-4">
                Plataforma centralizada para gestión de recursos IT, soporte técnico y administración de sistemas
              </p>
              
              {/* Quick Access Buttons with enhanced styling */}
              <div className="flex flex-wrap items-start gap-4 mt-8">
                <Button
                  variant="outline"
                  className="glass-panel bg-red-500/10 hover:bg-red-500/20 text-red-400 border-red-500/30 transition-all hover:scale-105 backdrop-blur-lg"
                  onClick={handlePendingIssuesAccess}
                >
                  <AlertCircle className="w-4 h-4 mr-2" />
                  Asuntos Pendientes
                </Button>

                <Link 
                  to="/service-alerts" 
                  className="inline-flex items-center px-4 py-2 rounded-lg glass-panel bg-blue-500/10 hover:bg-blue-500/20 text-blue-400 border border-blue-500/30 transition-all hover:scale-105 backdrop-blur-lg"
                >
                  <Bell className="w-4 h-4 mr-2" />
                  Alertas de Servicio
                </Link>
              </div>
            </div>
            
            {/* Features Grid */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-12">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4, delay: 0.2 + index * 0.1 }}
                  className="glass-panel rounded-xl p-5 backdrop-blur-md hover:bg-white/10 transition-all duration-300"
                >
                  <div className="mb-3">{feature.icon}</div>
                  <h3 className="text-lg font-semibold text-white mb-2">{feature.title}</h3>
                  <p className="text-gray-400 text-sm">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
          
          {/* Right side: Auth card */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            className="w-full lg:w-[450px]"
          >
            <Card className="glass-panel backdrop-blur-lg border-white/20 shadow-2xl overflow-hidden">
              <Tabs defaultValue="login" className="w-full">
                <TabsList className="grid w-full grid-cols-3 bg-white/5">
                  <TabsTrigger 
                    value="login"
                    className="data-[state=active]:bg-white/20 data-[state=active]:backdrop-blur-lg text-white hover:text-white/90 transition-all duration-300 flex items-center space-x-2 hover:bg-white/10"
                  >
                    <LogIn className="w-4 h-4" />
                    <span>Login</span>
                  </TabsTrigger>
                  <TabsTrigger 
                    value="access-request"
                    className="data-[state=active]:bg-white/20 data-[state=active]:backdrop-blur-lg text-white hover:text-white/90 transition-all duration-300 flex items-center space-x-2 hover:bg-white/10"
                  >
                    <Key className="w-4 h-4" />
                    <span>Acceso</span>
                  </TabsTrigger>
                  <TabsTrigger 
                    value="create-ticket"
                    className="data-[state=active]:bg-white/20 data-[state=active]:backdrop-blur-lg text-white hover:text-white/90 transition-all duration-300 flex items-center space-x-2 hover:bg-white/10"
                  >
                    <Ticket className="w-4 h-4" />
                    <span>Ticket</span>
                  </TabsTrigger>
                </TabsList>
                <div className="p-6">
                  <TabsContent value="login" className="mt-0 space-y-4">
                    <LoginForm />
                  </TabsContent>
                  <TabsContent value="access-request" className="mt-0 space-y-4">
                    <AccessRequestForm />
                  </TabsContent>
                  <TabsContent value="create-ticket" className="mt-0 space-y-4">
                    <PublicTicketForm />
                  </TabsContent>
                </div>
              </Tabs>
            </Card>
            
            {/* Add a note about support */}
            <div className="text-center mt-4">
              <p className="text-sm text-gray-500">
                ¿Necesitas ayuda? Contacta con soporte IT 
                <span className="text-blue-400 ml-1">soporte@msxvalencia.com</span>
              </p>
            </div>
          </motion.div>
        </div>
      </div>

      <ChatBot />
    </div>
  );
};

export default Login;
