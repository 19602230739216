import { useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { IssueCard } from "./issue/IssueCard";
import { CommentDialog } from "./CommentDialog";

export interface Issue {
  id: string;
  title: string;
  description: string;
  status: string;
  reporter: string;
  affected_users: string;
  created_at: string;
  market: string;
}

interface IssuesByMarket {
  [key: string]: Issue[];
}

interface IssueListProps {
  issues: Issue[];
  onUpdate: () => void;
}

const getMarketGradient = (market: string) => {
  const gradients: Record<string, string> = {
    'iberia': 'from-red-500/20 via-yellow-500/20 to-red-500/20', // España
    'italia': 'from-green-500/20 via-white/10 to-red-500/20', // Italia
    'francia': 'from-blue-500/20 via-white/10 to-red-500/20', // Francia
    'portugal': 'from-green-500/20 via-red-500/20 to-green-500/20', // Portugal
  };
  return gradients[market.toLowerCase()] || 'from-gray-500/20 to-gray-600/20';
};

const getMarketFlag = (market: string) => {
  const flags: Record<string, string> = {
    'iberia': '🇪🇸',
    'italia': '🇮🇹',
    'francia': '🇫🇷',
    'portugal': '🇵🇹',
  };
  return flags[market.toLowerCase()] || '🌍';
};

export const IssueList = ({ issues, onUpdate }: IssueListProps) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState<Issue | null>(null);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showCommentDialog, setShowCommentDialog] = useState(false);
  const [editForm, setEditForm] = useState({
    title: "",
    description: "",
  });

  const groupIssuesByMarket = (issues: Issue[]): IssuesByMarket => {
    return issues.reduce((acc, issue) => {
      const market = issue.market?.toLowerCase() === 'españa' ? 'iberia' : (issue.market?.toLowerCase() || 'sin mercado');
      if (!acc[market]) {
        acc[market] = [];
      }
      acc[market].push(issue);
      return acc;
    }, {} as IssuesByMarket);
  };

  const updateStatus = async (id: string, status: string) => {
    setIsUpdating(true);
    try {
      const { error } = await supabase
        .from('pending_issues')
        .update({ status })
        .eq('id', id);

      if (error) throw error;
      
      toast.success("Estado actualizado correctamente");
      onUpdate();
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error("Error al actualizar el estado");
    } finally {
      setIsUpdating(false);
    }
  };

  const handleEdit = async () => {
    if (!selectedIssue) return;
    
    try {
      const { error } = await supabase
        .from('pending_issues')
        .update({
          title: editForm.title,
          description: editForm.description,
          updated_at: new Date().toISOString()
        })
        .eq('id', selectedIssue.id);

      if (error) throw error;

      toast.success("Asunto actualizado correctamente");
      setShowEditDialog(false);
      onUpdate();
    } catch (error) {
      console.error('Error updating issue:', error);
      toast.error("Error al actualizar el asunto");
    }
  };

  const handleComment = async (comment: string, emails: string[]) => {
    if (!selectedIssue) return;
    
    try {
      const { error: notifyError } = await supabase.functions.invoke('notify-pending-issue', {
        body: { 
          issue: {
            ...selectedIssue,
            comment,
            notifyEmails: emails
          }
        }
      });

      if (notifyError) throw notifyError;

      toast.success("Notificación enviada correctamente");
      onUpdate();
    } catch (error) {
      console.error('Error sending notification:', error);
      toast.error("Error al enviar la notificación");
      throw error;
    }
  };

  const issuesByMarket = groupIssuesByMarket(issues);

  return (
    <div className="space-y-8">
      {Object.entries(issuesByMarket).map(([market, marketIssues]) => (
        <div key={market} className="space-y-4">
          <div className={`relative overflow-hidden rounded-lg bg-gradient-to-r ${getMarketGradient(market)} p-6 shadow-lg border border-gray-700/50 backdrop-blur-sm`}>
            <div className="relative flex items-center gap-4">
              <span className="text-4xl filter drop-shadow-lg">{getMarketFlag(market)}</span>
              <div>
                <h2 className="text-2xl font-semibold text-white">
                  {market.charAt(0).toUpperCase() + market.slice(1)}
                  <span className="ml-2 text-sm font-normal text-gray-400 bg-gray-700/50 px-2 py-1 rounded-full">
                    {marketIssues.length} {marketIssues.length === 1 ? 'asunto' : 'asuntos'}
                  </span>
                </h2>
              </div>
            </div>
          </div>
          
          <div className="space-y-4">
            {marketIssues.map((issue) => (
              <IssueCard
                key={issue.id}
                issue={issue}
                onStatusUpdate={updateStatus}
                onEdit={(issue) => {
                  setSelectedIssue(issue);
                  setEditForm({
                    title: issue.title,
                    description: issue.description
                  });
                  setShowEditDialog(true);
                }}
                onComment={(issue) => {
                  setSelectedIssue(issue);
                  setShowCommentDialog(true);
                }}
                isUpdating={isUpdating}
              />
            ))}
          </div>
        </div>
      ))}

      {/* Edit Dialog */}
      <Dialog open={showEditDialog} onOpenChange={setShowEditDialog}>
        <DialogContent className="bg-gray-800/95 backdrop-blur-md border-gray-700">
          <DialogHeader>
            <DialogTitle className="text-lg font-semibold text-white">Editar Asunto</DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <div>
              <label className="text-sm font-medium text-gray-300">Título</label>
              <Input
                value={editForm.title}
                onChange={(e) => setEditForm(prev => ({ ...prev, title: e.target.value }))}
                className="bg-gray-700/50 border-gray-600"
              />
            </div>
            <div>
              <label className="text-sm font-medium text-gray-300">Descripción</label>
              <Textarea
                value={editForm.description}
                onChange={(e) => setEditForm(prev => ({ ...prev, description: e.target.value }))}
                className="bg-gray-700/50 border-gray-600"
                rows={5}
              />
            </div>
            <Button onClick={handleEdit} className="w-full">Guardar Cambios</Button>
          </div>
        </DialogContent>
      </Dialog>

      {/* Comment Dialog */}
      <CommentDialog
        open={showCommentDialog}
        onOpenChange={setShowCommentDialog}
        onSubmit={handleComment}
        title={selectedIssue?.title || ''}
        issueId={selectedIssue?.id || ''}
      />
    </div>
  );
};