import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { IssueFormFields } from "./IssueFormFields";

export const IssueForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    reporter: "",
    affected_users: "",
    market: "españa",
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      console.log('Submitting issue with data:', formData);
      const { error } = await supabase
        .from('pending_issues')
        .insert([{
          ...formData,
          status: 'pending'
        }]);

      if (error) throw error;

      toast.success("Asunto reportado correctamente");
      setFormData({
        title: "",
        description: "",
        reporter: "",
        affected_users: "",
        market: "españa",
      });
    } catch (error) {
      console.error('Error al reportar el asunto:', error);
      toast.error("Error al reportar el asunto");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <IssueFormFields formData={formData} setFormData={setFormData} />

      <Button 
        type="submit" 
        className="w-full bg-blue-600 hover:bg-blue-700"
        disabled={isSubmitting}
      >
        {isSubmitting ? (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            Enviando...
          </>
        ) : (
          "Reportar Asunto"
        )}
      </Button>
    </form>
  );
};