import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { motion, AnimatePresence } from "framer-motion";
import { Loader2 } from "lucide-react";

interface AlertReportSectionProps {
  isLoginPage: boolean;
  hasReported: boolean;
  isReporting: boolean;
  cdsid: string;
  onCdsidChange: (value: string) => void;
  onReport: () => void;
}

export const AlertReportSection = ({
  isLoginPage,
  hasReported,
  isReporting,
  cdsid,
  onCdsidChange,
  onReport
}: AlertReportSectionProps) => {
  if (!isLoginPage) return null;

  return (
    <AnimatePresence>
      {hasReported ? (
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
        >
          <Badge variant="secondary" className="bg-green-900/50 text-green-300 border-green-800 flex items-center gap-2">
            <div className="w-2 h-2 rounded-full bg-green-400 animate-pulse" />
            Gracias por reportar esta alerta
          </Badge>
        </motion.div>
      ) : (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="flex flex-col gap-2 items-end"
        >
          <Input
            type="text"
            placeholder="Tu CDSID"
            value={cdsid}
            onChange={(e) => onCdsidChange(e.target.value)}
            className="w-[200px] bg-gray-800/50 border-gray-700 text-gray-200 placeholder:text-gray-500 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
          />
          <Button
            variant="outline"
            size="sm"
            onClick={onReport}
            disabled={isReporting}
            className="bg-gray-800/50 hover:bg-gray-700/50 text-gray-300 border-gray-700 hover:text-white transition-all duration-200 flex items-center gap-2"
          >
            {isReporting ? (
              <>
                <Loader2 className="w-4 h-4 animate-spin" />
                Reportando...
              </>
            ) : (
              'Me afecta'
            )}
          </Button>
        </motion.div>
      )}
    </AnimatePresence>
  );
};