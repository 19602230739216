
import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { EmpleadoEditDialog } from "./EmpleadoEditDialog";
import { EmpleadoInfo } from "./EmpleadoInfo";
import { EmpleadoActions } from "./EmpleadoActions";
import { BajaEmpleadoDialog } from "./BajaEmpleadoDialog";
import { motion } from "framer-motion";
import { Edit, Trash, User } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { EmpleadoAIAnalysis } from "./EmpleadoAIAnalysis";

interface EmpleadoCardProps {
  empleado: any;
  onUpdate: () => void;
}

export const EmpleadoCard = ({ empleado, onUpdate }: EmpleadoCardProps) => {
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showBajaDialog, setShowBajaDialog] = useState(false);
  const [tipoBaja, setTipoBaja] = useState<'temporal' | 'total'>('temporal');
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      
      const { error } = await supabase
        .from('empleados')
        .delete()
        .eq('id', empleado.id);

      if (error) throw error;

      toast.success("Empleado eliminado correctamente");
      onUpdate();
    } catch (error: any) {
      console.error("Error deleting employee:", error);
      toast.error("Error al eliminar el empleado: " + error.message);
    } finally {
      setIsDeleting(false);
      setShowDeleteDialog(false);
    }
  };

  const handleBajaTemporal = () => {
    setTipoBaja('temporal');
    setShowBajaDialog(true);
  };

  const handleBajaTotal = () => {
    setTipoBaja('total');
    setShowBajaDialog(true);
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      whileHover={{ scale: 1.02 }}
      transition={{ duration: 0.2 }}
    >
      <Card className="bg-gray-800 border-gray-700 p-6 hover:bg-gray-750 transition-all duration-300 shadow-xl">
        <div className="flex items-start justify-between mb-4">
          <div className="flex items-center gap-3">
            <div className="bg-gray-700 p-2 rounded-full">
              <User className="h-5 w-5 text-blue-400" />
            </div>
            <div>
              <h3 className="text-lg font-semibold text-white">
                {empleado.nombre} {empleado.apellidos}
              </h3>
              <p className="text-sm text-gray-400">{empleado.cdsid}</p>
            </div>
          </div>
          <div className="flex gap-2">
            <Button
              variant="ghost"
              size="icon"
              onClick={() => setShowEditDialog(true)}
              className="hover:bg-gray-700"
            >
              <Edit className="h-4 w-4 text-blue-400" />
            </Button>
            <Button
              variant="ghost"
              size="icon"
              onClick={() => setShowDeleteDialog(true)}
              className="hover:bg-gray-700"
            >
              <Trash className="h-4 w-4 text-red-400" />
            </Button>
            <EmpleadoActions 
              empleado={empleado}
              onBajaTemporalClick={handleBajaTemporal}
              onBajaTotalClick={handleBajaTotal}
            />
          </div>
        </div>

        <EmpleadoInfo empleado={empleado} />
        
        <div className="mt-4 pt-4 border-t border-gray-700">
          <EmpleadoAIAnalysis empleado={empleado} />
        </div>

        <EmpleadoEditDialog
          empleado={empleado}
          open={showEditDialog}
          onOpenChange={setShowEditDialog}
          onUpdate={() => {
            onUpdate();
            toast.success("Empleado actualizado correctamente");
          }}
        />

        <AlertDialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>¿Estás seguro?</AlertDialogTitle>
              <AlertDialogDescription>
                Esta acción no se puede deshacer. Se eliminará permanentemente el empleado {empleado.nombre} {empleado.apellidos}.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancelar</AlertDialogCancel>
              <AlertDialogAction 
                onClick={handleDelete} 
                className="bg-red-500 hover:bg-red-600"
                disabled={isDeleting}
              >
                {isDeleting ? "Eliminando..." : "Eliminar"}
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>

        <BajaEmpleadoDialog
          empleado={empleado}
          tipo={tipoBaja}
          open={showBajaDialog}
          onOpenChange={setShowBajaDialog}
          onUpdate={onUpdate}
        />
      </Card>
    </motion.div>
  );
};
