import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

interface BajaEmpleadoDialogProps {
  empleado: any;
  tipo: 'temporal' | 'total';
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onUpdate: () => void;
}

export const BajaEmpleadoDialog = ({
  empleado,
  tipo,
  open,
  onOpenChange,
  onUpdate,
}: BajaEmpleadoDialogProps) => {
  const [formData, setFormData] = useState({
    fecha_inicio: "",
    fecha_fin: "",
    direccion_recogida: "",
    telefono_contacto: "",
    material_devolver: {
      laptop: true,
      monitor: false,
      teclado: false,
      raton: false,
      dock: false,
      otros: ""
    }
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      const updates = {
        empleado_id: empleado.id,
        fecha_inicio: formData.fecha_inicio,
        fecha_fin: tipo === 'temporal' ? formData.fecha_fin : null,
        direccion_recogida: formData.direccion_recogida,
        telefono_contacto: formData.telefono_contacto,
        material_devolver: formData.material_devolver,
        estado: 'pendiente'
      };

      const { error } = await supabase
        .from('bajas_empleados')
        .insert(updates);

      if (error) throw error;

      // Update empleado status
      await supabase
        .from('empleados')
        .update({ 
          estado: tipo === 'temporal' ? 'baja_temporal' : 'inactivo' 
        })
        .eq('id', empleado.id);

      toast.success(`Baja ${tipo} registrada correctamente`);
      onUpdate();
      onOpenChange(false);
    } catch (error: any) {
      console.error('Error registering baja:', error);
      toast.error("Error al registrar la baja: " + error.message);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="bg-gray-800 text-white">
        <DialogHeader>
          <DialogTitle>
            {tipo === 'temporal' ? 'Baja Temporal' : 'Baja Total'} - {empleado.nombre} {empleado.apellidos}
          </DialogTitle>
        </DialogHeader>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <label>Fecha de Inicio</label>
            <Input
              type="date"
              value={formData.fecha_inicio}
              onChange={(e) => setFormData({ ...formData, fecha_inicio: e.target.value })}
              className="bg-gray-700"
              required
            />
          </div>

          {tipo === 'temporal' && (
            <div className="space-y-2">
              <label>Fecha de Fin</label>
              <Input
                type="date"
                value={formData.fecha_fin}
                onChange={(e) => setFormData({ ...formData, fecha_fin: e.target.value })}
                className="bg-gray-700"
                required
              />
            </div>
          )}

          <div className="space-y-2">
            <label>Dirección de Recogida</label>
            <Textarea
              value={formData.direccion_recogida}
              onChange={(e) => setFormData({ ...formData, direccion_recogida: e.target.value })}
              className="bg-gray-700"
              placeholder="Dirección completa..."
              required
            />
          </div>

          <div className="space-y-2">
            <label>Teléfono de Contacto</label>
            <Input
              value={formData.telefono_contacto}
              onChange={(e) => setFormData({ ...formData, telefono_contacto: e.target.value })}
              className="bg-gray-700"
              placeholder="+34..."
              required
            />
          </div>

          <div className="space-y-2">
            <label>Material a Devolver</label>
            <Textarea
              value={formData.material_devolver.otros}
              onChange={(e) => setFormData({ 
                ...formData, 
                material_devolver: {
                  ...formData.material_devolver,
                  otros: e.target.value
                }
              })}
              className="bg-gray-700"
              placeholder="Detalles del material a devolver..."
              required
            />
          </div>

          <div className="flex justify-end gap-2">
            <Button variant="outline" onClick={() => onOpenChange(false)}>
              Cancelar
            </Button>
            <Button type="submit">Tramitar Baja</Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};