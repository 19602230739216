import { motion } from "framer-motion";
import { ArrowLeft } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { AccessTrackingSection } from "@/components/access/new-hire/AccessTrackingSection";

const AccessTracking = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900">
      <div className="container mx-auto p-6 pt-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="space-y-6"
        >
          <div className="flex items-center gap-4 mb-6">
            <Button
              variant="ghost"
              size="icon"
              onClick={() => navigate(-1)}
              className="hover:bg-gray-800"
            >
              <ArrowLeft className="h-5 w-5" />
            </Button>
            <div>
              <h1 className="text-2xl font-semibold text-white">Panel de Seguimiento</h1>
              <p className="text-sm text-gray-400">Monitorización de accesos y nuevas incorporaciones</p>
            </div>
          </div>
          
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.2 }}
            className="w-full bg-gradient-to-br from-gray-900/50 to-gray-800/50 rounded-lg border border-gray-700/50 shadow-xl backdrop-blur-sm overflow-hidden"
          >
            <AccessTrackingSection />
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default AccessTracking;