import { Input } from "@/components/ui/input";
import { Search } from "lucide-react";
import { motion } from "framer-motion";

interface EmpleadosFiltersProps {
  searchTerm: string;
  onSearchChange: (value: string) => void;
}

export const EmpleadosFilters = ({ searchTerm, onSearchChange }: EmpleadosFiltersProps) => {
  return (
    <motion.div 
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="relative"
    >
      <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-500" />
      <Input
        type="search"
        placeholder="Buscar por nombre, CDSID, email o mercado..."
        value={searchTerm}
        onChange={(e) => onSearchChange(e.target.value)}
        className="pl-10 bg-gray-800/50 border-gray-700 text-gray-200 placeholder:text-gray-500"
      />
    </motion.div>
  );
};