import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Loader2 } from "lucide-react";
import { ticketCategories } from "./TicketCategories";
import { EmailNotificationFields } from "./form/fields/EmailNotificationFields";

interface TicketDetailsFormProps {
  title: string;
  setTitle: (value: string) => void;
  description: string;
  setDescription: (value: string) => void;
  priority: string;
  setPriority: (value: string) => void;
  category: string;
  setCategory: (value: string) => void;
  status: string;
  setStatus: (value: string) => void;
  affected_cdsid?: string;
  setAffectedCdsid?: (value: string) => void;
  sendEmail?: boolean;
  setSendEmail?: (value: boolean) => void;
  customEmailSubject?: string;
  setCustomEmailSubject?: (value: string) => void;
  additionalEmails?: string[];
  setAdditionalEmails?: (value: string[]) => void;
  onCancel: () => void;
  onSubmit: () => void;
  isSubmitting: boolean;
}

export const TicketDetailsForm = ({
  title,
  setTitle,
  description,
  setDescription,
  priority,
  setPriority,
  category,
  setCategory,
  status,
  setStatus,
  affected_cdsid,
  setAffectedCdsid,
  sendEmail = false,
  setSendEmail,
  customEmailSubject = "",
  setCustomEmailSubject,
  additionalEmails = [],
  setAdditionalEmails,
  onCancel,
  onSubmit,
  isSubmitting
}: TicketDetailsFormProps) => {
  console.log('Rendering TicketDetailsForm with notification props:', { 
    sendEmail, 
    setSendEmail, 
    customEmailSubject, 
    setCustomEmailSubject, 
    additionalEmails, 
    setAdditionalEmails 
  });

  return (
    <div className="space-y-4 animate-in slide-in-from-top duration-300">
      <div>
        <label className="text-sm font-medium">Título</label>
        <Input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="bg-gray-700/50 border-gray-600"
          disabled={isSubmitting}
        />
      </div>

      <div>
        <label className="text-sm font-medium">Descripción</label>
        <Textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="bg-gray-700/50 border-gray-600"
          rows={4}
          disabled={isSubmitting}
        />
      </div>

      <div className="grid grid-cols-3 gap-4">
        <div>
          <label className="text-sm font-medium">Prioridad</label>
          <Select value={priority} onValueChange={setPriority} disabled={isSubmitting}>
            <SelectTrigger className="bg-gray-700/50 border-gray-600">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="low">Baja</SelectItem>
              <SelectItem value="medium">Media</SelectItem>
              <SelectItem value="high">Alta</SelectItem>
              <SelectItem value="urgent">Urgente</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <label className="text-sm font-medium">Categoría</label>
          <Select value={category} onValueChange={setCategory} disabled={isSubmitting}>
            <SelectTrigger className="bg-gray-700/50 border-gray-600">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {Object.entries(ticketCategories).map(([key, { label }]) => (
                <SelectItem key={key} value={key}>{label}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div>
          <label className="text-sm font-medium">Estado</label>
          <Select value={status} onValueChange={setStatus} disabled={isSubmitting}>
            <SelectTrigger className="bg-gray-700/50 border-gray-600">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="open">Abierto</SelectItem>
              <SelectItem value="in_progress">En Progreso</SelectItem>
              <SelectItem value="closed">Cerrado</SelectItem>
              <SelectItem value="resolved">Resuelto</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      {setAffectedCdsid && (
        <div>
          <label className="text-sm font-medium">CDSID del Afectado</label>
          <Input
            value={affected_cdsid}
            onChange={(e) => setAffectedCdsid(e.target.value)}
            className="bg-gray-700/50 border-gray-600"
            placeholder="CDSID del usuario afectado"
            disabled={isSubmitting}
          />
        </div>
      )}

      <EmailNotificationFields
        sendEmail={sendEmail}
        setSendEmail={setSendEmail}
        customEmailSubject={customEmailSubject}
        setCustomEmailSubject={setCustomEmailSubject}
        additionalEmails={additionalEmails}
        setAdditionalEmails={setAdditionalEmails}
      />

      <div className="flex justify-end gap-2 pt-4">
        <Button
          variant="outline"
          onClick={onCancel}
          className="bg-gray-700 hover:bg-gray-600"
          disabled={isSubmitting}
        >
          Cancelar
        </Button>
        <Button
          onClick={onSubmit}
          disabled={isSubmitting}
          className="bg-blue-500 hover:bg-blue-600 min-w-[100px]"
        >
          {isSubmitting ? (
            <div className="flex items-center gap-2">
              <Loader2 className="h-4 w-4 animate-spin" />
              <span>Guardando...</span>
            </div>
          ) : (
            "Guardar Cambios"
          )}
        </Button>
      </div>
    </div>
  );
};