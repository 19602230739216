import { Dialog, DialogContent } from "@/components/ui/dialog";
import { TicketHeader } from "./TicketHeader";
import { TicketContent } from "./TicketContent";
import { TicketDetailsForm } from "./TicketDetailsForm";
import { TicketComments } from "./TicketComments";
import { useTicketDetails } from "./hooks/useTicketDetails";
import type { Ticket } from "@/hooks/useTickets";

interface TicketDetailsViewProps {
  ticket: Ticket;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const TicketDetailsView = ({ ticket, open, onOpenChange }: TicketDetailsViewProps) => {
  const {
    isEditing,
    setIsEditing,
    editedTitle,
    setEditedTitle,
    editedDescription,
    setEditedDescription,
    editedPriority,
    setPriority: setEditedPriority,
    editedCategory,
    setCategory: setEditedCategory,
    editedStatus,
    setStatus: setEditedStatus,
    editedAffectedCdsid,
    setAffectedCdsid: setEditedAffectedCdsid,
    sendEmail,
    setSendEmail,
    customEmailSubject,
    setCustomEmailSubject,
    additionalEmails,
    setAdditionalEmails,
    isSubmitting,
    comments,
    handleUpdate,
    handleDelete,
    handleAddComment
  } = useTicketDetails(ticket, onOpenChange);

  console.log('Rendering TicketDetailsView with notification options:', { sendEmail, customEmailSubject, additionalEmails });

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent 
        className="sm:max-w-[600px] bg-gray-800 text-white max-h-[90vh] overflow-y-auto"
        aria-describedby="ticket-content"
      >
        <div className="sticky top-0 z-10 bg-gray-800 pb-4 border-b border-gray-700">
          <TicketHeader 
            ticketNumber={ticket.ticket_number}
            onEdit={() => setIsEditing(!isEditing)}
            onDelete={handleDelete}
            isSubmitting={isSubmitting}
          />
        </div>

        <div className="space-y-4 py-4" id="ticket-content">
          {isEditing ? (
            <TicketDetailsForm
              title={editedTitle}
              setTitle={setEditedTitle}
              description={editedDescription}
              setDescription={setEditedDescription}
              priority={editedPriority}
              setPriority={setEditedPriority}
              category={editedCategory}
              setCategory={setEditedCategory}
              status={editedStatus}
              setStatus={setEditedStatus}
              affected_cdsid={editedAffectedCdsid}
              setAffectedCdsid={setEditedAffectedCdsid}
              sendEmail={sendEmail}
              setSendEmail={setSendEmail}
              customEmailSubject={customEmailSubject}
              setCustomEmailSubject={setCustomEmailSubject}
              additionalEmails={additionalEmails}
              setAdditionalEmails={setAdditionalEmails}
              onCancel={() => setIsEditing(false)}
              onSubmit={handleUpdate}
              isSubmitting={isSubmitting}
            />
          ) : (
            <TicketContent ticket={ticket} />
          )}

          <div className="mt-6">
            <TicketComments
              comments={comments}
              onAddComment={handleAddComment}
              isSubmitting={isSubmitting}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};