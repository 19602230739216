
import { useEffect } from "react";
import { MainContent } from "@/components/dashboard/MainContent";
import { ProtectedRoute } from "@/components/auth/ProtectedRoute";
import { DashboardSkeleton } from "@/components/dashboard/DashboardSkeleton";
import { DashboardError } from "@/components/dashboard/DashboardError";
import { useDashboardData } from "@/hooks/useDashboardData";
import { Button } from "@/components/ui/button";
import { RefreshCw } from "lucide-react";
import { motion } from "framer-motion";

const DashboardContent = () => {
  const {
    tickets,
    inventory,
    ticketStats,
    isLoading,
    error,
    refetchAll
  } = useDashboardData(true);

  useEffect(() => {
    console.log('DashboardContent mounted');
    
    // Set up polling for real-time updates (if needed)
    const pollingInterval = setInterval(() => {
      // Optional: Add condition to only refresh when tab is visible
      if (document.visibilityState === 'visible') {
        refetchAll();
      }
    }, 5 * 60 * 1000); // Refresh every 5 minutes
    
    return () => {
      console.log('DashboardContent unmounting');
      clearInterval(pollingInterval);
    };
  }, [refetchAll]);

  // Handle loading state
  if (isLoading) {
    console.log('Dashboard loading...');
    return <DashboardSkeleton />;
  }

  // Handle error state
  if (error) {
    console.error('Dashboard error:', error);
    return <DashboardError onRetry={refetchAll} />;
  }

  console.log('Dashboard rendering with data:', { tickets, inventory, ticketStats });
  
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto p-8 space-y-8"
    >
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold text-gray-100">Sistema Avanzado de Gestión IT V.1</h1>
        <Button 
          variant="outline" 
          size="sm" 
          onClick={refetchAll} 
          className="flex items-center gap-2"
        >
          <RefreshCw className="h-4 w-4" />
          Actualizar
        </Button>
      </div>
      
      <MainContent 
        inventory={inventory}
        tickets={tickets}
        ticketStats={ticketStats}
      />
    </motion.div>
  );
};

const Dashboard = () => {
  return (
    <ProtectedRoute>
      <DashboardContent />
    </ProtectedRoute>
  );
};

export default Dashboard;
