import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import type { Ticket } from "@/hooks/useTickets";

interface UpdateTicketData {
  title: string;
  description: string;
  priority: string;
  category: string;
  status: string;
  affected_cdsid: string;
  sendEmail: boolean;
  customEmailSubject: string;
  additionalEmails: string[];
}

export const useTicketActions = (ticket: Ticket, onOpenChange: (open: boolean) => void) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const queryClient = useQueryClient();

  const handleUpdate = async (data: UpdateTicketData) => {
    setIsSubmitting(true);
    try {
      const { error } = await supabase
        .from("tickets")
        .update({
          title: data.title,
          description: data.description,
          priority: data.priority,
          category: data.category,
          status: data.status,
          affected_cdsid: data.affected_cdsid,
          updated_at: new Date().toISOString()
        })
        .eq("id", ticket.id);

      if (error) throw error;

      if (data.sendEmail) {
        await supabase.functions.invoke("notify-ticket-updates", {
          body: {
            notification: {
              to: [ticket.creator_email, ...(data.additionalEmails || [])],
              subject: data.customEmailSubject || `Ticket #${ticket.ticket_number} actualizado`,
              ticketNumber: ticket.ticket_number,
              status: data.status,
              title: data.title,
              description: data.description,
              updateType: "updated"
            }
          }
        });
      }

      await supabase.from("activity_log").insert({
        action_type: "update_ticket",
        description: `Ticket #${ticket.ticket_number} actualizado`,
        user_id: ticket.creator_id,
        metadata: { ticket_data: data }
      });

      toast.success("¡Ticket actualizado correctamente!");
      queryClient.invalidateQueries({ queryKey: ["tickets"] });
      onOpenChange(false);
    } catch (error: any) {
      console.error("Error updating ticket:", error);
      toast.error(error.message || "No se pudo actualizar el ticket");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async () => {
    setIsSubmitting(true);
    try {
      const { error } = await supabase
        .from("tickets")
        .delete()
        .eq("id", ticket.id);

      if (error) throw error;

      toast.success("Ticket eliminado correctamente");
      queryClient.invalidateQueries({ queryKey: ["tickets"] });
      onOpenChange(false);
    } catch (error: any) {
      console.error("Error deleting ticket:", error);
      toast.error(error.message || "No se pudo eliminar el ticket");
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    isSubmitting,
    handleUpdate,
    handleDelete
  };
};